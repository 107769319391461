/**
 * share-link.js - Utility for creating shareable image links
 * 
 * Generates and manages temporary shareable links for images
 * created with HEXTRA-X. Used primarily for email sharing.
 */

import { nanoid } from 'nanoid';

// In-memory storage for temporary links (would move to proper storage in production)
const tempLinkStorage = new Map();

/**
 * Generate a unique shareable link for an image
 * @param {string} imageUrl - The processed image URL (data URL or blob URL)
 * @param {string} color - The color used to process the image
 * @param {string} format - The image format (png or webp)
 * @param {number} expiryHours - Hours until link expires (default: 48)
 * @returns {object} Link details including ID and expiry date
 */
export const generateShareableLink = (imageUrl, color, format, expiryHours = 48) => {
  // Create a unique ID for this share
  const shareId = nanoid(10);
  
  // Calculate expiry time
  const expiryTime = new Date();
  expiryTime.setHours(expiryTime.getHours() + expiryHours);
  
  // Store link data
  const linkData = {
    imageUrl,
    color,
    format,
    created: new Date(),
    expires: expiryTime,
    accessCount: 0
  };
  
  // Save to storage
  tempLinkStorage.set(shareId, linkData);
  
  // Create the shareable link ID and public details
  return {
    id: shareId,
    expires: expiryTime,
    accessUrl: `/shared-image/${shareId}`,
    fullUrl: `${window.location.origin}/shared-image/${shareId}`
  };
};

/**
 * Get data for a shareable link
 * @param {string} shareId - The unique share ID
 * @returns {object|null} The link data or null if not found/expired
 */
export const getShareableLinkData = (shareId) => {
  // Check if link exists
  if (!tempLinkStorage.has(shareId)) {
    console.log(`[SHARE] Link not found: ${shareId}`);
    return null;
  }
  
  // Get link data
  const linkData = tempLinkStorage.get(shareId);
  
  // Check if link is expired
  if (new Date() > linkData.expires) {
    console.log(`[SHARE] Link expired: ${shareId}`);
    tempLinkStorage.delete(shareId); // Clean up expired link
    return null;
  }
  
  // Increment access count
  linkData.accessCount += 1;
  tempLinkStorage.set(shareId, linkData);
  
  return linkData;
};

/**
 * Clean up expired links (can be called periodically)
 */
export const cleanupExpiredLinks = () => {
  const now = new Date();
  let expiredCount = 0;
  
  tempLinkStorage.forEach((data, id) => {
    if (now > data.expires) {
      tempLinkStorage.delete(id);
      expiredCount++;
    }
  });
  
  console.log(`[SHARE] Cleaned up ${expiredCount} expired links`);
  return expiredCount;
};
