import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

// Create a styled button that accepts customColor as a prop
const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})(({ theme, customColor }) => ({
  fontFamily: "'Inter', sans-serif",
  textTransform: 'none',
  minWidth: '100px',
  padding: '6px 16px',
  boxShadow: 'none',
  border: '1px solid var(--border-color)',
  transition: 'all 0.2s ease-in-out',
  
  // Default styles for outlined variant
  backgroundColor: 'var(--bg-secondary)',
  color: 'var(--text-primary)',
  '&:hover': {
    backgroundColor: 'var(--bg-secondary)',
    borderColor: 'var(--glow-color)',
    color: 'var(--glow-color)',
    boxShadow: '0 0 0 3px var(--glow-subtle)',
    transform: 'scale(1.05)',
  },
  
  // Styles for contained variant
  '&.MuiButton-contained': {
    backgroundColor: customColor || 'var(--button-bg)',
    color: (theme) => {
      // First check if we have a custom color that needs special handling
      if (customColor) {
        // These colors are considered light and need dark text
        const isLightButton = ['#FFFFFF', '#F5F5F5', '#E0E0E0', '#EEEEEE', '#FAFAFA'].includes(customColor.toUpperCase());
        return isLightButton ? '#333333' : 'white';
      }
      // Follow HEXTRA Golden Rule: text on button is opposite color to button background
      return 'var(--button-text)';
    },
    '&:hover': {
      backgroundColor: (theme) => {
        // Special case for light buttons in dark mode to preserve gold glow visibility
        const isDarkMode = theme.palette.mode === 'dark';
        const isLightButtonBg = customColor && ['#FFFFFF', '#F5F5F5', '#E0E0E0', '#EEEEEE', '#FAFAFA'].includes(customColor.toUpperCase());
        
        if (isDarkMode && (isLightButtonBg || !customColor)) {
          // Use dark grey for light buttons in dark mode to make gold glow visible
          return '#808080';
        }
        
        // Standard color handling for other cases
        return customColor 
          ? (customColor === '#D50032' ? '#B5002B' : // Red hover (free features)
             customColor === '#00A651' ? '#008C44' : // Green hover (premium features)
             customColor === '#0057B8' ? '#004494' : // Blue hover (expert-level features)
             customColor === '#224D8F' ? '#1A3A6C' : // Legacy blue hover
             customColor)
          : 'var(--button-hover)';
      },
      color: (theme) => {
        // Check if we're using the dark grey background in dark mode
        const isDarkMode = theme.palette.mode === 'dark';
        const isLightButtonBg = customColor && ['#FFFFFF', '#F5F5F5', '#E0E0E0', '#EEEEEE', '#FAFAFA'].includes(customColor.toUpperCase());
        
        if (isDarkMode && (isLightButtonBg || !customColor)) {
          // Use white text on dark grey background
          return 'white';
        }
        
        // First check if we have a custom color that needs special handling
        if (customColor) {
          const isLightButton = ['#FFFFFF', '#F5F5F5', '#E0E0E0', '#EEEEEE', '#FAFAFA'].includes(customColor.toUpperCase());
          return isLightButton ? '#333333' : 'white';
        }
        // Follow HEXTRA Golden Rule: text on button is opposite color to button background
        return 'var(--button-text)';
      },
      boxShadow: '0 0 0 3px var(--glow-subtle)',
      transform: 'scale(1.05)',
      borderColor: 'var(--glow-color)',
    },
  },
  
  // Disabled styles
  '&.Mui-disabled': {
    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(50, 50, 50, 0.7)' : 'rgba(200, 200, 200, 0.7)',
    color: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
  },
  '&.MuiButton-contained.Mui-disabled': {
    backgroundColor: theme => {
      if (customColor) {
        // Keep the same color but make it more transparent
        return customColor + '99'; // Add 60% opacity
      }
      return theme.palette.mode === 'dark' ? 'rgba(50, 50, 50, 0.7)' : 'rgba(200, 200, 200, 0.7)';
    },
    color: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
    opacity: 0.7
  }
}));

// Create a component that passes the customColor prop to the styled component
const GlowTextButton = React.forwardRef((props, ref) => {
  const { customColor, ...otherProps } = props;
  return <StyledButton ref={ref} customColor={customColor} {...otherProps} />;
});

export default GlowTextButton;
