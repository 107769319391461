/**
 * HEXTRA-X App Component (v2.3.1)
 * Main application component integrating color selection, image processing, and user tier management.
 * 
 * VERSION: 2.3.1
 * 
 * TEST_MODE: Set to true to enable full notifications in test environment (port 3000)
 * BUILD_ID: HEXTRA-2025-03-23-MC2301
 * BUILD_DATE: 2025-03-23
 */

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { 
  Box, 
  Button, 
  Container, // Currently unused but preserved for future use
  Grid, // Currently unused but preserved for future use
  TextField, // Currently unused but preserved for future use
  Typography, 
  Paper, // Currently unused but preserved for future use
  CircularProgress, 
  Slider, 
  LinearProgress, 
  Tooltip,
  IconButton,
  Alert, // Currently unused but preserved for future use
  Snackbar
} from '@mui/material';
import TagIcon from '@mui/icons-material/Tag'; // Currently unused but preserved for future use
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Currently unused but preserved for future use
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LinkRounded as LinkIcon, Lock as LockIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles'; // Currently unused but preserved for future use
import { debounce } from 'lodash';
import { hexToRgb } from './utils/colorUtils';
import { processImage, downloadWebPImage } from './utils/image-processing';
import { generateDownloadLink, trackDownloadClick, trackDownloadComplete, trackDownloadFailure, generateBulkDownloadLinks } from './utils/downloadTracker';
import { generateShareableLink, getShareableLinkData, cleanupExpiredLinks } from './utils/share-link'; // Some imports currently unused but preserved for future use
import SwatchDropdownField from './components/SwatchDropdownField';
import GlowTextButton from './components/GlowTextButton';
import GlowButton from './components/GlowButton'; // Currently unused but preserved for future use
import GlowSwitch from './components/GlowSwitch';
import IconTextField from './components/IconTextField';
import Banner from './components/Banner';
import { useNavigate } from 'react-router-dom';
import DefaultTshirt from './components/DefaultTshirt';
import GILDAN_64000 from './data/catalogs/gildan64000.js';
import CUSTOM_21 from './data/catalogs/gildan3000.js';
import './theme.css';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useTheme } from './context/ThemeContext';
import { useAuth } from './context/AuthContext';
import JSZip from 'jszip';
import Wheel from './components/Wheel';
import RestoreIcon from '@mui/icons-material/Restore';
import GlowIconButton from './components/GlowIconButton';
import { VERSION } from './version';
import PulsingGlowButton from './components/PulsingGlowButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HCS2Frame, { MiniHCS2TitleBar } from './components/HCS2Frame';
// FullWidthBulkSection removed to fix suspension errors
import DownloadIcon from '@mui/icons-material/Download';
import EmailCollectionDialog from './components/EmailCollectionDialog'; // Legacy component kept for backward compatibility
import RegistrationDialog from './components/RegistrationDialog';
import PremiumDialog from './components/PremiumDialog';
import AdminSettings from './components/AdminSettings';
import ColorAppliedFeedback from './components/ColorAppliedFeedback';
// Icon imports
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Currently unused but preserved for future use
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Currently unused but preserved for future use
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MailIcon from '@mui/icons-material/Mail';
import MiniDropDown from './components/MiniDropDown';

// Enable this flag to show full notifications in test environment (port 3000)
// This can be toggled via URL parameter: ?test_mode=true
// Only enable test mode explicitly with URL parameter and never in production
const TEST_MODE = window.location.search.includes('test_mode=true') && window.location.port === '3000';

// Constants
const DEFAULT_COLOR = '#EEAD1A'; // Gold for the Golden Connection (v1.3.3-gold)

const COLOR_GROUPS = [
  {
    name: 'Recently Used',
    colors: [] // This will be populated dynamically
  },
  {
    name: 'Brand Colors',
    colors: [
      { value: '#D50032', label: 'Dark Red' },
      { value: '#00805E', label: 'Green' },
      { value: '#224D8F', label: 'Blue' },
      { value: '#FED141', label: 'Daisy' }
    ]
  },
  {
    name: 'Grayscale',
    colors: [
      { value: '#000000', label: 'Black' },
      { value: '#333333', label: 'Dark Grey' },
      { value: '#999999', label: 'Mid Grey' },
      { value: '#CCCCCC', label: 'Light Grey' },
      { value: '#FFFFFF', label: 'White' }
    ]
  },
  {
    name: 'Useful',
    colors: [
      { value: '#FF4400', label: 'Orange' },
      { value: '#FFAA00', label: 'Amber' },
      { value: '#CABFAD', label: 'Stone' },
      { value: '#9900CC', label: 'Purple' },
      { value: '#00CCFF', label: 'Cyan' },
      { value: '#FF00FF', label: 'Magenta' },
      { value: '#00FF00', label: 'Chroma Green' },
      { value: '#0000FF', label: 'Chroma Blue' },
      { value: '#FF0000', label: 'Bright Red' }
    ]
  }
];

// Keep backward compatibility with existing code that uses DEFAULT_COLORS
const DEFAULT_COLORS = [ // Currently unused but preserved for backward compatibility
  DEFAULT_COLOR,
  '#D50032',  // Dark Red
  '#00805E',  // Green
  '#224D8F',  // Blue
  '#FED141',  // Daisy
  '#FF4400',  // Orange
  '#CABFAD',  // Stone
];

// Default catalog structure with predefined colors
const DEFAULT_CATALOGS = {
  'GILDAN_64000': [
    // Official Gildan 64000 colors from GILDAN-64000-Color-Info spreadsheet
    { hex: '#FFFFFF', name: 'White', id: 'G64000_01' },
    { hex: '#97999B', name: 'Sport Grey', id: 'G64000_02' },
    { hex: '#D7D2CB', name: 'Ice Grey', id: 'G64000_03' },
    { hex: '#7E7F74', name: 'Heather Military Green', id: 'G64000_04' },
    { hex: '#75787B', name: 'Graphite Heather', id: 'G64000_05' },
    { hex: '#425563', name: 'Dark Heather', id: 'G64000_06' },
    { hex: '#4D6995', name: 'Heather Indigo', id: 'G64000_07' },
    { hex: '#4A4A4A', name: 'Dark Heather Grey', id: 'G64000_08' },
    { hex: '#333F48', name: 'Heather Navy', id: 'G64000_09' },
    { hex: '#66676C', name: 'Charcoal', id: 'G64000_10' },
    { hex: '#25282A', name: 'Black', id: 'G64000_11' },
    { hex: '#971B2F', name: 'Antique Cherry Red', id: 'G64000_12' },
    { hex: '#AC2B37', name: 'Cherry Red', id: 'G64000_13' },
    { hex: '#BF0D3E', name: 'Heather Red', id: 'G64000_14' },
    { hex: '#D50032', name: 'Red', id: 'G64000_15' },
    { hex: '#382F2D', name: 'Dark Chocolate', id: 'G64000_16' },
    { hex: '#672E45', name: 'Heather Maroon', id: 'G64000_17' },
    { hex: '#5B2B42', name: 'Maroon', id: 'G64000_18' },
    { hex: '#8A1538', name: 'Cardinal Red', id: 'G64000_19' },
    { hex: '#9B2743', name: 'Heather Cardinal', id: 'G64000_20' },
    { hex: '#CABFAD', name: 'Sand', id: 'G64000_21' },
    { hex: '#E7CEB5', name: 'Natural', id: 'G64000_22' },
    { hex: '#F4633A', name: 'Orange', id: 'G64000_23' },
    { hex: '#EEAD1A', name: 'Gold', id: 'G64000_24' },
    { hex: '#FED141', name: 'Daisy', id: 'G64000_25' },
    { hex: '#F0EC74', name: 'Cornsilk', id: 'G64000_26' },
    { hex: '#A9C47F', name: 'Pistachio', id: 'G64000_27' },
    { hex: '#89A84F', name: 'Kiwi', id: 'G64000_28' },
    { hex: '#92BF55', name: 'Lime', id: 'G64000_29' },
    { hex: '#A0CFA8', name: 'Mint Green', id: 'G64000_30' },
    { hex: '#A4B09E', name: 'Sage', id: 'G64000_31' },
    { hex: '#00A74A', name: 'Irish Green', id: 'G64000_32' },
    { hex: '#00805E', name: 'Kelly Green', id: 'G64000_33' },
    { hex: '#5E7461', name: 'Military Green', id: 'G64000_34' },
    { hex: '#273B33', name: 'Forest Green', id: 'G64000_35' },
    { hex: '#5CAA7F', name: 'Heather Irish Green', id: 'G64000_36' },
    { hex: '#008E85', name: 'Jade Dome', id: 'G64000_37' },
    { hex: '#00859B', name: 'Tropical Blue', id: 'G64000_38' },
    { hex: '#006A8E', name: 'Antique Sapphire', id: 'G64000_39' },
    { hex: '#0076A8', name: 'Sapphire', id: 'G64000_40' },
    { hex: '#59A1CE', name: 'Heather Sapphire', id: 'G64000_41' },
    { hex: '#486D87', name: 'Indigo Blue', id: 'G64000_42' },
    { hex: '#7E93A7', name: 'Stone Blue', id: 'G64000_43' },
    { hex: '#464E7E', name: 'Metro Blue', id: 'G64000_44' },
    { hex: '#8BCDEA', name: 'Sky', id: 'G64000_45' },
    { hex: '#A4C8E1', name: 'Light Blue', id: 'G64000_46' },
    { hex: '#7BA4DB', name: 'Carolina Blue', id: 'G64000_47' },
    { hex: '#0093B2', name: 'Heather Galapagos Blue', id: 'G64000_48' },
    { hex: '#3975B7', name: 'Iris', id: 'G64000_49' },
    { hex: '#7C8CD9', name: 'Heather Royal', id: 'G64000_50' },
    { hex: '#307FE2', name: 'Royal', id: 'G64000_51' },
    { hex: '#263147', name: 'Navy', id: 'G64000_52' },
    { hex: '#948794', name: 'Paragon', id: 'G64000_53' },
    { hex: '#A15A95', name: 'Heather Radiant Orchid', id: 'G64000_54' },
    { hex: '#614B79', name: 'Heather Purple', id: 'G64000_55' },
    { hex: '#3C214E', name: 'Purple', id: 'G64000_56' },
    { hex: '#E4C6D4', name: 'Light Pink', id: 'G64000_57' },
    { hex: '#994878', name: 'Heather Berry', id: 'G64000_58' },
    { hex: '#AA0061', name: 'Antique Heliconia', id: 'G64000_59' },
    { hex: '#DD74A1', name: 'Azalea', id: 'G64000_60' },
    { hex: '#E24585', name: 'Heather Heliconia', id: 'G64000_61' },
    { hex: '#FF8D6D', name: 'Heather Orange', id: 'G64000_62' },
    { hex: '#FB637E', name: 'Coral Silk', id: 'G64000_63' }
  ],
  'GILDAN_3000': [
    // Core Colors
    { hex: '#FFFFFF', name: 'WHITE', id: 'G3000_01' },
    { hex: '#000000', name: 'BLACK', id: 'G3000_02' },
    { hex: '#808080', name: 'SPORT GREY', id: 'G3000_03' },
    { hex: '#FF0000', name: 'RED', id: 'G3000_04' },
    { hex: '#0000FF', name: 'ROYAL', id: 'G3000_05' },
    { hex: '#000080', name: 'NAVY', id: 'G3000_06' },
    
    // Additional Colors
    { hex: '#A0522D', name: 'BROWN', id: 'G3000_07' },
    { hex: '#556B2F', name: 'FOREST GREEN', id: 'G3000_08' },
    { hex: '#8B4513', name: 'SADDLE BROWN', id: 'G3000_09' },
    { hex: '#4B0082', name: 'INDIGO', id: 'G3000_10' },
    { hex: '#800080', name: 'PURPLE', id: 'G3000_11' },
    { hex: '#FF1493', name: 'DEEP PINK', id: 'G3000_12' },
    { hex: '#FF4500', name: 'ORANGE', id: 'G3000_13' },
    { hex: '#FFD700', name: 'GOLD', id: 'G3000_14' },
    { hex: '#98FB98', name: 'MINT', id: 'G3000_15' },
    
    // Extended Colors
    { hex: '#CD853F', name: 'TAN', id: 'G3000_16' },
    { hex: '#DEB887', name: 'BUFF', id: 'G3000_17' },
    { hex: '#F5DEB3', name: 'NATURAL', id: 'G3000_18' },
    { hex: '#F0E68C', name: 'KHAKI', id: 'G3000_19' },
    { hex: '#E6E6FA', name: 'LAVENDER', id: 'G3000_20' },
    { hex: '#FFC0CB', name: 'PINK', id: 'G3000_21' },
    { hex: '#00FF00', name: 'LIME', id: 'G3000_22' },
    { hex: '#00FFFF', name: 'CYAN', id: 'G3000_23' },
    { hex: '#FF00FF', name: 'MAGENTA', id: 'G3000_24' }
  ],
  'CUSTOM_21': [
    // Will be populated from HEXInputRef dropdown list
  ]
};

// Color names helper - This function will be defined properly inside the App component
let getColorNameWithSource; // Currently declared outside but implemented inside App component

/**
 * HEXTRA-X - v2.3.0
 * Build Date: 2025-03-22
 * Build ID: HEXTRA-2025-03-22-MC2300
 * 
 * Features:
 * - Complete image processing pipeline
 * - Kinde authentication
 * - Stripe subscription system (Early-Bird $5/mo, Pro $10/mo)
 * - Color management & visualization
 * 
 * Note: Version information is imported from the centralized version.js file
 */
// Using VERSION imported from './version.js'

// Browser environment check for SSR compatibility
const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

function App() {
  console.log('App: Starting initialization...');
  // 1. Basic hooks
  const navigate = useNavigate();
  // Get authentication from both Kinde and our AuthContext
  const { isAuthenticated: kindeAuthenticated, user, login } = useKindeAuth();
  // Safely destructure auth values with fallbacks to prevent TypeError
  const auth = useAuth();
  const userType = auth?.userType || 'free';
  const userCanDownload = auth?.canDownload || false;
  const userCanUseBulk = auth?.canUseBulk || false; // Extract bulk permission for security checks
  
  // Use the actual authentication status from Kinde
  const isAuthenticated = kindeAuthenticated;
  
  // Email user state for handling email-only users
  const [emailUser, setEmailUser] = useState(() => {
    try {
      // Attempt to retrieve saved email user from localStorage
      const savedUser = localStorage.getItem('hextra_email_user');
      return savedUser ? JSON.parse(savedUser) : null;
    } catch (e) {
      console.error('Error parsing email user from localStorage:', e);
      return null;
    }
  });

  // Create a derived userType that accounts for Verified users
  // This will be used for UI display purposes (like badges)
  const [derivedUserType, setDerivedUserType] = useState(userType);
  
  // Update derived user type whenever emailUser changes
  useEffect(() => {
    // If user is authenticated with a paid tier, keep their original type
    if (userType && ['earlybird', 'pro'].includes(userType)) {
      setDerivedUserType(userType);
    } 
    // If user has verified their email but is not a paid user, mark as 'email' type
    else if (emailUser && !emailUser.pending) {
      setDerivedUserType('email');
    }
    // Otherwise, use the default type (usually 'free')
    else {
      setDerivedUserType(userType);
    }
  }, [userType, emailUser]);
  
  // CRITICAL FIX: Strengthen recognition of paid users
  const userIsPaidTier = userType && ['earlybird', 'pro'].includes(userType);
  
  // Add additional check for authenticated users - always grant them access
  // This is a safety mechanism to ensure authenticated users are never locked out
  const hasBulkAccess = userIsPaidTier || (isAuthenticated && user?.email);
  
  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('✅ Authenticated user detected - ensuring BULK access:', {
        email: user.email,
        userType,
        userIsPaidTier,
        hasBulkAccess
      });
      
      // If user is authenticated, force userCanUseBulk to true
      if (auth && typeof auth.setForcePermission === 'function') {
        auth.setForcePermission('canUseBulk', true);
      }
    }
  }, [isAuthenticated, user, userType, userIsPaidTier]);
  
  const [isSubscribed, setIsSubscribed] = useState(false); // Default to not subscribed
  
  // Dialog states
  const [emailDialogOpen, setEmailDialogOpen] = useState(false); // Legacy dialog kept for backward compatibility
  const [newsletterDialogOpen, setNewsletterDialogOpen] = useState(false); // Newsletter signup dialog
  const [registrationDialogOpen, setRegistrationDialogOpen] = useState(false);
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);
  
  // State for tracking free downloads used (to force re-renders when count changes)
  const [freeDownloadsUsed, setFreeDownloadsUsed] = useState(0);
  
  // Handler for Kinde registration redirects - to avoid spinner issues
  const handleKindeRegistration = useCallback(() => {
    console.log('📝 Initiating Kinde registration via SDK...');
    // Store current path to return after authentication
    const currentPath = window.location.pathname;
    const returnPath = currentPath.includes('/subscription') ? '/app' : currentPath;
    
    // Force the proper registration page with all fields (not just login)
    login({
      authOnMount: false,
      appState: { targetUrl: returnPath },
      openerEnabled: true,
      registration: true, // Enable registration mode
      // These additional parameters ensure users go directly to registration
      authUrlParams: {
        screen_hint: 'signup',  // Critical parameter - forces signup screen
        is_create_account: 'true',
        prompt: 'create',
        signup_mode: 'fullform'
      }
    });
  }, [login]);
  
  // Free download tracking - allow 3 free downloads before requiring registration
  const MAX_FREE_DOWNLOADS = 3;
  
  // Get current free download count from localStorage
  const getFreeDownloadsUsed = useCallback(() => {
    try {
      const count = parseInt(localStorage.getItem('hextra_free_downloads') || '0', 10);
      return isNaN(count) ? 0 : count;
    } catch (error) {
      console.error('Error retrieving free download count:', error);
      return 0;
    }
  }, []);
  
  // Check if user has free downloads remaining
  const hasFreeDownloadsLeft = useCallback(() => {
    return getFreeDownloadsUsed() < MAX_FREE_DOWNLOADS;
  }, [getFreeDownloadsUsed]);
  
  // Increment free download counter in localStorage
  const incrementFreeDownloads = useCallback(() => {
    try {
      const currentCount = getFreeDownloadsUsed();
      const newCount = currentCount + 1;
      localStorage.setItem('hextra_free_downloads', newCount.toString());
      // Update the state to trigger re-renders
      setFreeDownloadsUsed(newCount);
      return newCount;
    } catch (error) {
      console.error('Error incrementing free download count:', error);
      return 0;
    }
  }, [getFreeDownloadsUsed]);

  // 2. Refs
  const wheelRef = useRef(null);
  const hexInputRef = useRef(null);
  const isDragging = useRef(false);

  // 2. State hooks
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [workingImageUrl, setWorkingImageUrl] = useState('');
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [workingProcessedUrl, setWorkingProcessedUrl] = useState('');
  const [selectedColor, setSelectedColor] = useState(DEFAULT_COLOR);
  
  // HCS2 integration state
  const [hcs2SelectedColor, setHcs2SelectedColor] = useState(DEFAULT_COLOR);
  const [hcs2AppliedColor, setHcs2AppliedColor] = useState(DEFAULT_COLOR);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showHCS2, setShowHCS2] = useState(false);
  const [showImageControls, setShowImageControls] = useState(false); // Controls visibility of HCS2 frame
  const [hcs2FeedbackOpen, setHcs2FeedbackOpen] = useState(false);
  const [hcs2FeedbackMessage, setHcs2FeedbackMessage] = useState('');

  // Function to update all color controls (RGB disc and Gray Value) when color is applied from HCS2
  const updateAllColorControls = (rgbColor) => {
    if (!rgbColor || typeof rgbColor !== 'object') return;
    
    // Convert RGB to HEX format for components that need it
    const hexColor = `#${rgbColor.r.toString(16).padStart(2, '0')}${rgbColor.g.toString(16).padStart(2, '0')}${rgbColor.b.toString(16).padStart(2, '0')}`.toUpperCase();
    
    // EXACTLY mimic dropdown color selection behavior:
    
    // 1. Set grayscale slider value (average of RGB)
    const grayValue = Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3);
    if (typeof setGrayscaleValue === 'function') {
      setGrayscaleValue(grayValue);
    }
    
    // 2. Update the selected color state
    setSelectedColor(hexColor);
    
    // 3. Update RGB color state
    if (typeof setRgbColor === 'function') {
      setRgbColor(rgbColor);
    }
    
    // 4. Update the RGB wheel directly via its ref
    if (wheelRef && wheelRef.current && wheelRef.current.setColor) {
      // This is the crucial step that updates the RGB disc position
      wheelRef.current.setColor(hexColor);
      console.log('🎡 Updated RGB wheel with color:', hexColor);
    }
    
    // 5. Maintain focus on HEX input (already done in the main handler)
  };
  // HCS2 auto-apply has been removed to simplify integration
  const [recentlyUsedColors, setRecentlyUsedColors] = useState(
    () => {
      try {
        const saved = localStorage.getItem('recentColors');
        return saved ? JSON.parse(saved) : [];
      } catch (e) {
        console.error('Error parsing recent colors from localStorage:', e);
        return [];
      }
    }
  );
  const [rgbColor, setRgbColor] = useState({ r: 255, g: 255, b: 255 }); // Default to white
  const [grayscaleValue, setGrayscaleValue] = useState(255); // Changed from 200 to 255 for maximum brightness
  const [canDownload, setCanDownload] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showHexDropDown, setShowHexDropDown] = useState(false);
  const [colorApplied, setColorApplied] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('png'); // 'png' or 'webp'
  const [autoApplyColors, setAutoApplyColors] = useState(false); // Default to OFF for better user control
  const [urlInput, setUrlInput] = useState('/images/default-tshirt.webp');
  const { theme, toggleTheme } = useTheme();
  const isDarkMode = theme === 'dark';
  const [enhanceEffect, setEnhanceEffect] = useState(true);
  const [showTooltips, setShowTooltips] = useState(true);
  const [showSubscriptionTest, setShowSubscriptionTest] = useState(false);
  const [matteValue, setMatteValue] = useState(50);
  const [textureValue, setTextureValue] = useState(50);
  const [batchResults, setBatchResults] = useState([]);
  
  // Store color catalogs in state
  const [catalogs, setCatalogs] = useState(() => {
    // Try to load from localStorage if available
    if (typeof window !== 'undefined') {
      const savedCatalogs = localStorage.getItem('hextra_color_catalogs');
      if (savedCatalogs) {
        try {
          return JSON.parse(savedCatalogs);
        } catch (e) {
          console.error('Failed to parse saved catalogs', e);
        }
      }
    }
    return DEFAULT_CATALOGS;
  });
  const [batchProgress, setBatchProgress] = useState(0);
  const [batchStatus, setBatchStatus] = useState('idle');
  const [successMessage, setSuccessMessage] = useState({ open: false, message: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  // Notification buttons have been removed as they were only for testing
  
  // Simplified notification system for normal application use
  // Test-specific code has been removed
  useEffect(() => {
    // No test code needed here anymore
  }, []);

  
  // Helper function to get color name with catalog attribution
  getColorNameWithSource = (hexCode, showHex = true) => {
    if (!hexCode) return '';
    
    // Normalize hex code for consistent lookup
    const normalizedHex = hexCode.toUpperCase();
    
    // Search through all catalogs for this color
    for (const [catalogId, colors] of Object.entries(catalogs)) {
      const color = colors.find(c => c.hex && c.hex.toUpperCase() === normalizedHex);
      if (color) {
        const catalogName = catalogId.replace('_', ': ');
        return showHex 
          ? `${color.name} (${catalogName}) - ${normalizedHex}`
          : `${color.name} (${catalogName})`;
      }
    }
    
    // If no match found, just return the hex code with a generic label
    return showHex ? `Custom Color - ${normalizedHex}` : 'Custom Color';
  };
  const [totalCount, setTotalCount] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [activeCatalog, setActiveCatalog] = useState('GILDAN_64000');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [isBatchMode, setIsBatchMode] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);
  const [lastClickColor, setLastClickColor] = useState(null);
  const [lastClickTime, setLastClickTime] = useState(0);

  // Check subscription status when user is authenticated
  useEffect(() => {
    if (isAuthenticated && user?.id) {
      // For now, we'll just mock the subscription check
      // In production, this would call your API
      console.log("Checking subscription status for user:", user.id);
      // Mock response - set to true to test subscription features
      setIsSubscribed(false);
    }
  }, [isAuthenticated, user]);

  // Load email user data from localStorage on app initialization
  useEffect(() => {
    const savedEmailUser = localStorage.getItem('hextra_email_user');
    console.log('[DEBUG] Init - localStorage email user data:', savedEmailUser);
    if (savedEmailUser) {
      try {
        const parsedUser = JSON.parse(savedEmailUser);
        console.log('[DEBUG] Init - Setting email user state:', parsedUser);
        setEmailUser(parsedUser);
      } catch (error) {
        console.error('Failed to parse saved email user data:', error);
      }
    } else {
      console.log('[DEBUG] Init - No email user data found in localStorage');
    }
  }, []);

  // Initialize the COLOR_GROUPS with the recent colors from localStorage
  useEffect(() => {
    if (recentlyUsedColors && recentlyUsedColors.length > 0) {
      COLOR_GROUPS[0].colors = recentlyUsedColors;
    }
  }, [recentlyUsedColors]);

  // Function to save color to recent list, only on deliberate actions
  const saveToRecentColors = useCallback((color) => {
    if (!color || color === '#FFFFFF') return; // Don't store empty or pure white
    
    // Ensure color is properly formatted
    let formattedColor = color.toUpperCase();
    if (formattedColor.length < 7) {
      console.warn('Invalid color format:', color);
      return;
    }
    
    // Add to local storage recent colors
    setRecentlyUsedColors(prev => {
      // Create the color object with value and label
      const newColor = { value: formattedColor, label: formattedColor };
      
      // Check if we already have an identical color
      const exactMatch = prev.find(c => c.value === formattedColor);
      if (exactMatch) {
        // Move it to the front without adding duplicate
        const filtered = prev.filter(c => c.value !== formattedColor);
        const updated = [newColor, ...filtered].slice(0, 4);
        
        // Update the COLOR_GROUPS with the new recently used colors
        COLOR_GROUPS[0].colors = updated;
        
        // Save to localStorage
        localStorage.setItem('recentColors', JSON.stringify(updated));
        
        return updated;
      }
      
      // Check for similar colors - consider colors within 10% difference as similar
      const rgbNew = hexToRgb(formattedColor);
      
      // Check if any existing color is too similar
      const tooSimilar = prev.some(existing => {
        const rgbExisting = hexToRgb(existing.value);
        
        // Calculate color distance (simple Euclidean distance)
        const rDiff = Math.abs(rgbNew.r - rgbExisting.r);
        const gDiff = Math.abs(rgbNew.g - rgbExisting.g);
        const bDiff = Math.abs(rgbNew.b - rgbExisting.b);
        
        // If the total difference is less than 30 (out of 765 max), consider it too similar
        return (rDiff + gDiff + bDiff) < 30;
      });
      
      // If too similar to existing color and we're in a dragging operation, don't add
      if (tooSimilar && isDragging.current) {
        return prev;
      }
      
      // Filter out the color if it already exists
      const filtered = prev.filter(c => c.value !== formattedColor);
      
      // Add to front of array and limit to 4 colors
      const updated = [newColor, ...filtered].slice(0, 4);
      
      // Update the COLOR_GROUPS with the new recently used colors
      COLOR_GROUPS[0].colors = updated;
      
      // Save to localStorage
      localStorage.setItem('recentColors', JSON.stringify(updated));
      
      return updated;
    });
  }, []);

  // Process the selected color - now used by multiple event handlers
  const processColor = useCallback(() => {
    if (!selectedColor || !workingImageUrl || !imageLoaded) {
      console.error('Cannot process color - missing requirements', {
        hasColor: !!selectedColor, 
        hasImage: !!workingImageUrl,
        imageLoaded
      });
      return;
    }
    
    // Save to recent colors on application (deliberate user action)
    saveToRecentColors(selectedColor);
    
    // Skip processing if auto-apply is off
    if (!autoApplyColors) return;
    
    setIsProcessing(true);
    
    try {
      console.log('Calling processImage...');
      processImage(workingImageUrl, selectedColor, selectedFormat)
        .then(processedUrl => {
          console.log('Processing complete, updating UI');
          setWorkingProcessedUrl(processedUrl);
          setCanDownload(true);
          setIsProcessing(false);
        })
        .catch(error => {
          console.error('Error in processColor:', error);
          // CRITICAL: Don't clear the image on error, keep showing the previous image
          setIsProcessing(false);
        });
    } catch (error) {
      console.error('Error in processColor:', error);
      // CRITICAL: Don't clear the image on error, keep showing the previous image
      setIsProcessing(false);
    }
  }, [selectedColor, workingImageUrl, imageLoaded, autoApplyColors, saveToRecentColors]);

  // 4. Memo hooks
  const debouncedProcessImage = useMemo(
    () => debounce(async (url, color) => {
      if (!url || !color) {
        console.log('Missing URL or color for image processing', { url: !!url, color });
        setIsProcessing(false);
        return;
      }
      
      console.log(`Debounced processing: URL: ${url.substring(0, 30)}..., Color: ${color}`);
      setIsProcessing(true);
      
      try {
        console.log('Calling processImage...');
        const processedUrl = await processImage(url, color, selectedFormat);
        console.log('Processing complete, updating UI');
        setWorkingProcessedUrl(processedUrl);
        setCanDownload(true);
        setIsProcessing(false);
      } catch (error) {
        console.error('Error in debouncedProcessImage:', error);
        setCanDownload(false);
        setIsProcessing(false);
      }
    }, 150),
    [] // No dependencies needed here
  );

  // 5. Callback hooks
  const focusHexInput = useCallback((colorValue) => {
    try {
      // Store current scroll position
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;
      
      // Try using the provided ref first
      if (hexInputRef.current) {
        // If a color is provided, update the input field directly
        if (colorValue) {
          // For direct value update
          if (typeof hexInputRef.current.value !== 'undefined') {
            console.log('📝 Setting HEX input value to:', colorValue);
            hexInputRef.current.value = colorValue;
          }
          // For MUI components that need properties set
          if (typeof hexInputRef.current.setValue === 'function') {
            hexInputRef.current.setValue(colorValue);
          }
          
          // Trigger events for change detection
          try {
            const inputEvent = new Event('input', { bubbles: true });
            hexInputRef.current.dispatchEvent(inputEvent);
            
            const changeEvent = new Event('change', { bubbles: true });
            hexInputRef.current.dispatchEvent(changeEvent);
          } catch (e) {
            console.warn('Could not dispatch input events:', e);
          }
        }
        
        // Focus the field without scrolling
        if (hexInputRef.current.focus) {
          // Use preventScroll option to avoid page jumping
          hexInputRef.current.focus({ preventScroll: true });
          // Restore scroll position to prevent jumping
          window.scrollTo(scrollX, scrollY);
          return;
        }
      }
      
      // If ref approach fails, try DOM selection
      const inputElement = document.querySelector('.color-picker-container input');
      if (inputElement) {
        if (colorValue) {
          inputElement.value = colorValue;
        }
        // Focus without scrolling
        inputElement.focus({ preventScroll: true });
        // Restore scroll position
        window.scrollTo(scrollX, scrollY);
      }
    } catch (err) {
      console.error('Error focusing/updating hex input:', err);
    }
  }, []);

  const applyColor = useCallback((trigger) => {
    console.log('applyColor called with:', {
      trigger,
      selectedColor,
      workingImageUrl: workingImageUrl?.substring(0, 30),
      imageLoaded
    });
    
    // Only proceed if we have a color and an image
    if (!selectedColor || !workingImageUrl || !imageLoaded) {
      console.error('Cannot apply color - missing requirements', {
        hasColor: !!selectedColor, 
        hasImage: !!workingImageUrl,
        imageLoaded
      });
      return;
    }
    
    // Set processing state
    setIsProcessing(true);
    
    // Force color to be the hex value, not the 'apply' trigger
    const colorToApply = selectedColor;
    console.log('Starting image processing with color:', colorToApply);
    
    // IMPORTANT: Use the direct processImage function, not the debounced version
    try {
      console.log('Processing image with dimensions:', {
        imageUrl: workingImageUrl ? 'present' : 'missing',
        originalImageUrl: originalImageUrl ? 'present' : 'missing'
      });
      
      processImage(workingImageUrl, colorToApply, selectedFormat)
        .then(processedUrl => {
          console.log('SUCCESS: Image processed with color', colorToApply);
          setWorkingProcessedUrl(processedUrl);
          setCanDownload(true);
          setIsProcessing(false);
        })
        .catch(error => {
          console.error('ERROR: Image processing failed:', error);
          // Don't reset to original image automatically, just keep current state
          setCanDownload(true);
          setIsProcessing(false);
        })
        .finally(() => {
          console.log('Processing complete (success or error)');
          focusHexInput(selectedColor);
        });
    } catch (e) {
      console.error('FATAL ERROR in image processing:', e);
      setIsProcessing(false);
    }
  }, [selectedColor, workingImageUrl, originalImageUrl, imageLoaded, focusHexInput]);

  const handleColorWheelChange = useCallback((color) => {
    if (!color) return;
    
    console.log('Color wheel changed to', color);
    setSelectedColor(color);
    setShowHexDropDown(false);
    
    // Extract RGB
    const rgb = hexToRgb(color);
    setRgbColor({ r: rgb.r, g: rgb.g, b: rgb.b });
    
    // Focus the HEX input (important for workflow)
    focusHexInput(color);
    
    // Only apply color if auto-apply is enabled, but DON'T save to recent colors
    // during wheel movement - only apply the color to preview
    if (autoApplyColors) {
      // Apply color without saving to recent
      if (selectedColor && workingImageUrl && imageLoaded) {
        setIsProcessing(true);
        
        try {
          processImage(workingImageUrl, color, selectedFormat)
            .then(processedUrl => {
              setWorkingProcessedUrl(processedUrl);
              setCanDownload(true);
              setIsProcessing(false);
            })
            .catch(error => {
              console.error('Error during wheel movement preview:', error);
              setIsProcessing(false);
            });
        } catch (error) {
          console.error('Error processing color during wheel movement:', error);
          setIsProcessing(false);
        }
      }
    }
  }, [focusHexInput, autoApplyColors, workingImageUrl, imageLoaded, setShowHexDropDown]);

  const handleDragStart = useCallback(() => {
    isDragging.current = true;
  }, []);

  const handleDragEnd = useCallback(() => {
    isDragging.current = false;
    
    // Now we save to recent when user releases the mouse (deliberate action)
    if (selectedColor) {
      saveToRecentColors(selectedColor);
    }
    
    // Apply the color when dragging ends if autoApplyColors is enabled
    if (autoApplyColors && selectedColor && workingImageUrl && imageLoaded) {
      setIsProcessing(true);
      
      try {
        processImage(workingImageUrl, selectedColor, selectedFormat)
          .then(processedUrl => {
            setWorkingProcessedUrl(processedUrl);
            setCanDownload(true);
            setIsProcessing(false);
          })
          .catch(error => {
            console.error('Error during color application at drag end:', error);
            setIsProcessing(false);
          });
      } catch (error) {
        console.error('Error processing color at drag end:', error);
        setIsProcessing(false);
      }
    }
  }, [selectedColor, autoApplyColors, saveToRecentColors, workingImageUrl, imageLoaded]);

  const handleDropdownSelect = useCallback((color) => {
    // Basic validation
    if (!color) {
      console.log('Invalid color from dropdown:', color);
      return;
    }
    
    // Make sure color is a string
    const hexColor = typeof color === 'string' ? color : 
                     (color.hex ? color.hex : null);
    
    if (!hexColor) {
      console.log('Could not extract hex color from:', color);
      return;
    }
    
    console.log('Selected color from dropdown:', hexColor);
    
    // Update the selected color
    setSelectedColor(hexColor);
    
    // Convert to RGB
    const rgb = hexToRgb(hexColor);
    if (rgb) {
      setRgbColor(rgb);
      
      // Calculate the HSV values to extract value component
      const max = Math.max(rgb.r, rgb.g, rgb.b) / 255;
      
      // Update grayscale value to match the brightness (value) of the selected color
      const valueComponent = Math.round(max * 255);
      setGrayscaleValue(valueComponent);
      
      // Update the color wheel position to match the selected color
      if (wheelRef.current && wheelRef.current.setColor) {
        wheelRef.current.setColor(hexColor);
      }
    }
    
    // Save to recent colors on dropdown select (deliberate user action)
    saveToRecentColors(hexColor);
    
    // Always process the image immediately for dropdown selections
    // This makes the dropdown behave consistently like Google menus
    // The Auto toggle now only affects Color Wheel behavior
    if (workingImageUrl && imageLoaded) {
      setIsProcessing(true);
      
      try {
        processImage(workingImageUrl, hexColor, selectedFormat)
          .then(processedUrl => {
            setWorkingProcessedUrl(processedUrl);
            setCanDownload(true);
            setIsProcessing(false);
          })
          .catch(error => {
            console.error('Error processing dropdown color:', error);
            setIsProcessing(false);
          });
      } catch (error) {
        console.error('Error applying dropdown color:', error);
        setIsProcessing(false);
      }
    }
    
    // Try to focus the hex input
    try {
      setTimeout(() => {
        const inputElement = document.querySelector('.color-picker-container input');
        if (inputElement) {
          inputElement.focus();
        }
      }, 100);
    } catch (err) {
      console.log('Error focusing input:', err);
    }
  }, [saveToRecentColors, processColor, autoApplyColors]);

  const handleImageUpload = useCallback(async (file) => {
    if (!file) {
      console.log('No file provided to handleImageUpload');
      return;
    }
    
    // Log file details for debugging
    console.log('Image upload initiated with file:', {
      name: file.name,
      type: file.type,
      size: `${(file.size / 1024).toFixed(2)} KB`
    });
    
    // Set loading state first
    setIsProcessing(true);
    
    try {
      // Create object URL immediately
      console.log('Creating object URL for uploaded file');
      const url = URL.createObjectURL(file);
      
      // Store both original and working image URLs
      console.log('Setting image URLs in state');
      setOriginalImageUrl(url);
      setWorkingImageUrl(url);
      setWorkingProcessedUrl(null); // Clear any previous processed image
      setCanDownload(false); // Reset download state for new image
      
      // Set image as loaded AFTER URLs are set
      console.log('Image successfully loaded');
      setImageLoaded(true);
      
      // Do NOT automatically apply color
      // if (selectedColor) {
      //   await applyColor(selectedColor);
      // }
    } catch (err) {
      console.error('ERROR in handleImageUpload:', err);
      setImageLoaded(false);
    } finally {
      // Always ensure processing state is reset
      setIsProcessing(false);
    }
  }, []);  // Remove dependencies on applyColor and selectedColor

  const handleSearchTshirts = useCallback(() => {
    window.open('https://www.google.com/search?q=blank+white+t-shirt&tbm=isch', '_blank');
  }, []);

  const handleLoadUrl = useCallback(async (directUrl) => {
    // Use either the provided directUrl or the urlInput state
    const urlToLoad = directUrl || urlInput.trim();
    
    if (!urlToLoad) {
      return;
    }

    console.log('Attempting to load image from URL:', urlToLoad);
    setIsProcessing(true);

    try {
      console.log('Fetching image from URL');
      const response = await fetch(urlToLoad);
      const blob = await response.blob();
      const file = new File([blob], 'image-from-url.png', { type: blob.type });
      
      console.log('Image fetched successfully, creating file object');
      // Since handleImageUpload now handles setting isProcessing false, we don't need to handle it here
      await handleImageUpload(file);
    } catch (err) {
      console.error('ERROR loading image from URL:', err);
      setIsProcessing(false); // Make sure to reset processing state on error
    }
  }, [handleImageUpload, urlInput]);

  const handleUrlKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLoadUrl();
    }
  }, [handleLoadUrl]);

  const handleWheelClick = useCallback((color) => {
    if (!color) return;
    
    console.log('Color wheel clicked on', color);
    setSelectedColor(color);
    setShowHexDropDown(false);
    
    // Extract RGB
    const rgb = hexToRgb(color);
    setRgbColor({ r: rgb.r, g: rgb.g, b: rgb.b });
    
    // Focus the HEX input
    focusHexInput();
    
    // Save to recent colors on click (deliberate user action)
    saveToRecentColors(color);
    
    // Only apply color if auto-apply is enabled
    if (autoApplyColors) {
      processColor();
    }
  }, [focusHexInput, saveToRecentColors, autoApplyColors, processColor, setShowHexDropDown]);

  const handleHexInputChange = useCallback((event) => {
    if (!event || !event.target) return;
    
    // Get the input value
    let inputValue = event.target.value || '';
    
    // Don't add # here - this is just the raw input change
    // The full hex with # will be managed by the component state
    
    // Update the selected color with # prefix
    let fullHex = inputValue.startsWith('#') ? inputValue : '#' + inputValue;
    fullHex = fullHex.toUpperCase(); // Ensure uppercase
    
    // Basic validation
    const validHex = /^#[0-9A-F]{0,6}$/i.test(fullHex);
    if (validHex || fullHex === '#') {
      setSelectedColor(fullHex);
      
      // Only convert to RGB if we have a valid 6-digit hex code
      if (/^#[0-9A-F]{6}$/i.test(fullHex)) {
        const rgb = hexToRgb(fullHex);
        if (rgb) {
          setRgbColor(rgb);
          
          // Calculate the HSV values to extract value component
          const max = Math.max(rgb.r, rgb.g, rgb.b) / 255;
          
          // Update grayscale value to match the brightness (value) of the selected color
          const valueComponent = Math.round(max * 255);
          setGrayscaleValue(valueComponent);
          
          // Update the color wheel position to match the new HEX code
          if (wheelRef.current && wheelRef.current.setColor) {
            wheelRef.current.setColor(fullHex);
          }
        }
      }
    }
  }, []);

  // Reset color to default yellow
  const resetColor = useCallback(() => {
    setSelectedColor(DEFAULT_COLOR);
    setRgbColor(hexToRgb(DEFAULT_COLOR));
    
    // Update the color wheel to the default color
    if (wheelRef.current && wheelRef.current.setColor) {
      wheelRef.current.setColor(DEFAULT_COLOR);
    }
  }, []);

  // Clear color (set empty)
  const clearColor = useCallback(() => {
    setSelectedColor('');
    setRgbColor({ r: 0, g: 0, b: 0 });
    
    // Update the color wheel to black when cleared
    if (wheelRef.current && wheelRef.current.setColor) {
      wheelRef.current.setColor('#000000');
    }
  }, []);

  const handleGrayscaleChange = useCallback((event, newValue) => {
    const grayValue = newValue;
    setGrayscaleValue(grayValue);
    
    // If we're at 0, set to pure black
    if (grayValue === 0) {
      const grayHex = '#000000';
      setSelectedColor(grayHex);
      setRgbColor({ r: 0, g: 0, b: 0 });
      
      // Update the wheel - the wheel's internal brightness will handle the display
      if (wheelRef.current && wheelRef.current.setColor) {
        wheelRef.current.setColor(grayHex);
      }
      return;
    }
    
    // For all other values, preserve the hue/saturation but apply the new brightness
    const currentColor = selectedColor;
    if (currentColor && currentColor.startsWith('#')) {
      // Convert the current color to HSV
      const rgb = hexToRgb(currentColor);
      if (rgb) {
        // Calculate the current HSV values
        const max = Math.max(rgb.r, rgb.g, rgb.b) / 255;
        const min = Math.min(rgb.r, rgb.g, rgb.b) / 255;
        const delta = max - min;
        
        let hue = 0;
        if (delta !== 0) {
          if (max === rgb.r / 255) {
            hue = ((rgb.g / 255 - rgb.b / 255) / delta) % 6;
          } else if (max === rgb.g / 255) {
            hue = (rgb.b / 255 - rgb.r / 255) / delta + 2;
          } else {
            hue = (rgb.r / 255 - rgb.g / 255) / delta + 4;
          }
        }
        
        hue = Math.round(hue * 60);
        if (hue < 0) hue += 360;
        
        const saturation = max === 0 ? 0 : delta / max;
        
        // Apply new brightness level (0-1 scale)
        const value = grayValue / 255;
        
        // Convert back to RGB
        let r, g, b;
        
        if (saturation === 0) {
          // Achromatic (gray)
          r = g = b = Math.round(value * 255);
        } else {
          hue /= 60; // sector 0 to 5
          const i = Math.floor(hue);
          const f = hue - i; // factorial part of h
          const p = value * (1 - saturation);
          const q = value * (1 - saturation * f);
          const t = value * (1 - saturation * (1 - f));
          
          switch (i % 6) {
            case 0: r = value; g = t; b = p; break;
            case 1: r = q; g = value; b = p; break;
            case 2: r = p; g = value; b = t; break;
            case 3: r = p; g = q; b = value; break;
            case 4: r = t; g = p; b = value; break;
            case 5: r = value; g = p; b = q; break;
            default: r = value; g = 0; b = 0;
          }
          
          r = Math.round(r * 255);
          g = Math.round(g * 255);
          b = Math.round(b * 255);
        }
        
        // Create new hex color with preserved hue/saturation but new brightness
        const newHex = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`.toUpperCase();
        setSelectedColor(newHex);
        setRgbColor({ r, g, b });
        
        // We don't need to call wheelRef.setColor here since the brightness prop will handle the wheel display
      }
    }
  }, [selectedColor]);

  const handleGraySwatchClick = useCallback(() => {
    // Calculate the current gray value
    const grayValue = Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3);
    const grayHex = `#${grayValue.toString(16).padStart(2, '0').repeat(3)}`.toUpperCase();
    
    // Update state
    setGrayscaleValue(grayValue);
    setSelectedColor(grayHex);
    setRgbColor({ r: grayValue, g: grayValue, b: grayValue });
    
    // Update the color wheel position to match this gray
    if (wheelRef.current && wheelRef.current.setColor) {
      wheelRef.current.setColor(grayHex);
    }
    
    // Save to recent colors on gray swatch click (deliberate user action)
    saveToRecentColors(grayHex);
    
    // Apply the gray color immediately only if autoApplyColors is enabled
    if (autoApplyColors) {
      processColor();
    } else {
      // If not auto-applying, make sure to focus the hex input
      focusHexInput(selectedColor);
    }
  }, [rgbColor, saveToRecentColors, autoApplyColors, focusHexInput, processColor]);

  // Function to handle download via email link
  const sendDownloadEmail = useCallback(async (email, imageUrl, imageType) => {
    // If there's no processed URL but there is a working image, set the working image as the URL to download
    const urlToDownload = imageUrl || workingProcessedUrl || (imageLoaded ? workingImageUrl : null);
    
    if (!urlToDownload) {
      console.log('[ERROR] No image available to download');
      return { success: false, error: 'no_image' };
    }
    
    // Generate a unique image ID based on color and format
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
    const imageId = `HEXTRA-${currentDate}-${selectedColor.replace('#', '')}-${selectedFormat}`;
    
    // Generate download link with tracking and additional metadata
    const { url: downloadUrl, id: downloadId } = generateDownloadLink(email, imageId, null, {
      format: selectedFormat,
      color: selectedColor,
      userType: userType || 'unknown'
    });
    
    console.log(`[EMAIL] Preparing to send download link to ${email} for image ${imageId}`);
    
    try {
      // Send email using the new email API
      const response = await fetch('/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'download',
          to: email,
          downloadUrl,
          imageId,
          format: selectedFormat,
          color: selectedColor
        })
      });
      
      const result = await response.json();
      
      if (result.success) {
        console.log(`[EMAIL] Successfully sent download link to ${email} for image ${imageId}`);
        
        // Track email sending in stats
        try {
          // Get current stats
          const statsJson = localStorage.getItem('hextra_email_stats') || '{}';
          const stats = JSON.parse(statsJson);
          
          // Update stats
          stats.emails_sent = (stats.emails_sent || 0) + 1;
          stats.last_email_sent = new Date().toISOString();
          stats.recipients = stats.recipients || {};
          stats.recipients[email] = (stats.recipients[email] || 0) + 1;
          stats.sent_via_api = (stats.sent_via_api || 0) + 1;
          
          localStorage.setItem('hextra_email_stats', JSON.stringify(stats));
        } catch (e) {
          console.error('[ERROR] Error updating email stats:', e);
        }
        
        // Always show notification in production or when TEST_MODE is enabled
        const isProduction = window.location.port !== '3000';
        if (isProduction || TEST_MODE) {
          // Show success message with more concise text
          setSnackbarMessage({
            open: true,
            message: `✅ Download link sent to ${email}`,
            severity: 'success'
          });
        }
        
        // Log for debugging in test mode
        if (TEST_MODE) {
          console.log(`[TEST_MODE] Email notification shown: "✅ Download link sent to ${email}"`);
        }
        
        return { success: true, downloadId, downloadUrl };
      } else {
        console.error('[EMAIL] Failed to send email:', result.error);
        
        // Always show error notification in production or when TEST_MODE is enabled
        const isProduction = window.location.port !== '3000';
        if (isProduction || TEST_MODE) {
          // Show error message
          setSnackbarMessage({
            open: true,
            message: 'Failed to send download link. Please try again.',
            severity: 'error'
          });
        }
        
        // Log for debugging in test mode
        if (TEST_MODE) {
          console.log('[TEST_MODE] Error notification shown: "Failed to send download link. Please try again."');
        }
        
        return { success: false, error: result.error || 'email_send_failed' };
      }
    } catch (error) {
      console.error('[EMAIL] Error sending email:', error);
      
      // Always show network error notification in production or when TEST_MODE is enabled
      const isProduction = window.location.port !== '3000';
      if (isProduction || TEST_MODE) {
        // Show error message
        setSnackbarMessage({
          open: true,
          message: 'Network error when sending email. Please try again.',
          severity: 'error'
        });
      }
      
      // Log for debugging in test mode
      if (TEST_MODE) {
        console.log('[TEST_MODE] Network error notification shown: "Network error when sending email. Please try again."');
      }
      
      return { success: false, error: 'network_error' };
    }
  }, [selectedColor, workingProcessedUrl, selectedFormat, workingImageUrl, imageLoaded]);
  
  // Function to perform the actual download
  const performDownload = useCallback(() => {
    // Get user email
    let userEmail = '';
    
    // Try to get email from authenticated user
    if (user && user.email) {
      userEmail = user.email;
    } 
    // Try to get from localStorage for Verified users
    else if (emailUser && emailUser.email) {
      userEmail = emailUser.email;
    }
    
    // Prepare filename
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
    const filename = `HEXTRA-${currentDate}-${selectedColor.replace('#', '')}.${selectedFormat}`;
    
    // Check user type to determine download behavior
    const isPaidUser = userType === 'pro' || userType === 'earlybird';
    // Check both Kinde auth and legacy email system for verified users
    const isVerifiedUser = userType === 'email' || (emailUser && emailUser.email);
    
    // 1. Paid users get instant download with email confirmation
    if (isPaidUser) {
      console.log(`Paid user (${userType}) downloading with email confirmation to ${userEmail}`);
      
      // Generate a unique image ID and tracking information
      const imageId = `HEXTRA-${currentDate}-${selectedColor.replace('#', '')}-${selectedFormat}`;
      let downloadId = null;
      
      // Send email confirmation with download link in background
      if (userEmail) {
        const emailResult = sendDownloadEmail(userEmail);
        if (emailResult.success) {
          downloadId = emailResult.downloadId;
          
          // Always show notification in production or when TEST_MODE is enabled
          // This ensures notifications work the same in test environment when needed
          const isProduction = window.location.port !== '3000';
          if (isProduction || TEST_MODE) {
            // Show success message about email confirmation
            setSnackbarMessage({
              open: true,
              message: 'Your download link has been emailed for your records.',
              severity: 'success'
            });
          }
          
          // Log for debugging in test mode
          if (TEST_MODE) {
            console.log('[TEST_MODE] Download notification shown: "Your download link has been emailed for your records."');
          }
        }
      }
      
      // Process direct download with tracking
      const startTime = Date.now();
      
      // Special handling for WebP to ensure proper compression
      if (selectedFormat === 'webp') {
        console.log('Using direct WebP download with proper compression for paid user');
        
        // Use our specialized WebP download function that ensures proper compression
        // WebP files are compressed with quality 0.1 for maximum size reduction
        downloadWebPImage(workingImageUrl, selectedColor, filename)
          .then(downloadResult => {
            // Track successful download completion
            if (downloadId) {
              trackDownloadComplete(downloadId, {
                format: 'webp',
                fileSize: downloadResult?.size || 0,
                duration: Date.now() - startTime
              });
            }
          })
          .catch(error => {
            console.error('Error during WebP download:', error);
            if (downloadId) trackDownloadFailure(downloadId, error.message);
          });
        
        return true;
      } else {
        // Standard download for PNG and other formats
        const urlToDownload = workingProcessedUrl || (imageLoaded ? workingImageUrl : null);
        
        if (!urlToDownload) {
          console.log('No image available to download');
          if (downloadId) trackDownloadFailure(downloadId, 'no_image');
          return false;
        }
        
        console.log(`Standard download for ${selectedFormat} format (paid user)`);
        
        // For PNG files, ensure we're getting the best quality
        let finalUrl = urlToDownload;
        let fileSize = 0;
        
        // Create a robust download mechanism
        try {
          // For immediate download, use direct link approach
          const link = document.createElement('a');
          link.href = urlToDownload;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // In background, fetch the image to get its size for tracking
          fetch(urlToDownload)
            .then(response => response.blob())
            .then(blob => {
              fileSize = blob.size;
              
              // Track successful download completion
              if (downloadId) {
                trackDownloadComplete(downloadId, {
                  format: selectedFormat,
                  fileSize,
                  duration: Date.now() - startTime
                });
              }
            })
            .catch(error => {
              console.error('Error getting file size:', error);
              // Still track completion but without size
              if (downloadId) {
                trackDownloadComplete(downloadId, {
                  format: selectedFormat,
                  duration: Date.now() - startTime
                });
              }
            });
          
          if (urlToDownload.startsWith('blob:')) {
            URL.revokeObjectURL(urlToDownload);
          }
          
          return true;
        } catch (error) {
          console.error('Error during download:', error);
          if (downloadId) trackDownloadFailure(downloadId, error.message);
          return false;
        }
      }
    }
    // 2. Verified users only get download links via email
    else if (isVerifiedUser) {
      console.log(`Verified user sending download link via email to ${userEmail}`);
      // Show message that download link has been sent
      setSnackbarMessage({
        open: true,
        message: 'Download link sent! Check your email to access your creation.',
        severity: 'success'
      });
      return sendDownloadEmail(userEmail);
    }
    // 3. Free users get limited free downloads before registration required
    else {
      // Check if user has free downloads remaining
      if (hasFreeDownloadsLeft()) {
        // User still has free downloads - allow this one and increment counter
        const usedBefore = getFreeDownloadsUsed();
        const usedNow = incrementFreeDownloads();
        const remainingAfter = MAX_FREE_DOWNLOADS - usedNow;
        
        console.log(`Free user download ${usedNow}/${MAX_FREE_DOWNLOADS} - processing download`);        
        
        // Process the download directly (using same flow as verified users)
        const urlToDownload = workingProcessedUrl || (imageLoaded ? workingImageUrl : null);
        
        if (!urlToDownload) {
          console.log('No image available to download');
          return false;
        }
        
        // Create a download link
        try {
          const link = document.createElement('a');
          link.href = urlToDownload;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          // Show message about free download
          setSnackbarMessage({
            open: true,
            message: `Free download ${usedNow} of ${MAX_FREE_DOWNLOADS} complete! ${remainingAfter > 0 ? `${remainingAfter} remaining.` : 'Create an account for unlimited downloads.'}`,
            severity: 'info'
          });
          
          if (urlToDownload.startsWith('blob:')) {
            URL.revokeObjectURL(urlToDownload);
          }
          
          return true;
        } catch (error) {
          console.error('Error during free download:', error);
          return false;
        }
      } else {
        // No free downloads left - redirect to Kinde registration
        console.log('Free user has used all free downloads - redirecting to Kinde registration');
        // Use Kinde SDK registration to avoid spinner issues
        handleKindeRegistration();
        return false;
      }
    }
  }, [selectedColor, workingProcessedUrl, selectedFormat, workingImageUrl, imageLoaded, user, emailUser, sendDownloadEmail]);
  
  // Handle newsletter subscription specifically for MailChimp integration
  const handleNewsletterSubmit = useCallback(async (email) => {
    if (!email) return;
    console.log('[DEBUG] Newsletter Submit - Processing email:', email);
    
    // For newsletter, we don't need to do anything else - the EmailCollectionDialog
    // will handle calling the newsletter-signup API endpoint directly
    // No verification email or download logic needed
    console.log('[DEBUG] Newsletter Submit - Completed');
  }, []);
  
  // Handle email submission from dialog - now generates and sends a link instead of direct download
  // This is for legacy compatibility only - new users should use Kinde registration
  const handleEmailSubmit = useCallback(async (email) => {
    if (!email) return;
    console.log('[DEBUG] Email Submit - Processing email:', email);
    
    // Create user info object with pending status
    const userInfo = {
      email,
      timestamp: Date.now(),
      pending: true,  // Mark as pending until verified
      verificationSent: true  // Flag to show verification UI
    };
    console.log('[DEBUG] Email Submit - Created user info:', userInfo);
    
    // Save to localStorage for persistence across sessions
    try {
      localStorage.setItem('hextra_email_user', JSON.stringify(userInfo));
      console.log('[DEBUG] Email Submit - Saved to localStorage successfully');
    } catch (error) {
      console.error('Failed to save email user data to localStorage:', error);
    }
    
    // Update state
    setEmailUser(userInfo);
    console.log('[DEBUG] Email Submit - Updated emailUser state');
    
    // Generate a verification token and URL
    const verificationToken = `verify_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
    const verificationUrl = `${window.location.origin}/verify?token=${verificationToken}&email=${encodeURIComponent(email)}`;
    
    // Store the verification token in localStorage
    try {
      const verifications = JSON.parse(localStorage.getItem('hextra_verifications') || '{}');
      verifications[email] = {
        token: verificationToken,
        created: Date.now(),
        expires: Date.now() + (24 * 60 * 60 * 1000) // 24 hours
      };
      localStorage.setItem('hextra_verifications', JSON.stringify(verifications));
    } catch (e) {
      console.error('[ERROR] Failed to store verification token:', e);
    }
    
    // Send verification email using the new email API
    try {
      const response = await fetch('/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'verification',
          to: email,
          verificationUrl
        })
      });
      
      const result = await response.json();
      
      if (result.success) {
        console.log(`[EMAIL] Successfully sent verification email to ${email}`);
        
        // Show verification sent message with more concise text
        setSnackbarMessage({
          open: true,
          message: 'Please check your inbox for verification link',
          severity: 'success'
        });
      } else {
        console.error('[EMAIL] Failed to send verification email:', result.error);
        
        // Show error message
        setSnackbarMessage({
          open: true,
          message: 'Failed to send verification email. Please try again.',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('[EMAIL] Error sending verification email:', error);
      
      // Show error message but still proceed with the flow
      setSnackbarMessage({
        open: true,
        message: 'Network error when sending verification email. Please try again.',
        severity: 'error'
      });
    }
    
    // Generate a download link instead of direct download for free users
    const urlToShare = workingProcessedUrl || (imageLoaded ? workingImageUrl : null);
    
    if (!urlToShare) {
      console.log('No image available to share');
      return;
    }
    
    // Generate a unique image ID based on color and format
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
    const imageId = `HEXTRA-${currentDate}-${selectedColor.replace('#', '')}-${selectedFormat}`;
    
    // Generate both a shareable link (for backward compatibility) and a trackable download link
    const shareableLink = generateShareableLink(urlToShare, selectedColor, selectedFormat, 48);
    console.log('[DEBUG] Generated shareable link:', shareableLink);
    
    // Generate download link with tracking
    const downloadUrl = generateDownloadLink(email, imageId);
    console.log('[DEBUG] Generated trackable download link:', downloadUrl);
    
    // Store both links for reference
    try {
      localStorage.setItem('hextra_last_share', JSON.stringify({
        shareId: shareableLink.id,
        downloadId: imageId,
        timestamp: Date.now(),
        email: email,
        expires: shareableLink.expires,
        downloadUrl: downloadUrl
      }));
    } catch (error) {
      console.error('[DEBUG] Failed to store share link data:', error);
    }
    
    // Send the download link email using our email service
    try {
      const emailResponse = await fetch('/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'download',
          to: email,
          downloadUrl,
          imageId,
          format: selectedFormat,
          color: selectedColor
        })
      });
      
      const emailResult = await emailResponse.json();
      
      if (emailResult.success) {
        console.log(`[EMAIL] Successfully sent download link to ${email} for image ${imageId}`);
        
        // Show concise confirmation to the user that the link was sent
        setSnackbarMessage({
          open: true,
          message: `✅ Download link sent to ${email}`,
          severity: 'success'
        });
      } else {
        console.error('[EMAIL] Failed to send download link email:', emailResult.error);
        
        // Still show a message, but indicate there might be an issue
        setSnackbarMessage({
          open: true,
          message: 'Download link generated, but email delivery may be delayed',
          severity: 'warning'
        });
      }
    } catch (error) {
      console.error('[EMAIL] Error sending download link email:', error);
      
      // Still show a message, but indicate there might be an issue
      setSnackbarMessage({
        open: true,
        message: 'Download link generated, but email delivery may be delayed',
        severity: 'warning'
      });
    }
  }, [workingProcessedUrl, workingImageUrl, imageLoaded, selectedColor, selectedFormat]);
  
  // DOWNLOAD/SHARE HANDLER - with email links for free users
  const handleQuickDownload = useCallback(() => {
    // Check various user states
    const isFreeTier = userType === 'free' && !emailUser;
    const hasPendingVerification = emailUser && emailUser.pending === true;
    const isPaidUser = userType === 'pro' || userType === 'earlybird';
    
    // Determine if this is a Verified user (verified email user but not paid tier)
    const isEmailSubscriber = userType === 'free' && emailUser && !emailUser.pending;
    
    // Log detailed information for debugging
    console.log('[DEBUG] Download/share check:', { 
      userType, 
      emailUserExists: !!emailUser, 
      isFreeTier,
      hasPendingVerification,
      isEmailSubscriber,
      isPaidUser
    });
    
    // Handle different user states
    if (isFreeTier) {
      // Free user without email - show dialog to collect email
      console.log('[DEBUG] Free user, redirecting to Kinde registration');
      handleKindeRegistration();
    } else if (hasPendingVerification) {
      // Email collected but not verified - show verification reminder
      console.log('[DEBUG] Email pending verification, showing reminder');
      setSnackbarMessage({
        open: true,
        message: 'Please check your inbox for verification link',
        severity: 'warning'
      });
    } else if (isPaidUser) {
      // Paid users get instant download with email confirmation
      console.log('[DEBUG] Paid user, providing instant download with email confirmation');
      performDownload();
    } else if (isEmailSubscriber) {
      // Verified email users (free tier) only get download links via email
      console.log('[DEBUG] Verified user, sending download link via email');
      performDownload();
    } else {
      // Fallback for any other case
      console.log('[DEBUG] Unhandled user type, redirecting to Kinde registration');
      handleKindeRegistration();
    }
  }, [userType, emailUser, performDownload]);

  const handleCSVUpload = useCallback(async (e) => {
    // Security check: Validate user has bulk permissions before processing
    if (!userCanUseBulk) {
      console.error('Security: Unauthorized CSV bulk upload attempted');
      // Clear the file input
      e.target.value = '';
      // Redirect to the app page instead of subscription
      navigate('/app');
      return;
    }
    
    const file = e.target.files[0];
    if (!file) return;

    setBatchStatus('processing');
    setBatchProgress(0);

    try {
      // Extract catalog name from filename (remove extension)
      const fileName = file.name.replace(/\.[^/.]+$/, "").toUpperCase();
      // Create a valid catalog ID
      const catalogId = fileName.replace(/\s+/g, '_');

      const text = await file.text();
      const lines = text.split('\n');
      const colors = [];
      
      for (let i = 1; i < lines.length; i++) {
        const columns = lines[i].split(',');
        if (columns.length >= 2) {
          const hex = columns[0].trim();
          const name = columns[1].trim();
          const id = columns[2]?.trim() || `${catalogId}_${i}`;
          
          if (hex.match(/^#[0-9A-F]{6}$/i)) {
            colors.push({
              hex: hex.toUpperCase(),
              name: name || `Color ${i}`,
              id,
              family: 'catalog',
              tags: ['imported', catalogId]
            });
          }
        }
        setBatchProgress(Math.round((i / lines.length) * 100));
      }
      
      if (colors.length > 0) {
        // Update catalogs with new colors
        setCatalogs(prevCatalogs => {
          const newCatalogs = { ...prevCatalogs };
          newCatalogs[catalogId] = colors;
          
          // Save to localStorage
          if (typeof window !== 'undefined') {
            localStorage.setItem('hextra_color_catalogs', JSON.stringify(newCatalogs));
          }
          
          return newCatalogs;
        });
        
        // Set active catalog to the newly imported one
        setActiveCatalog(catalogId);
        
        // Also set batch results to show the imported colors
        setBatchResults(colors);
      }
      
      setBatchStatus('complete');
    } catch (err) {
      console.error('Error processing CSV:', err);
      setBatchStatus('error');
    }
  }, []);

  const DEFAULT_TSHIRT_URL = '/images/default-tshirt.webp';

  const handleDefaultImageLoad = useCallback((urlOrEvent) => {
    // Handle both event objects and direct URL strings
    const imageUrl = urlOrEvent?.target?.src || urlOrEvent;
    
    if (imageUrl) {
      setWorkingImageUrl(imageUrl);
      setWorkingProcessedUrl(null); // Don't set processed URL for default image
      setImageLoaded(true);
      setCanDownload(false); // Default image shouldn't be downloadable until processed
    }
  }, []);

  const handleCatalogSwitch = useCallback((catalog) => {
    switch (catalog) {
      case 'GILDAN_64000':
        setActiveCatalog('GILDAN_64000');
        break;
      case 'CUSTOM_21':
        setActiveCatalog('CUSTOM_21');
        break;
      default:
        setActiveCatalog('GILDAN_64000');
    }
  }, []);

  // Reset image to original (pre-color) state
  const handleResetImage = useCallback(() => {
    console.log('Resetting image to original state');
    if (originalImageUrl) {
      console.log('Restoring original image');
      setWorkingImageUrl(originalImageUrl);
      setWorkingProcessedUrl(null);
      console.log('Image reset to original');
      setCanDownload(false);
    } else if (imageLoaded) {
      // Default image reset case - revert to default t-shirt
      console.log('Resetting to default t-shirt');
      setWorkingImageUrl(DEFAULT_TSHIRT_URL);
      setWorkingProcessedUrl(null);
      setCanDownload(false);
    } else {
      console.log('No original image available to reset to');
    }
  }, [originalImageUrl, imageLoaded]);

  // 6. Effect hooks
  
  // Load email user data from localStorage
  useEffect(() => {
    const savedEmailUser = localStorage.getItem('hextra_email_user');
    if (savedEmailUser) {
      try {
        setEmailUser(JSON.parse(savedEmailUser));
        console.log('Loaded email user data from localStorage');
      } catch (error) {
        console.error('Failed to parse saved email user data:', error);
      }
    }
  }, []);
  
  useEffect(() => {
    console.log('Subscription check bypassed for local development');
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('subscription') === 'true') {
      setShowSubscription(true);
    }
  }, []);

  useEffect(() => {
    // Removed themeManager.applyTheme(theme);
  }, [theme]);

  useEffect(() => {
    if (!workingImageUrl && !originalImageUrl) {
      console.log('Setting default T-shirt image');
      setUrlInput(DEFAULT_TSHIRT_URL);
      handleLoadUrl(DEFAULT_TSHIRT_URL);
    }
  }, [workingImageUrl, originalImageUrl, handleLoadUrl, DEFAULT_TSHIRT_URL]);

  // Add a button to toggle subscription view for testing
  const toggleSubscriptionView = () => {
    setShowSubscription(!showSubscription);
  };

  // Import the subscription page component lazily
  const SubscriptionPage = React.lazy(() => import('./components/SubscriptionPage'));

  // Handle loading state
  if (false) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: "center",
          background: '#000000'
        }}
      >
        <Box
          component="img"
          src="/images/HEXTRA-X-logo-Blk.svg"
          alt="HEXTRA"
          sx={{ width: 200, mb: 4 }}
        />
        <CircularProgress sx={{ color: 'white', ml: 2 }} />
      </Box>
    );
  }

  // Show login if not authenticated
  if (false) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center",
          background: '#000000'
        }}
      >
        <Box
          component="img"
          src="/images/HEXTRA-X-logo-Blk.svg"
          alt="HEXTRA"
          sx={{ width: 200, mb: 4 }}
        />
        <Button
          variant="contained"
          sx={{
            bgcolor: '#4CAF50',
            '&:hover': {
              bgcolor: '#45a049'
            }
          }}
        >
          Sign In
        </Button>
      </Box>
    );
  }

  // Show subscription page if subscription is required
  if (showSubscription) {
    return (
      <React.Suspense fallback={<CircularProgress />}>
        <SubscriptionPage />
      </React.Suspense>
    );
  }

  const mainContent = (
    <Box className={`app ${theme}`} sx={{ 
      width: '100%', 
      overflowX: 'hidden',
      boxSizing: 'border-box',
      backgroundColor: 'var(--bg-primary)',
      color: 'var(--text-primary)',
      minHeight: '100vh'
    }}>
      {/* Section 1.0 - Banner */}
      {/* Success message for bulk processing completion */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={successMessage.open}
        onClose={() => setSuccessMessage(prev => ({ ...prev, open: false }))}
        message={successMessage.message}
        action={
          <Button color="primary" size="small" onClick={() => setSuccessMessage(prev => ({ ...prev, open: false }))}>
            CLOSE
          </Button>
        }
        ContentProps={{
          sx: {
            bgcolor: 'var(--accent-color)',
            color: '#fff',
            fontWeight: 'medium',
            px: 2,
            py: 1,
            borderRadius: 2,
            '& .MuiSnackbarContent-message': {
              fontSize: '1rem'
            }
          }
        }}
      />
      
      {/* Email notification snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AutoAwesomeIcon sx={{ mr: 1, color: 'gold' }} />
            {typeof snackbarMessage === 'string' ? snackbarMessage : (snackbarMessage?.message || 'Notification')}
          </Box>
        }
        action={
          <Button 
            color="primary" 
            variant="contained" 
            size="small" 
            onClick={() => setSnackbarOpen(false)}
            sx={{ 
              fontWeight: 'bold', 
              ml: 1, 
              bgcolor: 'black',
              color: 'white',
              '&:hover': {
                bgcolor: '#333'
              }
            }}
          >
            CLOSE
          </Button>
        }
        ContentProps={{
          sx: {
            bgcolor: '#224D8F', // Brand Pro Blue for a stronger, more premium look
            color: '#fff',
            fontWeight: 'medium',
            px: 2,
            py: 1,
            borderRadius: 2,
            '& .MuiSnackbarContent-message': {
              fontSize: '1rem'
            }
          }
        }}
      />
      
      <Banner 
        version={VERSION}
        isDarkMode={theme === 'dark'}
        onThemeToggle={toggleTheme}
        isBatchMode={isBatchMode}
        setIsBatchMode={setIsBatchMode}
        setShowSubscriptionTest={setShowSubscriptionTest}
        derivedUserType={derivedUserType}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Subscription button removed */}
        </Box>
      </Banner>
      
      <Box className="app-content" sx={{ 
        pt: '25px',
        width: '100%',
        minWidth: '1350px', // Force minimum width for HCS2Frame
        maxWidth: '1350px', // Increased to accommodate the 1280px HCS2Frame plus padding
        boxSizing: 'border-box',
        overflowX: 'auto', // Allow horizontal scrolling if needed
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--bg-primary)',
        '& > *': { // Apply to all direct children
          maxWidth: '100%', // Allow children to expand to their own maxWidth
        },
        // Allow HCS2Frame to use the full available width
        '& .hcs2-frame-container': {
          width: '100%',
          minWidth: '1280px', // Force minimum width for HCS2 frame
          maxWidth: '1280px', // Match the width of the HCS2Frame
        }
      }}>
        <Typography 
          variant="h2" 
          sx={{ 
            textAlign: 'center',
            fontFamily: "'League Spartan', sans-serif",
            fontSize: '0.75rem',
            letterSpacing: '0.25em',
            textTransform: 'uppercase',
            color: 'var(--text-secondary)',
            height: '0px',
            padding: 0,
            margin: 0,
            overflow: 'hidden',
            visibility: 'hidden'
          }}
        >
          <Box component="span">COLORIZE</Box> | <Box component="span">VISUALIZE</Box> | <Box component="span">MESMERIZE</Box>
        </Typography>

        {/* Main content in vertical layout */}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: '1350px',  
          margin: '0 auto',
          p: 2,
          pt: 2,
          paddingLeft: 'calc(2 * 8px + 15px)', // 2 for p:2 + 15px offset
          alignItems: 'center',
          textAlign: 'center',
          boxSizing: 'border-box',
          overflow: 'hidden',
          '@media (max-width: 832px)': { 
            maxWidth: '100%', 
            p: 1,
            pt: 1,
            paddingLeft: 'calc(1 * 8px + 15px)' // 1 for p:1 + 15px offset
          }
        }}>
          {/* Color Section */}
          <Box sx={{ mb: 2 }}>
            {/* Catalog Title */}
            <Typography 
              id="apparel-mockups-heading"
              variant="h5" 
              component="h2" 
              sx={{ 
                textAlign: 'center', 
                fontFamily: "'Inter', sans-serif",
                fontWeight: 500,
                mb: 3,
                mt: 0,  
                color: 'var(--text-primary)'
              }}
            >
              Apparel Base Mockups | Bulk Image Generation
            </Typography>
            {/* Section 2.0 - Title and RGB Color Disc */}
            <Typography 
              id="color-picker-heading"
              variant="h5" 
              sx={{ 
                textAlign: 'center', 
                mb: 1,
                fontFamily: "'League Spartan', sans-serif",
                color: 'var(--text-primary)'
              }}
            >
              Pick a Color, or Enter a HEX code
            </Typography>
            <Box 
              id="colorize-section"
              className="color-picker-container"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
                width: '100%'
              }}
            >
              <Wheel
                ref={wheelRef}
                color={selectedColor}
                initialColor={DEFAULT_COLOR}
                initialBrightness={1.0}
                brightness={grayscaleValue}
                size={270}
                onChange={handleColorWheelChange}
                onClick={handleWheelClick}
                onDoubleClick={() => applyColor('double-click')}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                isDragging={isDragging.current}
              />
            </Box>

            {/* Section 3.0 - Grayscale Tool Bar */}
            <Box sx={{ 
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: "center",
              width: '100%',
              pl: '42px',  
              pr: '30px',  
              mb: 2,
              '@media (max-width: 600px)': { 
                justifyContent: 'center',
                pl: 2,
                pr: 2
              }
            }}>
              {/* GRAY Value Display */}
              <Typography sx={{ 
                fontFamily: "'Inter', sans-serif",
                color: 'var(--text-primary)',
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
                width: '140px',  
                display: 'flex',
                alignItems: "center",
                gap: 1
              }}>
                <Box component="span" sx={{ flexShrink: 0 }}>GRAY Value:</Box>
                <Box component="span" sx={{ 
                  fontFamily: 'monospace',
                  width: '85px',  
                  textAlign: 'left'
                }}>
                  {`${Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3)}`.padStart(3, ' ')}
                </Box>
              </Typography>

              {/* Centered layout container for swatch and slider */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 2,
                flex: 1,
                pl: 1
              }}>
                {/* Gray Swatch - Clickable */}
                <Box 
                  onClick={handleGraySwatchClick}
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: `rgb(${Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3)}, ${Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3)}, ${Math.round((rgbColor.r + rgbColor.g + rgbColor.b) / 3)})`,
                    borderRadius: '50%',
                    border: '1px solid var(--border-color)',
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 0 5px rgba(255, 153, 0, 0.5)'
                    }
                  }}
                />

                {/* Slider */}
                <Box sx={{
                  position: 'relative',
                  width: '225px',
                  height: '24px',
                  backgroundColor: 'transparent',
                  borderRadius: '12px',
                  background: 'linear-gradient(to right, #000000, #FFFFFF)',
                  overflow: 'visible', 
                  display: 'flex',
                  alignItems: "center",
                  px: 1,
                  mt: 2.5, // Reduced from 3.5px since we removed the text labels
                  mb: 2, // Adjusted since markers are now closer to the slider
                  outline: '1px solid rgba(200, 200, 200, 0.2)'
                }}>
                  {/* Grayscale Markers */}
                  <Box sx={{
                    position: 'absolute',
                    bottom: '-14px', 
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 1.5,
                    pointerEvents: 'none',
                    zIndex: 5 // Increased z-index to ensure markers are always visible
                  }}>
                    {[
                      { color: '#000000', position: '0%' },
                      { color: '#333333', position: '25%' },
                      { color: '#999999', position: '50%' },
                      { color: '#CCCCCC', position: '75%' },
                      { color: '#FFFFFF', position: '100%' }
                    ].map((marker, index) => (
                      <Box 
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          position: 'relative',
                          pointerEvents: 'auto' // Enable pointer events for entire box
                        }}
                      >
                        {/* Circle marker with tooltip */}
                        <Tooltip 
                          title={marker.color.toUpperCase()}
                          placement="top"
                          arrow
                          enterDelay={100}
                          leaveDelay={200}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: theme === 'dark' ? '#333' : '#fff',
                                color: theme === 'dark' ? '#fff' : '#333',
                                border: theme === 'dark' ? '1px solid #555' : '1px solid #ddd',
                                fontFamily: '"Roboto Mono", monospace',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                padding: '4px 8px',
                                filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.2))'
                              }
                            },
                            arrow: {
                              sx: {
                                color: theme === 'dark' ? '#333' : '#fff'
                              }
                            }
                          }}
                        >
                          <div> {/* Wrapping in a regular DOM element for tooltip to attach properly */}
                            <Box 
                              component="button" // Make it a button element for better accessibility
                              sx={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: marker.color,
                                borderRadius: '50%',
                                border: theme === 'dark' 
                                  ? (marker.color === '#000000' ? '1.5px solid rgba(255, 255, 255, 0.9)' : '1px solid rgba(180, 180, 180, 0.7)')
                                  : (marker.color === '#FFFFFF' ? '1.5px solid rgba(0, 0, 0, 0.8)' : '1px solid rgba(100, 100, 100, 0.7)'),
                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
                                position: 'relative',
                                transform: 'translateY(0)', // Slightly lowered to be more visible below the slider
                                cursor: 'pointer !important', // Force pointer cursor
                                transition: 'transform 0.15s ease, box-shadow 0.15s ease',
                                padding: 0, // Remove default button padding
                                margin: 0,  // Remove default button margin
                                outline: 'none', // Remove default focus outline
                                '&:hover': {
                                  transform: 'translateY(2px) scale(1.15)', // Lower and enlarge slightly on hover
                                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.4)', // Enhanced shadow on hover
                                  borderColor: '#FF9900', // Highlight with app's orange accent color
                                  zIndex: 2
                                }
                              }} 
                              onClick={() => {
                                // Convert from hex to RGB
                                const hexToRgb = (hex) => {
                                  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                                  return result ? {
                                    r: parseInt(result[1], 16),
                                    g: parseInt(result[2], 16),
                                    b: parseInt(result[3], 16)
                                  } : null;
                                };
                                
                                // Get the marker's color (HEX-ready mode - populates the HEX input but doesn't apply)
                                const hexColor = marker.color.toUpperCase();
                                const rgb = hexToRgb(hexColor);
                                
                                if (rgb) {
                                  // Set grayscale value for slider position
                                  const grayValue = Math.round((rgb.r + rgb.g + rgb.b) / 3);
                                  setGrayscaleValue(grayValue);
                                  
                                  // Update HEX input with the selected color (HEX-ready behavior)
                                  setSelectedColor(hexColor);
                                  setRgbColor({ r: rgb.r, g: rgb.g, b: rgb.b });
                                  
                                  // Update the wheel - the wheel's internal brightness will handle the display
                                  if (wheelRef && wheelRef.current && wheelRef.current.setColor) {
                                    wheelRef.current.setColor(hexColor);
                                  }
                                  
                                  // Focus the HEX input after setting the value (maintaining workflow)
                                  if (hexInputRef && hexInputRef.current) {
                                    hexInputRef.current.focus();
                                  }
                                }
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Box>
                    ))}
                  </Box>
                  <Slider
                    value={grayscaleValue}
                    onChange={handleGrayscaleChange}
                    min={0}
                    max={255}
                    sx={{
                      width: '100%',
                      '& .MuiSlider-thumb': {
                        width: 20,
                        height: 20,
                        backgroundColor: 'transparent',
                        border: '2px solid #FF9900',
                        outline: '1px solid rgba(0, 0, 0, 0.2)',
                        boxShadow: 'inset 0 0 4px #FF9900, 0 0 4px #FF9900',
                        '&:before': {
                          content: '""',
                          position: 'absolute',
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'transparent',
                        },
                        '&:hover, &.Mui-focusVisible': {
                          outline: '1px solid rgba(0, 0, 0, 0.3)',
                          boxShadow: 'inset 0 0 6px #FF9900, 0 0 8px #FF9900',
                        }
                      },
                      '& .MuiSlider-track': {
                        display: 'none'
                      },
                      '& .MuiSlider-rail': {
                        opacity: 0
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Section 4.0 - HEX Input Bar */}
            <Box sx={{ 
              display: 'flex',
              flexWrap: 'wrap',  
              gap: 2,
              alignItems: "center",
              width: '100%',
              pl: '42px',  
              pr: '30px',  
              mb: 2,
              '@media (max-width: 600px)': { 
                justifyContent: 'center',
                pl: 2,
                pr: 2
              }
            }}>
              {/* RGB Display */}
              <Typography sx={{ 
                fontFamily: "'Inter', sans-serif",
                color: 'var(--text-primary)',
                fontSize: '0.875rem',
                whiteSpace: 'nowrap',
                width: '140px',  
                display: 'flex',
                alignItems: "center",
                gap: 1
              }}>
                <Box component="span" sx={{ flexShrink: 0 }}>RGB:</Box>
                <Box component="span" sx={{ 
                  fontFamily: 'monospace',
                  width: '85px',  
                  textAlign: 'left'
                }}>
                  {rgbColor.r},{rgbColor.g},{rgbColor.b}
                </Box>
              </Typography>

              {/* Color Swatch */}
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: selectedColor,
                  borderRadius: '50%',
                  border: '1px solid var(--border-color)',
                  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
                  flexShrink: 0
                }}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <SwatchDropdownField
                  label="HEX Code"
                  value={selectedColor}
                  onChange={handleHexInputChange}
                  onEnterPress={(trigger) => {
                    handleHexInputChange(trigger);
                    saveToRecentColors(selectedColor);
                    // Always apply color on ENTER regardless of Auto Apply setting
                    applyColor('enter-key');
                  }}
                  onDropdownSelect={handleDropdownSelect}
                  options={COLOR_GROUPS}
                  ref={hexInputRef}
                  onReset={() => resetColor()}
                  onClear={() => clearColor()}
                  sx={{ 
                    width: '225px', 
                    '& .MuiAutocomplete-inputRoot': {
                      paddingRight: '45px !important', 
                      height: '42px'
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '9px 0px 9px 0',
                      fontFamily: '"Roboto Mono", monospace',
                      letterSpacing: '0px',
                      fontSize: '14.5px', 
                      minWidth: '100px' 
                    }
                  }}
                />
                {/* Mini HCS2 Toggle Button */}
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                  <MiniHCS2TitleBar 
                    isOpen={showHCS2}
                    onToggle={(newState) => {
                      setShowHCS2(newState);
                      // Notification now comes from MiniHCS2TitleBar component
                    }}
                    onNavigateTo={() => {
                      // Scroll to HCS2 section when clicking the color wheel icon
                      const hcs2Section = document.getElementById('section-9.0');
                      if (hcs2Section) {
                        hcs2Section.scrollIntoView({ behavior: 'smooth' });
                        // Always make sure HCS2 is showing when navigating to it
                        setShowHCS2(true);
                        // Notification now comes from MiniHCS2TitleBar component
                      }
                    }}
                  />
                </Box>
              </Box>

              {/* Custom square container for controls with precise spacing */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '110px', 
                  height: '78px', 
                  ml: '16px', // Use same spacing as between HEX and color swatch
                  mr: 0,
                  mb: 0, 
                  mt: 0, 
                  border: '1px solid var(--border-color)',
                  borderRadius: '8px',
                  backgroundColor: 'var(--background-paper)',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                  overflow: 'hidden',
                  alignSelf: 'center', // Vertically center with other elements
                }}
              >
                {/* Top section - APPLY button */}
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'stretch', 
                    justifyContent: 'center',
                    borderBottom: '1px solid var(--border-color)',
                    position: 'relative',
                    p: 0, 
                    m: 0, 
                    overflow: 'hidden' 
                  }}
                >
                  <GlowTextButton
                    id="apply-button"
                    variant="contained"
                    onClick={() => {
                      console.log('Apply button clicked with color:', selectedColor);
                      
                      // Validate requirements
                      if (!selectedColor || !workingImageUrl || !imageLoaded) {
                        console.error('Cannot apply color - missing requirements', {
                          hasColor: !!selectedColor, 
                          hasImage: !!workingImageUrl,
                          imageLoaded
                        });
                        return;
                      }
                      
                      // Save to recent colors when APPLY is clicked (deliberate action)
                      saveToRecentColors(selectedColor);
                      
                      // Update the Color Preview display
                      setHcs2AppliedColor(selectedColor);
                      
                      // Visual feedback - only for Apply button
                      setColorApplied(true);
                      setTimeout(() => setColorApplied(false), 500);
                      
                      // Set processing state - make this independent from other animations
                      setIsProcessing(true);
                      
                      // Process the image with the selected color
                      processImage(workingImageUrl, selectedColor, selectedFormat)
                        .then(processedUrl => {
                          console.log('Image successfully processed with color:', selectedColor);
                          setWorkingProcessedUrl(processedUrl);
                          setCanDownload(true);
                          setIsProcessing(false);
                        })
                        .catch(error => {
                          console.error('ERROR processing image:', error);
                          // Reset the processed URL to the original image as fallback
                          setWorkingProcessedUrl(workingImageUrl);
                          setIsProcessing(false);
                        });
                    }}
                    sx={{
                      height: '100%', 
                      fontSize: '13px', 
                      fontWeight: 'bold',
                      boxShadow: 'none',
                      width: '100%', 
                      borderRadius: 0, 
                      m: 0, 
                      transition: 'all 0.3s ease',
                      background: colorApplied ? `linear-gradient(135deg, ${selectedColor}, #FFFFFF, ${selectedColor})` : undefined,
                      backgroundSize: colorApplied ? '300% 300%' : undefined,
                      animation: colorApplied ? 'gradient-animation 2s ease infinite' : undefined,
                      // Use theme variables for proper visibility
                      color: 'var(--button-text)',
                      backgroundColor: 'var(--text-primary)'
                    }}
                  >
                    APPLY
                  </GlowTextButton>
                </Box>

                {/* Bottom section - Auto toggle and Reset */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1.5,
                    py: 0.5,
                    backgroundColor: 'var(--bg-paper-secondary)',
                    border: 'none',
                    borderTop: '1px solid var(--border-color)',
                    minHeight: '36px'
                  }}
                >
                  {/* Auto toggle with tooltip */}
                  <Tooltip 
                    title={autoApplyColors ? "Auto mode - colors apply immediately (click to disable)" : "Manual mode - requires APPLY button (recommended)"}
                    placement="bottom"
                    arrow
                    enterDelay={400}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                      onClick={() => setAutoApplyColors(!autoApplyColors)}
                    >
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          mr: 0, // Reduce margin between text and toggle
                          fontSize: '10px',
                          color: 'var(--text-secondary)',
                          userSelect: 'none'
                        }}
                      >
                        Auto
                      </Typography>
                      <GlowSwitch
                        checked={autoApplyColors}
                        onChange={(e) => setAutoApplyColors(e.target.checked)}
                        size="small"
                        sx={{
                          '& .MuiSwitch-track': {
                            backgroundColor: 'var(--border-color)',
                          },
                          transform: 'scale(0.65)', // Slightly smaller scale
                          my: -0.5,
                          ml: -0.5 // Negative margin to pull closer to text
                        }}
                      />
                    </Box>
                  </Tooltip>
                  
                  {/* Reset Button - moved to the right with auto-margin */}
                  <Tooltip title="Reset to original image">
                    <GlowIconButton
                      id="reset-button"
                      size="small"
                      onClick={handleResetImage}
                      disabled={isProcessing || !imageLoaded}
                      sx={{
                        color: 'var(--text-primary)',
                        padding: '4px',
                        ml: 'auto', 
                        mr: 0.5 
                      }}
                    >
                      <RestoreIcon fontSize="small" />
                    </GlowIconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

          </Box>

          {/* === Separator === */}
          <Box
            sx={{
              width: '100%',
              height: '4px',
              backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              my: 1
            }}
          />

          {/* Section 5.0 - Main Image Window Title/Image Loading */}
          <Typography 
            variant="h6" 
            sx={{ 
              mb: 2,  
              textAlign: 'center',
              fontFamily: "'Inter', sans-serif",
              fontSize: '1.25rem',
              fontWeight: 600,
              color: 'var(--text-primary)',
              '@media (max-width: 532px)': {
                fontSize: '1.1rem'
              }
            }}
          >
            Upload your T-shirt or other Image
          </Typography>

          {/* Section 6.0 - Main Image Window */}
          {/* Section 6.2 - Image Controls */}
          <Box sx={{ 
            display: 'flex',
            gap: 2,
            alignItems: "center",
            justifyContent: 'center', 
            mt: 1,
            mb: 2,
            width: '100%',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: "center",
              '& > button': {
                width: '110px', 
                alignSelf: 'center'
              }
            }
          }}>
            <GlowTextButton
              component="label"
              variant="contained"
              disabled={isProcessing}
              sx={{ 
                width: '110px',
                flexShrink: 0,
                // Use theme variables for proper visibility
                color: 'var(--button-text)',
                backgroundColor: 'var(--button-bg)'
              }}
            >
              UPLOAD
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => handleImageUpload(e.target.files[0])}
                disabled={false}
              />
            </GlowTextButton>

            <Box sx={{ 
              flex: 1,
              minWidth: 0, 
              maxWidth: '600px', 
              '@media (max-width: 600px)': {
                width: '100%',
                maxWidth: '300px',
                alignSelf: 'center'
              }
            }}>
              <IconTextField
                placeholder="Paste image URL here..."
                value={urlInput}
                onChange={(e) => setUrlInput(e.target.value)}
                onKeyDown={handleUrlKeyPress}
                startIcon={<LinkIcon />}
                hasReset
                onReset={() => setUrlInput(DEFAULT_TSHIRT_URL)}
                sx={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <IconButton 
                      onClick={handleLoadUrl}
                      size="small"
                      sx={{ 
                        color: theme => theme.palette.primary.main,
                        '&:hover': {
                          bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  )
                }}
              />
            </Box>

            <GlowTextButton
              variant="contained"
              onClick={handleSearchTshirts}
              sx={{ 
                width: '110px',
                // Use theme variables for proper visibility
                color: 'var(--button-text)',
                backgroundColor: 'var(--button-bg)'
              }}
              startIcon={<SearchIcon />}
            >
              FIND
            </GlowTextButton>
          </Box>

          {/* Section 6.1 - Image Display */}
          <Box sx={{
            width: '100%',
            maxWidth: '800px',
            height: '800px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            mb: 1, 
            position: 'relative'
          }}>
            {workingProcessedUrl || workingImageUrl ? (
              <>
                <Box 
                  component="img"
                  src={workingProcessedUrl || workingImageUrl}
                  alt="T-shirt or selected image"
                  sx={{
                    width: 'auto',
                    maxWidth: '800px',
                    maxHeight: '800px',
                    objectFit: 'contain',
                    border: theme => theme.palette.mode === 'dark' ? '1px solid rgba(150, 150, 150, 0.2)' : '1px solid rgba(128, 128, 128, 0.15)',
                    boxShadow: theme => theme.palette.mode === 'dark' ? '0 0 5px rgba(255, 255, 255, 0.1)' : '0 0 2px rgba(0, 0, 0, 0.1)'
                  }}
                />
                
                {/* Free Download Counter - Only shown for free users */}
                {derivedUserType === 'free' && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 20,
                      left: 0,
                      right: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      zIndex: 10
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: isDarkMode ? 'rgba(255, 215, 0, 0.8)' : 'rgba(213, 0, 50, 0.8)',
                        color: isDarkMode ? '#000' : '#fff',
                        padding: '6px 12px',
                        borderRadius: '24px',
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        boxShadow: isDarkMode 
                          ? '0 0 8px 2px rgba(255, 215, 0, 0.4)' 
                          : '0 0 8px 2px rgba(213, 0, 50, 0.4)',
                        transition: 'all 0.3s ease'
                      }}
                  >
                    <DownloadIcon sx={{ fontSize: 18 }} />
                    <Typography variant="body2" fontWeight="bold">
                      {MAX_FREE_DOWNLOADS - freeDownloadsUsed} free {MAX_FREE_DOWNLOADS - freeDownloadsUsed === 1 ? 'download' : 'downloads'} remaining
                    </Typography>
                  </Box>
                  </Box>
                )}
                
                {/* Section 6.3 - Download Options */}
                <Tooltip title={derivedUserType !== 'free' ? "Download image" : emailUser ? "Download your creation" : "Level Up to Download!"} arrow>
                  {/* Ultra simple: Show email button only for true free users who haven't given email */}
                  {(derivedUserType === 'free' && !emailUser) ? (
                    <PulsingGlowButton 
                      onClick={handleKindeRegistration}
                      disabled={false}
                      isDownloadButton={true}
                      autoPulse={true}
                      pulseInterval={5000}
                      sx={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px',
                        '--glow-color': theme === 'dark' ? 'rgba(255, 215, 0, 0.7)' : 'rgba(213, 0, 50, 0.7)',
                        '--border-color': theme === 'dark' ? 'rgba(255, 215, 0, 0.3)' : 'rgba(213, 0, 50, 0.3)',
                        backgroundColor: theme === 'dark' ? 'rgba(50, 50, 50, 0.8)' : 'rgba(255, 255, 255, 0.6)',
                        '&:hover': {
                          backgroundColor: theme === 'dark' ? 'rgba(60, 60, 60, 0.9)' : 'rgba(255, 255, 255, 0.8)',
                          transform: 'scale(1.1)',
                          boxShadow: theme === 'dark' 
                            ? '0 0 15px 3px rgba(255, 215, 0, 0.6)' 
                            : '0 0 15px 3px rgba(213, 0, 50, 0.6)',
                        }
                      }}
                    >
                      <DownloadIcon sx={{
                        color: theme === 'dark' ? 'rgba(255, 215, 0, 0.9)' : '#D50032'
                      }} />
                    </PulsingGlowButton>
                  ) : (
                    <GlowIconButton
                      onClick={handleQuickDownload}
                      disabled={!canDownload}
                      sx={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: theme === 'dark' ? 'rgba(50, 50, 50, 0.8)' : 'rgba(255, 255, 255, 0.6)',
                        '&:hover': {
                          backgroundColor: theme === 'dark' ? 'rgba(60, 60, 60, 0.9)' : 'rgba(255, 255, 255, 0.8)',
                        },
                        '&.Mui-disabled': {
                          opacity: 0.4,
                          backgroundColor: theme === 'dark' ? 'rgba(40, 40, 40, 0.4)' : 'rgba(255, 255, 255, 0.4)',
                        }
                      }}
                    >
                      <DownloadIcon sx={{
                        color: theme === 'dark' ? 'rgba(255, 255, 255, 0.85)' : undefined
                      }} />
                    </GlowIconButton>
                  )}
                </Tooltip>
              </>
            ) : (
              <Typography variant="body1" sx={{ color: 'var(--text-secondary)' }}>
                No image loaded yet. Please upload or select an image.
              </Typography>
            )}
            
            {/* Use DefaultTshirt component to preload the default t-shirt */}
            <DefaultTshirt onLoad={(url) => {
              if (!workingImageUrl && !originalImageUrl) {
                console.log('Setting default t-shirt from component:', url);
                setWorkingImageUrl(url);
                setImageLoaded(true);
              }
            }} />
          </Box>

          {/* Section 6.4 - Format Selection (PNG/WebP) */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '720px',
            margin: '0 auto',
            mt: 0.5, 
            mb: 2 
          }}>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              padding: '6px 12px',
              borderRadius: '8px',
              backgroundColor: theme === 'dark' ? '#323232' : 'rgba(240, 240, 240, 0.5)'
            }}>
              <Tooltip title="Choose PNG format (higher quality, larger file size)">
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    fontWeight: selectedFormat === 'png' ? 600 : 400,
                    color: selectedFormat === 'png' 
                      ? theme => theme.palette.mode === 'dark' ? '#FFFFFF' : 'var(--text-primary)'
                      : theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.75)' : 'var(--text-secondary)',
                    cursor: 'pointer',
                    mr: 1
                  }}
                  onClick={() => setSelectedFormat('png')}
                >
                  PNG
                </Typography>
              </Tooltip>
              
              <GlowSwitch
                checked={selectedFormat === 'webp'}
                onChange={(e) => setSelectedFormat(e.target.checked ? 'webp' : 'png')}
                size="small"
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(150, 150, 150, 0.5) !important' : undefined
                  }
                }}
              />
              
              <Tooltip title="Choose WebP format (smaller file size, good compatibility)">
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    fontWeight: selectedFormat === 'webp' ? 600 : 400,
                    color: selectedFormat === 'webp' 
                      ? theme => theme.palette.mode === 'dark' ? '#FFFFFF' : 'var(--text-primary)'
                      : theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.75)' : 'var(--text-secondary)',
                    cursor: 'pointer',
                    ml: 1
                  }}
                  onClick={() => setSelectedFormat('webp')}
                >
                  WebP
                </Typography>
              </Tooltip>
            </Box>
          </Box>

          {/* === Separator === */}
          <Box
            sx={{
              width: '100%',
              height: '4px',
              backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              my: 1
            }}
          />

          {/* Section 7.0 - Image Adjustments */}
          <Box sx={{ 
            width: '100%',
            maxWidth: '700px',
            mt: 2,
            position: 'relative',
            zIndex: 2,
            mx: 'auto' // Center the container
          }}>
            {/* Using the MiniDropDown component for Section 7.3 - Advanced Toggle */}
            <MiniDropDown 
              title="HEXTRA Advanced Image Controls"
              defaultOpen={false}
              onChange={setShowImageControls}
            >
            {/* Section 7.4 - Advanced Options (hidden by default) */}
              <>
                {/* Advanced Settings Toggle Section */}
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 2
                }}>
                  {!isSubscribed && (
                    <Typography sx={{ 
                      fontSize: '0.8rem',
                      color: theme => theme.palette.mode === 'dark' ? '#FED141' : '#D50032',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      mb: 1
                    }}>
                      <LockIcon fontSize="small" />
                      For Professional Users Only
                    </Typography>
                  )}
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'flex-end',
                    flexDirection: 'row'
                  }}>
                    <GlowSwitch
                      checked={showAdvanced}
                      onChange={(e) => {
                        setShowAdvanced(e.target.checked);
                        if (e.target.checked) {
                          // Different behavior based on user type
                          if (userType === 'pro' || userType === 'earlybird') {
                            // For paid users, show Coming Soon dialog
                            setComingSoonDialogOpen(true);
                          } else if (userType === 'free' || userType === 'email') {
                            // For free/email users, redirect to Kinde registration
                            handleKindeRegistration();
                          } else if (isAuthenticated) {
                            // If authenticated but no user type, redirect to subscription
                            navigate('/subscription');
                          } else {
                            // Otherwise redirect non-authenticated users to Kinde registration
                            handleKindeRegistration();
                          }
                          // Keep the switch off until feature is available
                          setTimeout(() => setShowAdvanced(false), 100);
                        }
                      }}
                      size="small"
                    />
                    <Typography sx={{ 
                      fontSize: '0.75rem',
                      color: 'var(--text-secondary)',
                      mt: 0.5
                    }}>
                      Advanced
                    </Typography>
                  </Box>
                </Box>

                {/* Section 7.1 - Processing Controls */}
                <Box sx={{ 
                  width: '100%',
                  opacity: showAdvanced ? 1 : 0.6,
                  transition: 'opacity 0.2s',
                  pointerEvents: showAdvanced && isSubscribed ? 'auto' : 'none',
                  position: 'relative'
                }}>
                  {/* Overlay for non-subscribed users */}
                  {!isSubscribed && (
                    <Box 
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.03)',
                        zIndex: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: isAuthenticated ? 'pointer' : 'default',
                        '&:hover': {
                          backgroundColor: isAuthenticated ? 'rgba(0,0,0,0.06)' : 'rgba(0,0,0,0.03)'
                        }
                      }}
                      onClick={isAuthenticated ? () => navigate('/subscription') : handleKindeRegistration}
                    />
                  )}
                  {/* Controls Row */}
                  <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: "center",
                    gap: 4,
                    mb: 3
                  }}>
                    <GlowSwitch
                      checked={enhanceEffect}
                      onChange={(e) => {
                        setEnhanceEffect(e.target.checked);
                        if (imageLoaded) {
                          applyColor('enhance-toggle');
                        }
                      }}
                      label="Enhanced"
                    />
                    <GlowSwitch
                      checked={showTooltips}
                      onChange={(e) => setShowTooltips(e.target.checked)}
                      label="Tooltips"
                    />
                  </Box>

                  {/* Section 7.2 - Effect Sliders */}
                  <Box sx={{ 
                    width: '100%',
                    maxWidth: '700px',
                    margin: '0 auto'
                  }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        color: 'var(--text-secondary)'
                      }}>
                        Matte Effect
                      </Typography>
                      <Slider
                        value={matteValue}
                        onChange={(e, newValue) => setMatteValue(newValue)}
                        min={0}
                        max={100}
                        disabled={true}
                        sx={{ 
                          width: '600px',
                          color: 'var(--text-secondary)',
                          '& .MuiSlider-thumb': {
                            color: 'var(--text-secondary)',
                          },
                          '& .MuiSlider-track': {
                            color: 'var(--text-secondary)',
                          },
                          '& .MuiSlider-rail': {
                            color: 'var(--text-secondary)',
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography gutterBottom sx={{ 
                        fontFamily: "'Inter', sans-serif",
                        color: 'var(--text-secondary)'
                      }}>
                        Texture Effect
                      </Typography>
                      <Slider
                        value={textureValue}
                        onChange={(e, newValue) => setTextureValue(newValue)}
                        min={0}
                        max={100}
                        disabled={true}
                        sx={{ 
                          width: '600px',
                          color: 'var(--text-secondary)',
                          '& .MuiSlider-thumb': {
                            color: 'var(--text-secondary)',
                          },
                          '& .MuiSlider-track': {
                            color: 'var(--text-secondary)',
                          },
                          '& .MuiSlider-rail': {
                            color: 'var(--text-secondary)',
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            </MiniDropDown>
          </Box>

          {/* === Separator === */}
          <Box
            sx={{
              width: '100%',
              height: '4px',
              backgroundColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.15)',
              my: 1
            }}
          />

          {/* Section 8.0 - HEXTRA BULK IMAGE GEN (BIG) */}
          {/* Only show the Bulk Section to users with bulk permissions */}
          {userCanUseBulk ? (
            <Box sx={{
              width: '100%',
              p: 4,
              my: 4,
              borderRadius: '8px',
              bgcolor: 'var(--bg-secondary)',
              border: '1px solid var(--border-subtle)',
              position: 'relative'
            }}>
              {/* Section Header - Centered */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mb: 3
              }}>
                <Typography variant="h5" sx={{
                  fontWeight: 700,
                  fontFamily: "'Inter', sans-serif",
                  color: 'var(--text-primary)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}>
                  <AutoAwesomeIcon sx={{ mr: 1, color: 'gold' }} />
                  BULK IMAGE GENERATION (BIG)
                </Typography>
                <Typography variant="subtitle1" sx={{
                  fontFamily: "'Inter', sans-serif",
                  color: 'var(--text-secondary)',
                  textAlign: 'center'
                }}>
                  Select, (or Pro-Users upload) your Catalog
                </Typography>
                {/* Only show Upgrade button for free users, not for paid users */}
                {/* Debug info - remove after fixing */}
                {console.log('BULK section auth state:', { isAuthenticated, userType, isDev: window.location.hostname === 'localhost' })}
                
                {/* Force hide button for all paid users - this should work regardless of other conditions */}
                {(userType !== 'pro' && userType !== 'earlybird') ? (
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      href="/subscription"
                      sx={{
                        minWidth: 180,
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        color: '#FFFFFF !important',
                        bgcolor: '#224D8F',
                        '&:hover': {
                          bgcolor: '#1a3c6e',
                          color: '#FFFFFF !important'
                        }
                      }}
                    >
                      Upgrade Now
                    </Button>
                  </Box>
                ) : null}
              </Box>
            <Box sx={{ 
              width: '100%',
              mb: 2
            }}>
              <Typography 
                sx={{ 
                  mb: 1,
                  textAlign: 'center',
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '15px',
                  fontWeight: 500,
                  color: 'var(--text-primary)',
                }}
              >
                Select a Catalog
              </Typography>
              <Typography 
                sx={{ 
                  mb: 2,
                  textAlign: 'center',
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '12px',
                  fontStyle: 'italic',
                  color: 'var(--text-secondary)',
                }}
              >
                (Pro-Users can Upload CSV below)
              </Typography>

              {/* Catalog selector */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              justifyContent: 'center', 
              mb: 4,
              mt: 2  
            }}>
              <GlowTextButton
                variant={activeCatalog === 'GILDAN_64000' ? 'contained' : 'outlined'}
                onClick={() => {
                  setActiveCatalog('GILDAN_64000');
                }}
                sx={{
                  width: '140px',
                  height: '36px',
                  fontSize: '0.8rem',
                  letterSpacing: '0.05em',
                  whiteSpace: 'nowrap',
                  opacity: activeCatalog === 'GILDAN_64000' ? 1 : 0.7,
                  // Use theme variables for proper visibility
                  color: 'var(--button-text)',
                  backgroundColor: activeCatalog === 'GILDAN_64000' ? 'var(--button-bg)' : 'transparent'
                }}
              >
                GILDAN 64000
              </GlowTextButton>
              <GlowTextButton
                variant={activeCatalog === 'GILDAN_3000' ? 'contained' : 'outlined'}
                onClick={() => {
                  setActiveCatalog('GILDAN_3000');
                }}
                sx={{
                  width: '140px',
                  height: '36px',
                  fontSize: '0.8rem',
                  letterSpacing: '0.05em',
                  whiteSpace: 'nowrap',
                  opacity: activeCatalog === 'GILDAN_3000' ? 1 : 0.7,
                  // Use same theme variables as GILDAN_64000 for consistent appearance
                  ...(activeCatalog === 'GILDAN_3000' ? {
                    color: 'var(--button-text)',
                    backgroundColor: 'var(--button-bg)'
                  } : {
                    color: 'var(--text-primary)',
                    backgroundColor: 'transparent'
                  })
                }}
              >
                GILDAN 3000
              </GlowTextButton>
              <Tooltip title="Pro Feature coming soon" arrow placement="top">
                <span>
                  <GlowTextButton
                    variant={activeCatalog === 'CUSTOM_21' ? 'contained' : 'outlined'}
                    onClick={() => {
                      setActiveCatalog('CUSTOM_21');
                    }}
                    sx={{
                      width: '140px',
                      height: '36px',
                      fontSize: '0.8rem',
                      letterSpacing: '0.05em',
                      whiteSpace: 'nowrap',
                      opacity: activeCatalog === 'CUSTOM_21' ? 1 : 0.7
                    }}
                  >
                    CUSTOM 21
                  </GlowTextButton>
                </span>
              </Tooltip>
            </Box>

            {/* Instruction text */}
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontFamily: '"Inter", sans-serif',
                fontSize: '15px',
                fontWeight: 600,
                color: theme === 'dark' ? '#EEAD1A' : '#e09e00',
                textAlign: 'center',
                mt: 2,
                mb: 3,
              }}
            >
              Create ALL Color variants in ONE-Click
            </Typography>

            {/* Batch Processing Controls */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: "center",
              p: 4, // Increased padding uniformly
              borderRadius: '8px',
              bgcolor: 'var(--bg-secondary)',
              border: '1px solid var(--border-subtle)',
              width: '800px', // Fixed width to exactly 800px
              maxWidth: '800px',
              boxSizing: 'border-box', // Include padding in width calculation
              mx: 'auto' // Center the container
            }}>
              <Typography variant="h6" sx={{ 
                fontFamily: "'Inter', sans-serif",
                fontSize: '18px',
                fontWeight: 600,
                color: 'var(--text-primary)',
                mb: 1
              }}>
                ONE-Click BIG Batch Processing
              </Typography>

              {/* Main Action Buttons */}
              <Box sx={{ 
                display: 'flex', 
                gap: 3, // Increased gap between buttons
                mb: 3, // Increased bottom margin
                mt: 1, // Added top margin
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}>
                <GlowTextButton
                  variant="contained"
                  onClick={async () => {
                    // Security check: Validate user has bulk permissions before processing
                    // This ensures Verified users can't access bulk functionality
                    if (!userCanUseBulk) {
                      console.error(`Security: Unauthorized bulk access attempted by ${userType} user`);
                      // Show a notification instead of redirecting
                      setSuccessMessage({
                        open: true,
                        message: 'This feature requires a paid subscription. Please upgrade your account to access it.',
                        severity: 'warning'
                      });
                      return;
                    }
                    
                    setIsProcessing(true);
                    setBatchStatus('processing');
                    
                    try {
                      const colors = activeCatalog === 'GILDAN_64000' ? GILDAN_64000 : CUSTOM_21; // Use current catalog
                      console.log(`Processing ${colors.length} colors from ${activeCatalog}`);
                      
                      const zip = new JSZip();
                      const folder = zip.folder("hextra-colors");
                      
                      const CHUNK_SIZE = 5;
                      const chunks = [];
                      for (let i = 0; i < colors.length; i += CHUNK_SIZE) {
                        chunks.push(colors.slice(i, i + CHUNK_SIZE));
                      }
                      
                      for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
                        const chunk = chunks[chunkIndex];
                        
                        // Double-check permissions during execution in case they change
                      if (!userCanUseBulk) {
                        throw new Error('Permission denied: Bulk processing requires paid subscription');
                      }
                      
                      await Promise.all(chunk.map(async (color) => {
                          console.log(`Processing color: ${color.name} (${color.hex})`);
                          
                          const rgb = hexToRgb(color.hex);
                          if (!rgb) {
                            console.error(`Invalid hex color: ${color.hex}`);
                            return;
                          }
                          
                          const processedUrl = await processImage(workingImageUrl, color.hex, selectedFormat);
                          const response = await fetch(processedUrl);
                          const blob = await response.blob();
                          
                          const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
                          const filename = `HEXTRA-${date}-${activeCatalog}_${color.hex.replace('#', '')}.png`;
                          folder.file(filename, blob);
                        }));
                        
                        const progress = Math.round(((chunkIndex + 1) * CHUNK_SIZE / colors.length) * 100);
                        setBatchProgress(Math.min(progress, 100));
                        
                        await new Promise(resolve => setTimeout(resolve, 0));
                      }
                      
                      console.log('Generating ZIP file...');
                      setBatchStatus('saving');
                      
                      // Final permission check before generating ZIP
                      if (!userCanUseBulk) {
                        throw new Error('Permission denied: Bulk export requires paid subscription');
                      }
                      
                      const content = await zip.generateAsync({
                        type: "blob",
                        compression: "DEFLATE",
                        compressionOptions: {
                          level: 5
                        }
                      }, (metadata) => {
                        setBatchProgress(Math.round(metadata.percent));
                      });
                      
                      // Verify user still has permission before downloading
                      if (!userCanUseBulk) {
                        throw new Error('Permission denied: Bulk download requires paid subscription');
                      }
                      
                      const url = window.URL.createObjectURL(content);
                      const link = document.createElement('a');
                      link.href = url;
                      const date = new Date().toISOString().split('T')[0];
                      link.download = `HEXTRA-${date}-${activeCatalog}_${colors.length}`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(url);
                      
                      setBatchStatus('complete');
                      console.log('Batch processing complete');
                      
                      // Show completion message
                      setSuccessMessage({
                        open: true,
                        message: 'Rapid & professional! Your bulk job is complete. How much time did you just save?'
                      });
                      // Auto-dismiss after 8 seconds (pregnant pause)
                      setTimeout(() => setSuccessMessage(prev => ({ ...prev, open: false })), 8000);
                      
                    } catch (err) {
                      console.error('Error in batch processing:', err);
                      setBatchStatus('error');
                    } finally {
                      setIsProcessing(false);
                      setBatchProgress(0);
                    }
                  }}
                  disabled={isProcessing || !imageLoaded}
                  sx={{ 
                    minWidth: '180px',
                    fontSize: '0.95rem',
                    px: 3,
                    py: 1.2,
                    // Use theme variables for proper visibility
                    color: 'var(--button-text)',
                    backgroundColor: 'var(--button-bg)'
                  }}
                >
                  GENERATE ALL
                </GlowTextButton>

                {/* Multi-Batch Button */}
                <Tooltip title="Pro Feature coming soon" arrow placement="top">
                  <span>
                    <GlowTextButton
                      variant="outlined"
                      disabled={true}
                      sx={{ 
                        minWidth: '180px', 
                        bgcolor: 'transparent',
                        fontSize: '0.95rem',
                        px: 3,
                        '&.Mui-disabled': {
                          color: 'var(--text-secondary)',
                          borderColor: 'var(--border-color)',
                          opacity: 0.7
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        py: 1.2
                      }}
                    >
                      <Box>MULTI-BATCH</Box>
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'var(--text-secondary)',
                          fontSize: '10px',
                          opacity: 0.8,
                          mt: 0.5
                        }}
                      >
                        coming soon
                      </Typography>
                    </GlowTextButton>
                  </span>
                </Tooltip>
              </Box>

              {/* CSV Upload Button */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Tooltip title={!isSubscribed ? "Subscribe to unlock batch processing" : ""}>
                  <span>
                    <GlowTextButton
                      component="label"
                      variant="outlined"
                      disabled={isProcessing || batchStatus === 'processing' || !isSubscribed}
                      sx={{ 
                        minWidth: '180px', 
                        bgcolor: 'transparent',
                        fontSize: '0.95rem',
                        px: 3,
                        '&.Mui-disabled': {
                          color: 'var(--text-secondary)',
                          borderColor: 'var(--border-color)',
                          opacity: 0.7
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        py: 1.2
                      }}
                      onClick={!isSubscribed && isAuthenticated ? () => navigate('/subscription') : undefined}
                    >
                      <Box>UPLOAD CSV</Box>
                      <input
                        type="file"
                        hidden
                        accept=".csv"
                        onChange={handleCSVUpload}
                        disabled={!isSubscribed}
                      />
                      {!isSubscribed && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: 'var(--text-secondary)',
                            fontSize: '10px',
                            opacity: 0.8,
                            mt: 0.5
                          }}
                        >
                          subscribe to unlock
                        </Typography>
                      )}
                    </GlowTextButton>
                  </span>
                </Tooltip>
              </Box>

              {/* Progress Indicator */}
              {batchStatus === 'processing' && (
                <Box sx={{ width: '100%', maxWidth: 400, mt: 2 }}>
                  <Typography variant="body2" color="var(--text-secondary)" align="center" mt={1}>
                    Processing: {batchProgress}%
                  </Typography>
                  <LinearProgress 
                    variant="determinate" 
                    value={batchProgress} 
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: 'var(--border-subtle)',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: 'var(--glow-color)',
                        borderRadius: 4
                      }
                    }}
                  />
                </Box>
              )}

              {/* Color Results */}
              {batchResults && batchResults.length > 0 && (
                <Box sx={{ 
                  width: '100%',
                  maxWidth: '1280px',
                  mt: 3,
                  mx: 'auto'
                }}>
                  <Typography variant="h6" sx={{ 
                    mb: 2, 
                    textAlign: 'center',
                    color: 'var(--text-secondary)',
                    letterSpacing: '0.1em'
                  }}>
                    Colors from Catalog: {activeCatalog.replace('_', ': ')}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1.5,
                    justifyContent: 'center',
                    maxWidth: '100%',
                    p: 2
                  }}>
                    {batchResults.map((color, index) => (
                      <Tooltip 
                        key={index} 
                        title={color.name || color.hex}
                        arrow
                        placement="top"
                      >
                        <Box
                          sx={{
                            width: 36,
                            height: 36,
                            aspectRatio: '1/1',
                            backgroundColor: color.hex,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            border: '1px solid var(--border-color)',
                            boxShadow: theme => `0 0 0 ${selectedColors.includes(color.hex) ? '2px var(--glow-color)' : '1px rgba(0, 0, 0, 0.1)'}`,
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'scale(1.1)',
                              boxShadow: '0 0 0 2px var(--glow-color)',
                            }
                          }}
                          onClick={() => {
                            if (selectedColors.includes(color.hex)) {
                              setSelectedColors(selectedColors.filter(c => c !== color.hex));
                            } else {
                              setSelectedColors([...selectedColors, color.hex]);
                            }
                          }}
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            </Box>
            </Box>
          ) : (
            /* Show an upgrade notice for users without bulk permissions */
            <Box sx={{
              width: '100%',
              p: 3,
              mt: 2,
              mb: 2,
              borderRadius: '8px',
              bgcolor: 'var(--bg-secondary)',
              border: '1px solid var(--border-subtle)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {/* Title with AutoAwesome icon */}
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '18px',
                  fontWeight: 600,
                  color: 'var(--text-primary)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 2
                }}
              >
                <AutoAwesomeIcon sx={{ mr: 1, color: 'gold' }} />
                BULK Image Generation (BIG)
              </Typography>
              
              {/* Lock icon centered below title */}
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mb: 2 
              }}>
                <LockIcon sx={{ 
                  color: 'gold',
                  fontSize: '28px'
                }} />
              </Box>
              <Typography
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '16px',
                  fontWeight: 500,
                  color: theme === 'dark' ? 'white' : '#333333',
                  textAlign: 'center',
                  mb: 0.5
                }}
              >
                Unlock to see the FULL Gildan 64000 Catalog
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '18px',
                  fontWeight: 600,
                  color: theme === 'dark' ? '#EEAD1A' : '#e09e00',
                  textAlign: 'center',
                  mb: 0.5
                }}
              >
                ALL 63 Shades
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '20px',
                  fontWeight: 700,
                  color: theme === 'dark' ? '#EEAD1A' : '#e09e00',
                  textAlign: 'center',
                  mb: 2
                }}
              >
                DONE in 60 seconds!
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '15px',
                  color: 'var(--text-secondary)',
                  textAlign: 'center',
                  mb: 3
                }}
              >
                This premium feature is exclusive to Early Bird and Pro users.
                Upgrade your account for instant access to bulk processing.
              </Typography>
              {/* Only show UPGRADE NOW button for non-paid users */}
              {!isAuthenticated || (userType !== 'earlybird' && userType !== 'pro') ? (
                <GlowTextButton
                  variant="contained"
                  onClick={() => {
                    // First just navigate to the subscription page
                    navigate('/subscription');
                    
                    // Then set a timeout to scroll to the available plans section after the page loads
                    setTimeout(() => {
                      try {
                        // Find the available-plans element
                        const availablePlansElement = document.getElementById('available-plans');
                        if (availablePlansElement) {
                          // Scroll to the available plans section
                          availablePlansElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        } else {
                          // Fallback to a fixed position if element not found
                          window.scrollTo({
                            top: 500,
                            behavior: 'smooth'
                          });
                        }
                      } catch (error) {
                        console.error('Error scrolling to available plans:', error);
                        // Basic fallback just to ensure something happens
                        window.scrollTo(0, 500);
                      }
                    }, 1000); // Increased timeout to ensure page is fully loaded
                  }}
                  sx={{
                    fontSize: '0.95rem',
                    px: 3,
                    py: 1.2,
                    minWidth: '180px',
                    color: 'var(--button-text)',
                    backgroundColor: 'var(--button-bg)'
                  }}
                >
                  UPGRADE NOW
                </GlowTextButton>
              ) : null}
            </Box>
          )}

          {/* Section 9.0 - HEXTRA Color System 2 (HCS2) */}
          {/* Section title and description moved to HCS2Frame component */}
          
          {/* HCS2 Integration - iframe with the new version */}
          <Box sx={{
            width: '100%',
            minWidth: '1280px', // Force minimum width - aligned with Section 8.0
            mb: 3,
            display: 'flex', // Always display the container
            justifyContent: 'center',
            maxWidth: '1280px', // Match Section 8.0 width
            mx: 'auto', // Center the container
            overflowX: 'auto' // Allow horizontal scrolling if needed
          }}>
            <Box className="hcs2-frame-container" sx={{
              minWidth: '1280px', // Enforce minimum width for proper display
              width: '100%',
              maxWidth: '1280px'
            }}>
              <HCS2Frame 
                isDropdownOpen={showHCS2}
                onDropdownToggle={(newState) => setShowHCS2(newState)}
                onColorSelect={(color) => {
                  console.log(`Color selected from HCS2: ${color}`);
                  setHcs2SelectedColor(color);
                }}
                onColorApply={(color) => {
                  // Implementing the Golden Connection Strategy (from Golden-Connection-Strategy.md)
                  console.log('💫 GOLDEN CONNECTION in action! Received color from HCS2:', color);
                  
                  // Ensure color has a # prefix and is uppercase (HEXTRA standard)
                  const normalizedColor = (color.startsWith('#') ? color : `#${color}`).toUpperCase();
                  
                  // 1. Update application state
                  setHcs2AppliedColor(normalizedColor); // This updates the Color Preview display
                  setSelectedColor(normalizedColor);
                  
                  // 2. Set the value directly in the HEX input field
                  if (hexInputRef && hexInputRef.current) {
                    // Update the input field value
                    hexInputRef.current.value = normalizedColor;
                    
                    // Show the feedback animation
                    setShowFeedback(true);
                    setTimeout(() => setShowFeedback(false), 2000);
                    
                    // Ensure the color is registered by the input component
                    try {
                      const inputEvent = new Event('input', { bubbles: true });
                      hexInputRef.current.dispatchEvent(inputEvent);
                      console.log('✅ Successfully updated HEX input with color:', normalizedColor);
                      
                      // 3. DIRECTLY process the color like dropdown selection does
                      // This is the key change - we directly apply the color without requiring additional user action
                      console.log('🔄 Directly applying HCS2 color to product:', normalizedColor);
                      saveToRecentColors(normalizedColor);
                      
                      // Process the image with the selected color
                      if (workingImageUrl && imageLoaded) {
                        setIsProcessing(true);
                        
                        processImage(workingImageUrl, normalizedColor, selectedFormat)
                          .then(processedUrl => {
                            setWorkingProcessedUrl(processedUrl);
                            setCanDownload(true);
                            setIsProcessing(false);
                            console.log('✅ HCS2 color successfully applied to product!');
                          })
                          .catch(error => {
                            console.error('Error processing HCS2 color:', error);
                            setIsProcessing(false);
                          });
                      }
                      
                      // CRITICAL FIX: Synchronize with RGB disc and Gray Value
                      // Convert hex to RGB and update all color controls
                      const hexValue = normalizedColor.replace('#', '');
                      const r = parseInt(hexValue.substring(0, 2), 16);
                      const g = parseInt(hexValue.substring(2, 4), 16);
                      const b = parseInt(hexValue.substring(4, 6), 16);
                      
                      if (!isNaN(r) && !isNaN(g) && !isNaN(b)) {
                        // This updates the RGB disc position and Gray Value sliders
                        updateAllColorControls({ r, g, b });
                        console.log('🔄 Synchronized RGB disc position and Gray Value with:', { r, g, b });
                      }
                    } catch (e) {
                      console.warn('❌ HEX input event dispatch error:', e);
                    }
                  } else {
                    console.error('❌ HEX input reference is not available');
                  }
                  
                  // 3. Let the user explicitly hit the APPLY button (Option 2 from Golden Connection Strategy)
                  // This maintains the separation between selection and application
                  console.log('🎨 Color Preview updated with:', normalizedColor);
                }}
                initialColor={selectedColor}
              />
              {/* Section 10.0 - MegaSwatch - Smart color display with enhanced functionality */}
              <Box sx={{ mt: 0, mb: 0, width: '1280px' }}>

                {/* Using inline implementation of MegaSwatch until we can import the component */}
                <Box sx={{ 
                  width: '1280px',
                  maxWidth: '1280px',
                  height: 150,
                  borderRadius: '8px',
                  bgcolor: hcs2AppliedColor,
                  color: hcs2AppliedColor.startsWith('#') ? 
                    (parseInt(hcs2AppliedColor.slice(1), 16) > 0xffffff / 2 ? '#000' : '#fff') : '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                  border: '1px solid rgba(0,0,0,0.1)',
                  position: 'relative',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                  }
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Typography variant="h6" sx={{ 
                      fontWeight: 500,
                      letterSpacing: 0.5,
                      opacity: 0.85,
                      fontStyle: 'italic'
                    }}>
                      Current Color in Use
                    </Typography>
                    
                    {/* Extract color information */}
                    {(() => {
                      // Function to convert hex to RGB for comparison
                      const hexToRgb = (hex) => {
                        // Remove the # if present
                        hex = hex.replace(/^#/, '');
                        
                        // Handle both 3-digit and 6-digit formats
                        let r, g, b;
                        if (hex.length === 3) {
                          r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
                          g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
                          b = parseInt(hex.charAt(2) + hex.charAt(2), 16);
                        } else {
                          r = parseInt(hex.substring(0, 2), 16);
                          g = parseInt(hex.substring(2, 4), 16);
                          b = parseInt(hex.substring(4, 6), 16);
                        }
                        
                        return { r, g, b };
                      };
                      
                      // Calculate color difference (Euclidean distance in RGB space)
                      const colorDifference = (color1, color2) => {
                        const rgb1 = hexToRgb(color1);
                        const rgb2 = hexToRgb(color2);
                        
                        return Math.sqrt(
                          Math.pow(rgb1.r - rgb2.r, 2) +
                          Math.pow(rgb1.g - rgb2.g, 2) +
                          Math.pow(rgb1.b - rgb2.b, 2)
                        );
                      };
                      
                      // Normalize hex code for consistent lookup
                      const normalizedHex = hcs2AppliedColor.toUpperCase().replace(/^#/, '#');
                      
                      // Search through all catalogs for this color
                      let colorName = '';
                      let catalogName = '';
                      let colorFound = false;
                      let bestMatch = null;
                      let bestMatchDifference = 1000; // Initialize with a high value
                      
                      // Set threshold for color matching - lower values mean more exact matching
                      const COLOR_MATCH_THRESHOLD = 30;
                      
                      // First try exact match
                      for (const [catalogId, colors] of Object.entries(catalogs)) {
                        const color = colors.find(c => c.hex && c.hex.toUpperCase() === normalizedHex);
                        if (color) {
                          colorName = color.name;
                          catalogName = catalogId.replace('_', ': ');
                          colorFound = true;
                          break;
                        }
                        
                        // While searching, track the closest match as backup
                        for (const color of colors) {
                          if (color.hex) {
                            const diff = colorDifference(normalizedHex, color.hex);
                            if (diff < bestMatchDifference && diff < COLOR_MATCH_THRESHOLD) {
                              bestMatchDifference = diff;
                              bestMatch = { color, catalogId };
                            }
                          }
                        }
                      }
                      
                      // If no exact match but we found a close one, use it
                      if (!colorFound && bestMatch) {
                        colorName = bestMatch.color.name;
                        catalogName = bestMatch.catalogId.replace('_', ': ');
                        colorFound = true;
                      }
                      
                      return (
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.25 }}>
                            <Typography variant="h4" sx={{ 
                              fontWeight: 700,
                              fontSize: '32px',
                              textAlign: 'center',
                              lineHeight: 1.1,
                              mb: 0
                            }}>
                              {colorFound ? colorName.toUpperCase() : 'CUSTOM COLOR'}
                            </Typography>
                            
                            {colorFound && (
                              <Typography sx={{ 
                                fontSize: '16px',
                                fontWeight: 500,
                                opacity: 0.9,
                                mt: 0
                              }}>
                                {catalogName}
                              </Typography>
                            )}
                          </Box>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                            <Typography sx={{ 
                              fontSize: '16px',
                              fontWeight: 500,
                              letterSpacing: 0.5
                            }}>
                              {normalizedHex}
                            </Typography>
                            
                            <Tooltip title="Copy HEX code" arrow>
                              <IconButton 
                                size="small" 
                                onClick={() => {
                                  navigator.clipboard.writeText(normalizedHex);
                                  setHcs2FeedbackMessage('Color HEX code copied to clipboard!');
                                  setHcs2FeedbackOpen(true);
                                  setTimeout(() => setHcs2FeedbackOpen(false), 3000);
                                }}
                                sx={{ 
                                  color: 'inherit',
                                  opacity: 0.8,
                                  '&:hover': { opacity: 1 }
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </>
                      );
                    })()}
                  </Box>
                </Box>
              </Box>
              
              {/* Section 9.3 - HCS2 Version Info */}
              <Box sx={{ 
                mt: 2, 
                mb: 2, 
                textAlign: 'center',
                borderTop: '1px solid',
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
                pt: 1,
                background: isDarkMode 
                  ? 'linear-gradient(180deg, #222222 0%, #1a1a1a 100%)'
                  : 'linear-gradient(180deg, #f9f9f9 0%, #f0f0f0 100%)',
                borderRadius: '0 0 8px 8px',
                px: 2,
                pb: 1
              }}>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
                    fontWeight: 500
                  }}
                >
                  HEXTRA Color System 2 (HCS2) - The next generation color selection tool
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    mt: 0.5, 
                    display: 'block',
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)'
                  }}
                >
                  Version 1.3.3-gold • Build: 2025-03-20 (golden-edition)
                </Typography>
                <Button 
                  size="small" 
                  variant="text" 
                  onClick={() => {
                    // Find the HCS2Frame component and call its refresh method
                    if (window.hcs2FrameRef && window.hcs2FrameRef.current) {
                      window.hcs2FrameRef.current.refreshFooterInfo();
                    } else {
                      console.warn('HCS2Frame reference not available for refresh');
                    }
                  }}
                  sx={{ 
                    mt: 1, 
                    fontSize: '0.7rem',
                    color: isDarkMode ? '#90CAF9' : '#1976D2',
                    '&:hover': {
                      backgroundColor: isDarkMode ? 'rgba(144, 202, 249, 0.08)' : 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                >
                  REFRESH FOOTER
                </Button>
              </Box>
              
              {/* Section 12.0 - Hextra-X Footer */}
              <Box 
                component="footer"
                className="app-footer"
                sx={{ 
                  width: '100%',
                  mt: 4,
                  textAlign: 'center',
                  borderTop: isDarkMode
                    ? '1px solid rgba(0, 0, 0, 0.12)'
                    : '1px solid rgba(255, 255, 255, 0.12)',
                  background: isDarkMode 
                    ? 'linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%)'
                    : 'linear-gradient(180deg, #2d2d2d 0%, #1a1a1a 100%)',
                  position: 'relative',
                  padding: 0,
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', px: 3 }}>
                  {/* Copyright information */}
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                      fontWeight: 500,
                      lineHeight: '48px'
                    }} 
                  >
                    &copy; 2025 HEXTRA Color System - All rights reserved.
                  </Typography>
                  
                  {/* Newsletter signup button */}
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setNewsletterDialogOpen(true)}
                    sx={{
                      color: isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                      fontWeight: 500,
                      textTransform: 'none',
                      '&:hover': {
                        color: isDarkMode ? '#000' : '#fff',
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    <MailIcon sx={{ fontSize: 16, mr: 0.5 }} /> Sign Up for Newsletter
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  
  // Render the app content and dialogs
  return (
    <div>
      {mainContent}
      
      {/* Notification test button has been removed */}
      
      {/* Legacy Email Collection Dialog (kept for backward compatibility) */}
      <EmailCollectionDialog 
        open={emailDialogOpen} 
        onClose={() => setEmailDialogOpen(false)}
        onSubmit={handleEmailSubmit}
      />
      
      {/* Newsletter Signup Dialog - using EmailCollectionDialog with newsletter-specific handler */}
      <EmailCollectionDialog 
        open={newsletterDialogOpen} 
        onClose={() => setNewsletterDialogOpen(false)}
        onSubmit={handleNewsletterSubmit}
        title="Subscribe to Our Newsletter"
        message="Get the latest updates, tips, and special offers directly to your inbox!"
        submitButtonText="Subscribe"
        isNewsletter={true}
      />
      
      {/* Registration Dialog - shown after free downloads are used */}
      <RegistrationDialog
        open={registrationDialogOpen}
        onClose={() => setRegistrationDialogOpen(false)}
        freeDownloadsUsed={getFreeDownloadsUsed()}
        maxFreeDownloads={MAX_FREE_DOWNLOADS}
      />
      
      {/* Premium Dialog for Paid Users */}
      <PremiumDialog
        open={comingSoonDialogOpen}
        onClose={() => setComingSoonDialogOpen(false)}
        title="Advanced Features Coming Soon!"
        message="The advanced image control features are currently being finalized and will be available to premium users in an upcoming update."
        buttonText="Got it!"
      />
      
      {/* Admin Settings */}
      <AdminSettings />
      
      {/* Color Applied Feedback Animation */}
      <ColorAppliedFeedback show={showFeedback} color={selectedColor} />

      {/* HCS2 Toggle Feedback moved to MiniHCS2TitleBar component for centralized management */}
    </div>
  );
}

export default App;
