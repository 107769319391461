/**
 * HEXTRA-X Mobile Browser Performance Optimization
 * 
 * This module provides specific optimizations for mobile Chrome and other
 * mobile browsers to prevent unconfigurable property errors and improve
 * performance while maintaining the HEXTRA-X central spine alignment.
 */

// Detects if we're on a mobile device
export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Apply mobile-specific performance optimizations
export const applyMobileOptimizations = () => {
  if (!isMobileBrowser()) return;
  
  console.log('Applying HEXTRA-X mobile browser optimizations');
  
  // 1. Optimize React reconciliation for mobile
  if (typeof window !== 'undefined' && window.React && window.React.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED) {
    try {
      // Attempt to patch React's internal scheduler priorities on mobile
      const internals = window.React.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
      if (internals.SchedulerTracing) {
        // Give higher priority to user interactions on mobile
        const originalScheduleCallback = internals.Scheduler.scheduleCallback;
        internals.Scheduler.scheduleCallback = function(priority, callback, options) {
          // Lower the rendering priority of non-user interactions on mobile
          if (priority > 2) { // Normal or low priority
            priority += 1; // Make it slightly lower priority
          }
          return originalScheduleCallback(priority, callback, options);
        };
      }
    } catch (e) {
      console.warn('Mobile optimization: Failed to patch React internals', e);
    }
  }
  
  // 2. Prevent property descriptor errors that happen on mobile Chrome
  if (typeof Object !== 'undefined' && Object.defineProperty) {
    const originalDefineProperty = Object.defineProperty;
    
    Object.defineProperty = function(obj, prop, descriptor) {
      // Handle cases common to Suspense components that cause errors on mobile
      if (obj && 
          typeof obj === 'object' && 
          (obj.nodeType || (obj.nodeName && obj.nodeType === undefined)) && 
          (prop === 'current' || prop === '_owner' || prop === '_debugOwner' || 
           prop === '_debugSource' || prop === 'value' || prop === 'checked')) {
        
        try {
          // For problematic DOM properties, use direct assignment
          const value = descriptor.value !== undefined ? descriptor.value : 
                      (descriptor.get ? descriptor.get() : undefined);
          
          if (value !== undefined) {
            obj[prop] = value;
            return obj;
          }
        } catch (e) {
          console.warn('Mobile fix: Property assignment failed', e);
        }
      }
      
      // For all other cases, use the original method
      try {
        return originalDefineProperty.call(this, obj, prop, descriptor);
      } catch (e) {
        // If the original call fails (as it sometimes does on mobile),
        // fall back to direct property assignment
        console.warn(`Mobile fix: defineProperty failed for ${prop}, using direct assignment`);
        if (descriptor.value !== undefined) {
          obj[prop] = descriptor.value;
        }
        return obj;
      }
    };
  }
  
  // 3. Add mobile-optimized rendering hints
  document.documentElement.style.setProperty('content-visibility', 'auto');
  
  // 4. Reduce animation complexity on mobile
  document.body.classList.add('hextra-mobile-optimized');
};

// Apply idle callbacks on mobile to improve performance
export const setupMobileIdleCallbacks = () => {
  if (!isMobileBrowser()) return;
  
  // Clean up unnecessary objects during idle time
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(() => {
      // Clear caches that aren't needed after initial load
      if (window.caches && window.caches.keys) {
        window.caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            if (cacheName.includes('temp')) {
              window.caches.delete(cacheName);
            }
          });
        });
      }
      
      // Force garbage collection of image processing data
      if (window.HEXTRA_IMAGE_CACHE) {
        window.HEXTRA_IMAGE_CACHE.clear();
      }
    });
  }
};
