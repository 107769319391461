import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import KindeAuth from './components/KindeAuth';
import { ThemeProvider } from './context/ThemeContext';
import ErrorBoundary from './components/ErrorBoundary';
import { applySafariPropertyFix } from './utils/safariPropertyFix';
import { applyMobileOptimizations, setupMobileIdleCallbacks } from './utils/mobileBrowserFix';

// Apply cross-browser optimizations, focusing on Chrome Mobile
applyMobileOptimizations();
applySafariPropertyFix(); // Keep in case it helps Safari

// Defer non-critical optimizations to idle time
if ('requestIdleCallback' in window) {
  window.requestIdleCallback(() => setupMobileIdleCallbacks());
}

// Add diagnostic logging to help debug Vercel deployment issues
console.log('App: Starting initialization...', {
  environment: process.env.NODE_ENV,
  buildTime: new Date().toISOString(),
  userAgent: navigator.userAgent,
  documentReady: !!document.getElementById('root')
});

// Attempt to detect if we're in a production environment
const isProduction = process.env.NODE_ENV === 'production';
console.log('Environment detection:', { isProduction });

// Create a safety wrapper to catch any errors during initialization
try {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  
  // Render the app with error boundary
  // Removed StrictMode to prevent suspension errors
  root.render(
    <ErrorBoundary>
      <ThemeProvider>
        <KindeAuth />
      </ThemeProvider>
    </ErrorBoundary>
  );
  
  console.log('App: Render complete');
} catch (error) {
  console.error('CRITICAL ERROR during app initialization:', error);
  
  // Display a fallback UI if React fails to initialize
  const rootElement = document.getElementById('root');
  if (rootElement) {
    rootElement.innerHTML = `
      <div style="padding: 20px; margin: 20px; border: 1px solid #f5c6cb; border-radius: 4px; background-color: #f8d7da; color: #721c24">
        <h2>Application Failed to Initialize</h2>
        <p>We're sorry, but something went wrong during application startup.</p>
        <p>Error: ${error.message}</p>
        <button onclick="window.location.reload()" style="padding: 8px 16px; background-color: #dc3545; color: white; border: none; border-radius: 4px; cursor: pointer">
          Reload Page
        </button>
      </div>
    `;
  }
}
