import React, { Suspense } from 'react';

/**
 * Safari Compatible Suspense Component
 * 
 * This component provides a wrapper around React.Suspense to prevent
 * the "Attempting to change the setter of an unconfigurable property" error
 * in Safari and mobile browsers.
 * 
 * It maintains HEXTRA-X's central spine and container principles while
 * ensuring cross-browser compatibility.
 */
const SafariCompatibleSuspense = (props) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  // For Safari and mobile, use a safer approach
  if (isSafari || isMobile) {
    const { fallback, children, ...rest } = props;
    
    // Using a stable wrapper with key prevents React from manipulating
    // problematic DOM properties in Safari/mobile
    return (
      <div key="safari-suspense-wrapper" {...rest}>
        {/* If we already have the content, just render it directly */}
        {React.Children.map(children, child => {
          // This mapping process creates a new stable element tree
          return React.isValidElement(child) 
            ? React.cloneElement(child, { key: child.key || 'safari-child' })
            : child;
        })}
        
        {/* A hidden fallback that won't trigger property descriptor issues */}
        <div style={{ display: 'none' }}>
          {fallback}
        </div>
      </div>
    );
  }
  
  // For other browsers, use normal Suspense
  return <Suspense {...props} />;
};

export default SafariCompatibleSuspense;
