import React, { useState, useEffect } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * ColorAppliedFeedback - A component that displays a cool animation
 * when a color is applied from HCS2 to the main application
 */
const ColorAppliedFeedback = ({ show, color }) => {
  const [step, setStep] = useState(0);
  
  useEffect(() => {
    if (show) {
      // Reset to first step
      setStep(0);
      
      // Sequence through steps
      const timers = [
        setTimeout(() => setStep(1), 300),  // "HEX"
        setTimeout(() => setStep(2), 600),  // "code"
        setTimeout(() => setStep(3), 900),  // "Applied!"
      ];
      
      return () => {
        // Clean up timers
        timers.forEach(timer => clearTimeout(timer));
      };
    }
  }, [show]);
  
  if (!show) return null;
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '12px',
        padding: '20px 40px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
        color: 'white',
        pointerEvents: 'none', // Prevent interaction
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box 
          sx={{ 
            width: '24px', 
            height: '24px', 
            borderRadius: '50%', 
            backgroundColor: color || '#EEAD1A',
            mr: 2,
            boxShadow: '0 0 10px rgba(238, 173, 26, 0.8)'
          }} 
        />
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {color || '#EEAD1A'}
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px' }}>
        <Fade in={step === 0}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold',
              position: 'absolute',
              opacity: step === 0 ? 1 : 0,
              transition: 'all 0.3s ease',
              transform: step === 0 ? 'scale(1)' : 'scale(0.8)',
            }}
          >
            HEX
          </Typography>
        </Fade>
        
        <Fade in={step === 1}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold',
              position: 'absolute',
              opacity: step === 1 ? 1 : 0,
              transition: 'all 0.3s ease',
              transform: step === 1 ? 'scale(1)' : 'scale(0.8)',
            }}
          >
            code
          </Typography>
        </Fade>
        
        <Fade in={step === 2}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold',
              position: 'absolute',
              opacity: step === 2 ? 1 : 0,
              transition: 'all 0.3s ease',
              transform: step === 2 ? 'scale(1)' : 'scale(0.8)',
              color: '#4CAF50',
            }}
          >
            Applied!
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};

ColorAppliedFeedback.propTypes = {
  /** Whether to show the feedback animation */
  show: PropTypes.bool.isRequired,
  /** The color that was applied */
  color: PropTypes.string
};

export default ColorAppliedFeedback;
