/**
 * HEXTRA-X Version Information
 * 
 * This file serves as the central source of truth for version information
 * throughout the application. All components should import version details
 * from this file rather than defining them locally.
 */

export const VERSION = '2.3.2';
export const BUILD_DATE = '2025-03-28';
export const BUILD_ID = 'HEXTRA-2025-03-28-MC2302';
