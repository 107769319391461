import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Box, Typography, IconButton, Button, Tooltip, Divider, Menu, MenuItem, ListItemIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AboutBox from './AboutBox';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { VERSION } from '../version';
import GlowIconButton from './GlowIconButton';
import { useLocation } from 'react-router-dom';
import UserProfileMenu from './UserProfileMenu';
import { useAuth } from '../context/AuthContext';
import { safeNavigate, scrollToElement } from '../utils/navigation';
import TokenSystem from './TokenSystem';
import { useTheme } from '../context/ThemeContext';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SvgIcon from '@mui/material/SvgIcon';

const BRAND_COLORS = ['#D50032', '#00805E', '#224D8F'];  // Red, Green, Blue

// Define keyframe animations for the badges
const keyframes = `
  @keyframes crownPulse { 
    0% { transform: scale(1) rotate(10deg); opacity: 0.9; } 
    100% { transform: scale(1.15) rotate(10deg); opacity: 1; } 
  }
  @keyframes starPulse { 
    0% { transform: scale(1) rotate(10deg); opacity: 0.9; } 
    100% { transform: scale(1.15) rotate(15deg); opacity: 1; } 
  }
  @keyframes levelUpPulse {
    0% { color: #FED141; text-shadow: 0 0 0px rgba(254, 209, 65, 0); }
    50% { color: #FFC700; text-shadow: 0 0 5px rgba(254, 209, 65, 0.5); }
    100% { color: #FED141; text-shadow: 0 0 0px rgba(254, 209, 65, 0); }
  }
  @keyframes levelUpPulseBlue {
    0% { color: #224D8F; text-shadow: 0 0 0px rgba(34, 77, 143, 0); }
    50% { color: #3A6EBF; text-shadow: 0 0 5px rgba(34, 77, 143, 0.5); }
    100% { color: #224D8F; text-shadow: 0 0 0px rgba(34, 77, 143, 0); }
  }
  @keyframes badgeGlow {
    0% { box-shadow: 0 3px 10px rgba(0,0,0,0.3), 0 0 15px rgba(0, 0, 0, 0.5); }
    100% { box-shadow: 0 5px 15px rgba(0,0,0,0.4), 0 0 20px rgba(0, 0, 0, 0.7); }
  }
`;

const Banner = ({
  version,
  isDarkMode = false,
  onThemeToggle,
  isBatchMode,
  setIsBatchMode,
  setShowSubscriptionTest,
  derivedUserType = null
}) => {
  const [aboutOpen, setAboutOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  
  // Add keyframes to the document
  React.useEffect(() => {
    // Check if the style element already exists
    const existingStyle = document.getElementById('banner-badge-keyframes');
    if (!existingStyle) {
      const styleElement = document.createElement('style');
      styleElement.id = 'banner-badge-keyframes';
      styleElement.innerHTML = keyframes;
      document.head.appendChild(styleElement);
    }
    
    // Cleanup on unmount
    return () => {
      const styleElement = document.getElementById('banner-badge-keyframes');
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);
  const { login } = useKindeAuth();
  const { isAuthenticated, user, userType, isLoading, logout } = useAuth();
  // Using our custom navigation utility instead of useNavigate
  const location = useLocation();
  const isSubscriptionPage = location.pathname === '/subscription' || location.pathname === '/';
  // Removed useTransition to avoid potential initialization errors
  const [emailUser, setEmailUser] = useState(null);

  // Load email user from localStorage if available
  useEffect(() => {
    const savedEmailUser = localStorage.getItem('hextra_email_user');
    if (savedEmailUser) {
      try {
        setEmailUser(JSON.parse(savedEmailUser));
      } catch (error) {
        console.error('Failed to parse saved email user data:', error);
      }
    }
  }, []);

  const userInitial = useMemo(() => {
    if (isAuthenticated && user?.given_name) {
      return user.given_name[0].toUpperCase();
    } else if (emailUser?.email) {
      // Make sure we're actually getting the first character of the email
      // and handle the case where email might be an empty string
      const email = emailUser.email.trim();
      return email && email.length > 0 ? email[0].toUpperCase() : 'E';
    }
    return 'F'; // F for Free user
  }, [isAuthenticated, user?.given_name, emailUser?.email]);

  const userColor = useMemo(() => {
    if (isAuthenticated && user?.email) {
      // Blue for PRO users (authenticated)
      return BRAND_COLORS[2]; 
    } else if (emailUser?.email) {
      // Red for email-only users (Mailchimp subscribers)
      return BRAND_COLORS[0]; 
    }
    // Red for anonymous/free users
    return BRAND_COLORS[0]; 
  }, [isAuthenticated, user?.email, emailUser?.email]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  const handleLogout = () => {
    const batchSection = document.querySelector('.batch-processing-section');
    if (batchSection) {
      batchSection.scrollIntoView({ behavior: 'smooth' });
    }

    setTimeout(() => {
      logout({
        post_logout_redirect_uri: `${window.location.origin}/#batch-section`
      });
    }, 800); 
  };

  const scrollToBatch = () => {
    const batchSection = document.querySelector('.batch-processing-section');
    if (batchSection) {
      batchSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const COLORS = {
    textDark: 'rgba(255, 255, 255, 0.7)',
    textLight: 'rgba(0, 0, 0, 0.7)'
  };

  return (
    <Box
      className="app-banner"
      sx={{
        width: '100%',
        margin: 0,
        padding: 0,
        overflow: 'visible',
        height: '40px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000
      }}
    >
      <Box
        className="banner-content"
        sx={{
          background: isDarkMode 
            ? 'linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%)'
            : 'linear-gradient(180deg, #2d2d2d 0%, #1a1a1a 100%)',
          borderBottom: isDarkMode
            ? '1px solid rgba(0, 0, 0, 0.12)'
            : '1px solid rgba(255, 255, 255, 0.12)',
          width: '100%',
          display: 'flex',
          height: '62px',
          margin: 0,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: 'relative',
          padding: 0,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '22px',
            background: 'inherit',
            borderBottom: 'inherit',
            zIndex: 1050
          }
        }}
      >
        {/* Left side - About and Version */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'flex-start',
          minWidth: '140px',
          position: 'relative',
          zIndex: 1200,
          mt: 1,
          ml: 3
        }}>
          <Typography
            component="span"
            sx={{
              color: isDarkMode ? COLORS.textLight : COLORS.textDark,
              fontSize: '0.75rem',
              cursor: 'pointer',
              '&:hover': {
                color: '#FED141',
                textShadow: '0 0 8px rgba(254, 209, 65, 0.4)'
              }
            }}
            onClick={() => setAboutOpen(true)}
          >
            About • v{version}
          </Typography>
          {/* User Email or Subscription Prompt */}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
            {/* HEXTRA Token System - Roman Coin Icon */}
            <Tooltip title="HEXTRA Token System (Coming Soon)">
              <IconButton 
                onClick={() => {
                  // Show the token system dialog directly
                  const tokenSystemDialog = document.getElementById('token-system-dialog');
                  if (tokenSystemDialog) {
                    tokenSystemDialog.style.display = 'block';
                  }
                }}
                size="small"
                sx={{ 
                  mr: 0.25,
                  p: 0.25,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { transform: 'rotate(12deg)' }
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="img"
                    src="/images/HEXTRA-Token.svg"
                    alt="HEXTRA Token"
                    sx={{ 
                      width: '32px',
                      height: '32px',
                      filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.3))',
                    }}
                  />
                  {derivedUserType === 'earlybird' && (
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: -2, 
                        right: -2, 
                        width: 8, 
                        height: 8, 
                        borderRadius: '50%', 
                        bgcolor: '#4caf50',
                        border: '1px solid white'
                      }} 
                    />
                  )}
                </Box>
              </IconButton>
            </Tooltip>
            
            <Typography
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '16px',
                color: isDarkMode ? COLORS.textLight : COLORS.textDark,
                fontSize: isAuthenticated ? '0.7rem' : '0.8rem',
                fontStyle: isAuthenticated ? 'normal' : 'italic',
                fontWeight: isAuthenticated ? 400 : 600,
                cursor: isAuthenticated ? 'default' : 'pointer',
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                animation: isAuthenticated ? 'none' : (isDarkMode ? 'levelUpPulseBlue 3s infinite ease-in-out' : 'levelUpPulse 3s infinite ease-in-out'),
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: isAuthenticated ? (isDarkMode ? COLORS.textLight : COLORS.textDark) : (isDarkMode ? '#3A6EBF' : '#FFC700'),
                  textDecoration: isAuthenticated ? 'none' : 'underline',
                  textShadow: isAuthenticated ? 'none' : (isDarkMode ? '0 0 8px rgba(34, 77, 143, 0.7)' : '0 0 8px rgba(254, 209, 65, 0.7)')
                }
              }}
              onClick={() => {
                if (!isAuthenticated) {
                  // Using our navigation utility to handle navigation safely
                  if (isSubscriptionPage) {
                    // If already on subscription page, scroll directly to plans section
                    console.log('Scrolling to Available Plans section');
                    scrollToElement('available-plans', {
                      block: 'start'
                    });
                  } else {
                    // Navigate to subscription page using our safe navigation utility
                    // This avoids the React suspension issues
                    safeNavigate('/subscription');
                  }
                }
              }}
            >
              {isAuthenticated && user?.email ? user.email : 'Level-Up'}
            </Typography>
          </Box>
        </Box>

        {/* Center - Logo */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1100,
            marginTop: '-5px'
          }}
        >
          <Box
            component="img"
            src={isDarkMode ? '/images/HEXTRA-X-logo-Wht.svg' : '/images/HEXTRA-X-logo-Blk.svg'}
            alt="HEXTRA-X"
            onClick={() => {
              // Use safeNavigate instead of direct window.location to avoid suspension errors
              safeNavigate('/app');
            }}
            sx={{
              height: '90px',
              width: 'auto',
              objectFit: 'contain',
              display: 'block',
              position: 'relative',
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.03)'
              }
            }}
          />
        </Box>

        {/* Right side - Controls */}
        <Box sx={{ 
          minWidth: '140px', 
          display: 'flex', 
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1.5,
          position: 'relative',
          zIndex: 1200,
          mr: 2,
          transform: 'translateX(-8px)',
          mt: 1.5
        }}>

          {/* Container demos now accessible via Shift+D keyboard shortcut */}
          
          {/* Theme Toggle - Simple black/white disc */}
          <Tooltip title={`Switch to ${isDarkMode ? 'Light' : 'Dark'} Mode`}>
            <IconButton 
              onClick={onThemeToggle}
              sx={{
                width: '32px',
                height: '32px',
                position: 'relative',
                padding: 0,
                overflow: 'hidden',
                backgroundColor: 'transparent',
                '&:hover': {
                  transform: 'scale(1.05)',
                  backgroundColor: 'transparent',
                  borderColor: '#FFD700',
                  boxShadow: '0 0 0 3px rgba(255, 215, 0, 0.25)'
                }
              }}
            >
              <Box
                sx={{
                  width: '28px',
                  height: '28px',
                  borderRadius: '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  border: '1px solid rgba(128, 128, 128, 0.5)',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '200%',
                    height: '200%',
                    top: '-50%',
                    left: '-50%',
                    background: `linear-gradient(135deg, 
                      ${isDarkMode ? '#ffffff' : '#000000'} 0%, 
                      ${isDarkMode ? '#ffffff' : '#000000'} 49%, 
                      ${isDarkMode ? '#000000' : '#ffffff'} 51%, 
                      ${isDarkMode ? '#000000' : '#ffffff'} 100%)`,
                    transformOrigin: 'center',
                  }
                }}
              />
            </IconButton>
          </Tooltip>





          {/* User Profile Menu */}
          {!isLoading && <UserProfileMenu derivedUserType={derivedUserType} />}
        </Box>
      </Box>

      {/* Token System Dialog - Hidden by default, shown when gold coin is clicked */}
      <Box 
        id="token-system-dialog"
        sx={{ 
          display: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 2000,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        ref={(el) => {
          if (el) {
            // Override the display style to ensure it works
            let originalDisplay = el.style.display;
            Object.defineProperty(el.style, 'display', {
              get: function() { return originalDisplay; },
              set: function(value) {
                originalDisplay = value;
                if (value === 'block') {
                  el.style.setProperty('display', 'flex', 'important');
                } else {
                  el.style.setProperty('display', value);
                }
              }
            });
          }
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            e.currentTarget.style.display = 'none';
          }
        }}
      >
        <Box sx={{ 
          width: '90%',
          maxWidth: '500px',
          backgroundColor: isDarkMode ? '#333' : '#fff',
          borderRadius: '8px',
          padding: '20px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
          position: 'relative'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ 
              width: '64px', 
              height: '64px', 
              mr: 1, 
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/HEXTRA-Token.svg`}
                alt="HEXTRA Token"
                sx={{ 
                  width: '64px',
                  height: '64px',
                  filter: 'drop-shadow(0px 0px 4px rgba(255,215,0,0.5))'
                }}
              />
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', height: '64px' }}>
              HEXTRA Token System
            </Typography>
            <IconButton 
              sx={{ 
                position: 'absolute', 
                right: '10px', 
                top: '10px',
                color: isDarkMode ? '#fff' : '#333'
              }}
              onClick={() => {
                document.getElementById('token-system-dialog').style.display = 'none';
              }}
            >
              ✕
            </IconButton>
          </Box>
          
          <Typography variant="h5" sx={{ mb: 2, color: '#FFC108', fontWeight: 'bold' }}>
            Coming Soon to HEXTRA
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 2 }}>
            We're introducing a token system to provide a fair and sustainable way to manage downloads:
          </Typography>
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              How Tokens Work
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              • Each download costs 1 token
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              • Catalog downloads (any size) cost only 10 tokens
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              • Monthly token allocation based on your subscription tier
            </Typography>
          </Box>
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Monthly Token Allocation
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Box sx={{ 
                    width: '16px', 
                    height: '16px', 
                    borderRadius: '50%', 
                    bgcolor: '#D50032', 
                    mr: 1 
                  }} />
                  <Typography variant="body2" sx={{ mr: 0.5 }}>|</Typography>
                  <Typography variant="body2">Free Loader</Typography>
                </Box>
              </Box>
              <Typography variant="body2">3 tokens/month</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Box sx={{ 
                    width: '16px', 
                    height: '16px', 
                    borderRadius: '50%', 
                    bgcolor: '#FFAA00', 
                    mr: 1 
                  }} />
                  <Typography variant="body2" sx={{ mr: 0.5 }}>|</Typography>
                  <Typography variant="body2">Verified</Typography>
                </Box>
              </Box>
              <Typography variant="body2">10 tokens/month</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Box sx={{ 
                    width: '16px', 
                    height: '16px', 
                    borderRadius: '50%', 
                    bgcolor: '#00805E', 
                    mr: 1 
                  }} />
                  <Typography variant="body2" sx={{ mr: 0.5 }}>|</Typography>
                  <Typography variant="body2">Early-Bird</Typography>
                </Box>
              </Box>
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#FFD700' }}>
                Unlimited Access (Limited Time)
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <Box sx={{ 
                    width: '16px', 
                    height: '16px', 
                    borderRadius: '50%', 
                    bgcolor: '#224D8F', 
                    mr: 1 
                  }} />
                  <Typography variant="body2" sx={{ mr: 0.5 }}>|</Typography>
                  <Typography variant="body2">Pro</Typography>
                </Box>
              </Box>
              <Typography variant="body2">100 tokens/month</Typography>
            </Box>
          </Box>
          
          {derivedUserType === 'earlybird' && (
            <Box sx={{ 
              p: 2, 
              bgcolor: isDarkMode ? '#3a2f0b' : '#fff8e1', 
              borderRadius: 1,
              border: '1px solid',
              borderColor: isDarkMode ? '#ffd54f' : '#ffb300',
              mb: 3
            }}>
              <Typography variant="subtitle1" sx={{ 
                fontWeight: 'bold', 
                mb: 1,
                color: isDarkMode ? '#ffd54f' : '#ff8f00'
              }}>
                ✨ Early Bird Advantage
              </Typography>
              <Typography variant="body2">
                As an Early-Bird member, you'll enjoy <strong>unlimited downloads</strong> for a limited time after we introduce the token system. This is our way of saying thank you for your early support!
              </Typography>
            </Box>
          )}
          
          <Button 
            variant="contained" 
            fullWidth
            onClick={() => {
              document.getElementById('token-system-dialog').style.display = 'none';
            }}
            sx={{ 
              mt: 2,
              bgcolor: '#00805E',  // Green color for Early-Bird attraction
              color: '#fff',
              '&:hover': {
                bgcolor: '#00A67E'  // Lighter green on hover
              }
            }}
          >
            Got it!
          </Button>
        </Box>
      </Box>
      
      <AboutBox
        open={aboutOpen}
        onClose={() => setAboutOpen(false)}
        navigateToPlans={() => {
          setAboutOpen(false);
          
          // Check if already on subscription page
          const isSubscriptionPage = window.location.pathname === '/subscription';
          
          // Function to scroll to Available Plans section
          const scrollToAvailablePlans = () => {
            console.log('Scrolling to Available Plans section from AboutBox');
            const availablePlansElement = document.getElementById('available-plans');
            if (availablePlansElement) {
              availablePlansElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
              console.log('Available Plans element not found');
            }
          };
          
          if (isSubscriptionPage) {
            // If already on subscription page, scroll directly
            scrollToAvailablePlans();
          } else {
            // Navigate to subscription page first, then scroll after page loads
            safeNavigate('/subscription');
            // Use a longer timeout to ensure the page is fully loaded
            setTimeout(scrollToAvailablePlans, 1000);
          }
        }}
      />
    </Box>
  );
};

export default Banner;
