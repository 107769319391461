import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import GlowButton from './GlowButton';

/**
 * RegistrationDialog Component
 * 
 * Prompts users to register for a Kinde account after they've used their free downloads.
 */
const RegistrationDialog = ({ open, onClose, freeDownloadsUsed = 3, maxFreeDownloads = 3 }) => {
  const { login } = useKindeAuth();

  // Handle registration via Kinde
  const handleRegister = () => {
    console.log('📝 Initiating Kinde registration redirect from RegistrationDialog...');
    login({
      authOnMount: false,
      appState: { targetUrl: window.location.pathname },
      openerEnabled: false,
      registration: true // This forces the registration page instead of login
    });
    // Close the dialog
    onClose();
  };

  // Handle login via Kinde
  const handleLogin = () => {
    console.log('🔑 Initiating Kinde login redirect from RegistrationDialog...');
    login({
      authOnMount: false,
      appState: { targetUrl: window.location.pathname },
      openerEnabled: false
    });
    // Close the dialog
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#1E1E1E',
          borderRadius: '16px',
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
        <Typography variant="h4" component="div" fontWeight="bold" sx={{ mb: 1 }}>
          You've Used All Free Downloads
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You've used all {maxFreeDownloads} of your free downloads.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Create a free account to continue downloading images and access more features!
          </Typography>
          
          <Divider sx={{ my: 3 }} />
          
          <Box sx={{ my: 3 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
              Benefits of Creating an Account:
            </Typography>
            <Box sx={{ textAlign: 'left', pl: 2 }}>
              <ul style={{ paddingInlineStart: '20px' }}>
                <li>Unlimited single image downloads</li>
                <li>Save your download history</li>
                <li>Option to upgrade for bulk downloads</li>
                <li>Access to premium features</li>
              </ul>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ flexDirection: 'column', pb: 3, px: 3 }}>
        <GlowButton
          onClick={handleRegister}
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 1 }}
        >
          Create Free Account
        </GlowButton>
        
        <Button 
          onClick={handleLogin}
          fullWidth
          variant="text"
          color="primary"
          sx={{ mt: 1 }}
        >
          Already have an account? Log in
        </Button>
        
        <Button
          onClick={onClose}
          fullWidth
          variant="text"
          color="inherit"
          sx={{ mt: 1, opacity: 0.7 }}
        >
          Maybe Later
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegistrationDialog;
