/**
 * KindeAuth Component (v2.2.5)
 * 
 * Provides authentication wrapper for the application using Kinde Auth.
 * Handles the OAuth2 PKCE flow and redirects.
 * 
 * Flow:
 * 1. User clicks sign in
 * 2. Kinde handles authentication
 * 3. Redirects back to callback URL
 * 4. Processes tokens and redirects to /app
 * 
 * This version includes support for both:
 * - KINDE_* variables (standard pattern)
 * - REACT_APP_KINDE_* variables (CRA pattern)
 * 
 * Environment variables:
 * KINDE_CLIENT_ID or REACT_APP_KINDE_CLIENT_ID
 * KINDE_ISSUER_URL or REACT_APP_KINDE_DOMAIN
 * KINDE_POST_LOGIN_REDIRECT_URL or REACT_APP_KINDE_REDIRECT_URI
 * KINDE_POST_LOGOUT_REDIRECT_URL or REACT_APP_KINDE_LOGOUT_URI
 * 
 * @version 2.2.5
 * @lastUpdated 2025-03-10
 */

import React from 'react';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../context/AuthContext';
import App from '../App';
import CallbackPage from './CallbackPage';
import Banner from './Banner';
import { VERSION } from '../version';
import { Box, Chip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SafariCompatibleSuspense from './SafariCompatibleSuspense';
import CentralSpine from './layout/CentralSpine';

// Import DownloadStats component
const DownloadStats = React.lazy(() => import('./DownloadStats'));

// Development mode auth override
const isDev = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
if (isDev) {
  // Force localStorage to indicate PRO user in dev mode
  localStorage.setItem('hextra_dev_simulate_pro', 'true');
  localStorage.setItem('hextra_user_state', JSON.stringify({
    type: 'pro',
    isLoading: false
  }));
  console.log('🔥 DEVELOPMENT MODE: Forcing PRO user status via localStorage');
}

// Lazy load the SubscriptionPage to improve performance
const SubscriptionPage = React.lazy(() => import('./SubscriptionPage'));
// Import test component
const TestSubscription = React.lazy(() => import('../testSubscription'));
// Import ProfilePage component
const ProfilePage = React.lazy(() => import('./ProfilePage'));
// Import TestLoginPage component
const TestLoginPage = React.lazy(() => import('./TestLoginPage'));
// Import MailChimpTest component
const MailChimpTest = React.lazy(() => import('./MailChimpTest'));
// Import AboutPage component
const AboutPage = React.lazy(() => import('./AboutPage'));
// Import EnhancedLegoExample component
const EnhancedLegoExample = React.lazy(() => import('../components/example/EnhancedLegoExample'));

// Layout component for consistent UI across routes
const Layout = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = React.useState(true);
  const [isBatchMode, setIsBatchMode] = React.useState(false);
  const [showSubscriptionTest, setShowSubscriptionTest] = React.useState(false);

  // Wrap the entire layout in a SafariCompatibleSuspense boundary to handle lazy-loaded components
  return (
    <SafariCompatibleSuspense fallback={
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: isDarkMode ? '#1a1a1a' : '#ffffff',
        color: isDarkMode ? '#ffffff' : '#000000'
      }}>
        <div>Loading...</div>
      </Box>
    }>
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: isDarkMode ? '#1a1a1a' : '#ffffff',
        color: isDarkMode ? '#ffffff' : '#000000',
        position: 'relative' // Needed for proper positioning of spine lines
      }}>
        {/* Global CentralSpine component for keyboard shortcuts and alignment guides */}
        <CentralSpine initialGlobalVisible={false} initialContainerVisible={false} />
        
        <Banner 
          version={VERSION}
          isDarkMode={isDarkMode}
          onThemeToggle={() => setIsDarkMode(!isDarkMode)}
          isBatchMode={isBatchMode}
          setIsBatchMode={setIsBatchMode}
          setShowSubscriptionTest={setShowSubscriptionTest}
        />
        <Box sx={{ flex: 1, mt: '62px' }}>
          {children}
        </Box>
      </Box>
    </SafariCompatibleSuspense>
  );
};

export default function KindeAuth({ children }) {
  console.log('KindeAuth: Component initializing...');
  
  // Enhanced diagnostic logging for Vercel debugging
  try {
    console.log('KindeAuth: Environment information', {
      hostname: window.location.hostname,
      pathname: window.location.pathname,
      href: window.location.href,
      protocol: window.location.protocol,
      envNodeEnv: process.env.NODE_ENV,
      hasReactAppPrefix: Object.keys(process.env).some(key => key.startsWith('REACT_APP_')),
      hasKindePrefix: Object.keys(process.env).some(key => key.startsWith('KINDE_'))
    });
  } catch (error) {
    console.error('KindeAuth: Error logging environment information', error);
  }

  // Get the environment variables with fallbacks
  const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const isProduction = window.location.hostname === 'hextra.io' || window.location.hostname === 'www.hextra.io';
  console.log('KindeAuth: Environment detection', { isDevelopment, isProduction });
  
  // ALWAYS use real Kinde credentials, even in development mode
  // Fallback clientId directly to the known working value if env vars are not set
  const clientId = process.env.KINDE_CLIENT_ID || process.env.REACT_APP_KINDE_CLIENT_ID || '0bd4c1c6f92d46f7b72290073d1806c7';
  const domain = process.env.KINDE_ISSUER_URL || process.env.REACT_APP_KINDE_DOMAIN || 'https://hextra.kinde.com';
  
  // CRITICAL FIX: Use dynamic origin for redirectUri instead of hardcoding localhost
  // This ensures it works in both development and production environments
  const origin = window.location.origin; // Gets current domain (localhost or production URL)
  const redirectUri = process.env.KINDE_POST_LOGIN_REDIRECT_URL || process.env.REACT_APP_KINDE_REDIRECT_URI || `${origin}/api/auth/kinde/callback`;
  const logoutUri = process.env.KINDE_POST_LOGOUT_REDIRECT_URL || process.env.REACT_APP_KINDE_LOGOUT_URI || origin;

  // Debug output to help troubleshoot
  console.log("KindeAuth: Configuration", {
    isDevelopment,
    clientIdSet: !!clientId,
    domainSet: !!domain,
    redirectUriSet: !!redirectUri,
    logoutUriSet: !!logoutUri,
    configuredRedirectUri: redirectUri, // Show the actual URI for debugging
    hostname: window.location.hostname,
    origin: window.location.origin,
    productionUrls: {
      // Kinde allowed production callback URLs
      callbacks: [
        'https://hextra.io/api/auth/kinde/callback',
        'https://www.hextra.io/api/auth/kinde/callback'
      ],
      // Kinde allowed logout redirect URLs
      logoutRedirects: [
        'https://hextra.io',
        'https://www.hextra.io'
      ]
    },
    localStorage: {
      hextra_user_state: localStorage.getItem('hextra_user_state'),
      hextra_dev_simulate_pro: localStorage.getItem('hextra_dev_simulate_pro'),
      hextra_email_user: localStorage.getItem('hextra_email_user')
    }
  });

  const config = {
    // Required Kinde configuration
    clientId,
    domain,
    redirectUri,
    logoutUri,

    // Auth configuration
    responseType: 'code',  // Use authorization code flow
    scope: 'openid profile email',  // Required scopes
    
    // Development mode handling
    isDevelopmentMode: isDevelopment,

    // CRITICAL FIX: Ensure consistent authentication behavior
    authOnMount: false, // Don't auto-login, let user trigger it
    openerEnabled: false, // CRITICAL: Never use popups, ALWAYS redirect
    
    // Redirect handler with smarter logic
    onRedirectCallback: (appState) => {
      console.log("onRedirectCallback called with:", appState);
      
      // Check if there's a targetUrl in the appState
      const targetUrl = appState?.targetUrl || '/app';
      
      // CRITICAL FIX: Always redirect authenticated users to app or target page
      console.log(`✅ Redirecting authenticated user to ${targetUrl}`);
      window.location.href = targetUrl;
    },
    
    // Add detailed login debugging for all auth events
    onLoginError: (error) => {
      console.error('🔴 Kinde Login Error:', error);
    },
    
    onLogin: () => {
      console.log('🔐 Kinde Login initiated');
    }
    
  };

  return (
    <KindeProvider {...config}>
      {isDevelopment && (
        <div style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '8px',
          borderRadius: '4px',
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          alignItems: 'flex-end'
        }}>
          <Chip
            icon={<VerifiedUserIcon />}
            label="PRO ACCESS FORCE-ENABLED"
            color="primary"
            size="small"
            variant="outlined"
            style={{ backgroundColor: 'rgba(0, 102, 255, 0.9)', color: 'white', fontWeight: 'bold' }}
          />
          <div style={{
            backgroundColor: 'rgba(255, 165, 0, 0.8)',
            color: 'black',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            zIndex: 9999
          }}>
            Dev Mode: Using real auth credentials
          </div>
        </div>
      )}
      {/* Wrap Router with AuthProvider to ensure auth context is properly available */}
      <AuthProvider>
        <Router>
        <Routes>
          {/* All routes now use direct components instead of lazy-loaded ones to prevent suspension errors */}
          {/* Make Subscription Page the home page */}
          <Route path="/" element={<Layout>
            <SafariCompatibleSuspense fallback={<div>Loading subscription page...</div>}>
              <SubscriptionPage />
            </SafariCompatibleSuspense>
          </Layout>} />
          
          {/* App is now at /app path */}
          <Route path="/app" element={<Layout>
            <SafariCompatibleSuspense fallback={<div>Loading app...</div>}>
              <App />
            </SafariCompatibleSuspense>
          </Layout>} />
          
          {/* Auth callback route */}
          <Route path="/api/auth/kinde/callback" element={<CallbackPage />} />
          <Route path="/callback" element={<CallbackPage />} />
          
          {/* Keep subscription route for backward compatibility */}
          <Route path="/subscription" element={<Layout>
            <SafariCompatibleSuspense fallback={<div>Loading subscription page...</div>}>
              <SubscriptionPage />
            </SafariCompatibleSuspense>
          </Layout>} />
          
          {/* About Page - SEO optimized */}
          <Route path="/about" element={<Layout><AboutPage /></Layout>} />
          
          {/* Profile Pages */}
          <Route 
            path="/profile" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading...</div>}>
                  <ProfilePage />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />
          
          {/* LEGO Container Example */}
          <Route 
            path="/lego-example" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading LEGO Container example...</div>}>
                  <EnhancedLegoExample />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />
          
          {/* Admin Dashboard */}
          <Route 
            path="/admin/downloads" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading dashboard...</div>}>
                  <DownloadStats />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />

          {/* Test Pages */}
          <Route 
            path="/test-subscription" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading...</div>}>
                  <TestSubscription />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />
          <Route 
            path="/test-login" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading...</div>}>
                  <TestLoginPage />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />
          <Route 
            path="/mailchimp-test" 
            element={
              <Layout>
                <SafariCompatibleSuspense fallback={<div>Loading...</div>}>
                  <MailChimpTest />
                </SafariCompatibleSuspense>
              </Layout>
            } 
          />
        </Routes>
        </Router>
      </AuthProvider>
    </KindeProvider>
  );
}
