/**
 * UserBadge Component
 * 
 * A stylish badge component that displays user status with consistent styling
 * across the application. Supports different colors and optional trinkets.
 */

import React from 'react';
import { Box, Tooltip } from '@mui/material';

const UserBadge = ({ 
  initial = 'F', 
  userType = 'free',
  tooltipText = 'HEXTRA User',
  onClick = null
}) => {
  // Determine badge styling based on user type
  const getBadgeStyles = () => {
    // Default styles (for free users - red)
    let backgroundColor = '#D50032'; // Red
    let badgeText = 'F'; // Default for free users
    let hasTrinket = false;
    let trinketSymbol = '';
    
    switch (userType) {
      case 'pro':
        backgroundColor = '#224D8F'; // Blue
        badgeText = 'P'; // Pro user
        hasTrinket = true;
        trinketSymbol = '♛'; // Crown
        break;
      case 'earlybird':
        backgroundColor = '#00805E'; // Green
        badgeText = 'E'; // Early-bird user
        hasTrinket = true;
        trinketSymbol = '★'; // Star
        break;
      case 'email':
        backgroundColor = '#F39C12'; // Amber/Orange for Verified users
        badgeText = 'V'; // Verified email user
        hasTrinket = false;
        break;
      case 'free':
      default:
        backgroundColor = '#D50032'; // Red
        badgeText = 'F'; // Free user
        hasTrinket = false;
        break;
    }
    
    return { backgroundColor, badgeText, hasTrinket, trinketSymbol };
  };
  
  const { backgroundColor, badgeText, hasTrinket, trinketSymbol } = getBadgeStyles();
  
  return (
    <Tooltip title={tooltipText}>
      <Box 
        onClick={onClick}
        sx={{ 
          position: 'relative',
          width: '42px',
          height: '42px',
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: "'League Spartan', sans-serif",
          fontSize: '1.4rem',
          fontWeight: 900,
          boxShadow: `0 3px 10px rgba(0,0,0,0.3), 0 0 15px rgba(${backgroundColor.replace('#', '').match(/../g).map(h => parseInt(h, 16)).join(',')}, 0.5)`,
          border: '3px solid #FFFFFF',
          outline: `1px solid ${backgroundColor}`,
          zIndex: 2,
          cursor: onClick ? 'pointer' : 'default',
          transition: 'all 0.3s ease-in-out',
          '&:hover': onClick ? {
            transform: 'scale(1.1)',
            boxShadow: `0 5px 15px rgba(0,0,0,0.4), 0 0 20px rgba(${backgroundColor.replace('#', '').match(/../g).map(h => parseInt(h, 16)).join(',')}, 0.7)`
          } : {}
        }}
      >
        {/* User initial with optional trinket */}
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {badgeText}
          {hasTrinket && (
            <span style={{ 
              position: 'absolute',
              top: userType === 'pro' ? '-18px' : '-16px',
              right: '-14px',
              fontSize: userType === 'pro' ? '22px' : '18px',
              lineHeight: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#FFC700', // Gold color for both crown and star
              textShadow: `
                0 0 4px rgba(255, 199, 0, 0.8),
                -1px -1px 0 var(--bg-primary),  
                1px -1px 0 var(--bg-primary),
                -1px 1px 0 var(--bg-primary),
                1px 1px 0 var(--bg-primary)
              `,
              transform: userType === 'pro' ? 'rotate(10deg)' : 'rotate(10deg)',
              fontWeight: 'bold',
              animation: `${userType === 'pro' ? 'crownPulse' : 'starPulse'} 2s infinite alternate`,
              zIndex: 3
            }}>
              {trinketSymbol}
            </span>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default UserBadge;
