import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import config, { saveConfig, getHCS2Url } from '../config';

/**
 * AdminSettings Component
 * 
 * Provides an interface for administrators to configure application settings.
 */
const AdminSettings = () => {
  const [open, setOpen] = useState(false);
  const [activeVersion, setActiveVersion] = useState(config.hcs2.activeVersion);
  const [defaultUrl, setDefaultUrl] = useState(config.hcs2.defaultUrl);
  const [goldUrl, setGoldUrl] = useState(config.hcs2.goldUrl);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Handle dialog open/close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle form submission
  const handleSave = () => {
    const newConfig = {
      hcs2: {
        activeVersion,
        defaultUrl,
        goldUrl
      }
    };

    const success = saveConfig(newConfig);
    
    if (success) {
      setSnackbarMessage('Settings saved successfully. Refresh the page to apply changes.');
      setSnackbarSeverity('success');
    } else {
      setSnackbarMessage('Failed to save settings.');
      setSnackbarSeverity('error');
    }
    
    setSnackbarOpen(true);
    handleClose();
  };

  // Handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {/* Admin Settings Button */}
      <IconButton 
        onClick={handleOpen}
        sx={{ 
          position: 'fixed', 
          bottom: 16, 
          right: 16,
          bgcolor: 'primary.main',
          color: 'white',
          '&:hover': {
            bgcolor: 'primary.dark',
          }
        }}
      >
        <SettingsIcon />
      </IconButton>

      {/* Admin Settings Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Admin Settings
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              HCS2 Integration Settings
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="active-version-label">Active Version</InputLabel>
              <Select
                labelId="active-version-label"
                value={activeVersion}
                label="Active Version"
                onChange={(e) => setActiveVersion(e.target.value)}
              >
                <MenuItem value="default">Default</MenuItem>
                <MenuItem value="gold">Gold (v1.3.3-gold)</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              fullWidth
              label="Default URL"
              value={defaultUrl}
              onChange={(e) => setDefaultUrl(e.target.value)}
              margin="normal"
              helperText="URL for the default version of HCS2"
            />
            
            <TextField
              fullWidth
              label="Gold URL"
              value={goldUrl}
              onChange={(e) => setGoldUrl(e.target.value)}
              margin="normal"
              helperText="URL for the gold version of HCS2 (v1.3.3-gold)"
            />
            
            <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>
                Current Active URL:
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {getHCS2Url()}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save Settings
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminSettings;
