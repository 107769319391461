import JimpModule from 'jimp';
import { Buffer } from 'buffer';

// Handle different import formats
const Jimp = JimpModule.default || JimpModule;

// Check if we're in a browser environment
const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';

// Luminance calculation methods
export const LUMINANCE_METHODS = {
  NATURAL: {
    calculate: (r, g, b) => {
      return (r * 0.299 + g * 0.587 + b * 0.114) / 255;
    }
  }
};

// Cache for processed images
const processedImageCache = new Map();

// Convert hex color to RGB with caching
const rgbCache = new Map();
export const hexToRgb = (hex) => {
  if (rgbCache.has(hex)) {
    return rgbCache.get(hex);
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgb = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
  rgbCache.set(hex, rgb);
  return rgb;
};

/**
 * Function to directly download a WebP image with proper compression
 * This specialized function bypasses the standard download process to ensure
 * WebP files are properly compressed when downloaded
 * 
 * @param {string} imageUrl - URL of the image to download
 * @param {string} color - Hex color code to apply to the image
 * @param {string} filename - Filename for the downloaded image
 * @param {number} quality - WebP compression quality (0.0-1.0), default 0.1 for maximum compression
 * @returns {Promise<Object>} - Object containing download stats including size and dimensions
 */
export const downloadWebPImage = async (imageUrl, color, filename, quality = 0.1) => {
  // Define startTime outside try block so it's accessible in catch block
  const startTime = Date.now();
  try {
    console.log(`Starting WebP download process with quality: ${quality}`);
    
    // Fetch the image
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status}`);
    }
    
    const blob = await response.blob();
    const originalSize = blob.size;
    console.log(`Original image size: ${Math.round(originalSize / 1024)} KB`);
    
    const img = new Image();
    const imgBlob = URL.createObjectURL(blob);
    
    // Load image
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = imgBlob;
    });
    
    // Create canvas with the same dimensions as the image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    
    // Draw original image
    ctx.drawImage(img, 0, 0);
    
    // Get image data for pixel manipulation
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
    // Get the RGB values from the color
    const rgbData = hexToRgb(color);
    if (!rgbData) {
      throw new Error(`Invalid color format: ${color}`);
    }
    
    const { r, g, b } = rgbData;
    
    // Process each pixel with color
    for (let idx = 0; idx < imageData.data.length; idx += 4) {
      const red = imageData.data[idx];
      const green = imageData.data[idx + 1];
      const blue = imageData.data[idx + 2];
      const alpha = imageData.data[idx + 3];
      
      if (alpha > 0) {
        // Calculate NATURAL luminance
        const luminance = LUMINANCE_METHODS.NATURAL.calculate(red, green, blue);
        
        // Apply same luminance to each channel
        imageData.data[idx] = Math.round(r * luminance);
        imageData.data[idx + 1] = Math.round(g * luminance);
        imageData.data[idx + 2] = Math.round(b * luminance);
      }
    }
    
    // Put processed image data back to canvas
    ctx.putImageData(imageData, 0, 0);
    
    // Try multiple compression qualities if needed to ensure small file size
    let webpBlob;
    let currentQuality = quality;
    
    // First attempt with specified quality
    webpBlob = await new Promise(resolve => {
      canvas.toBlob(blob => resolve(blob), 'image/webp', currentQuality);
    });
    
    // If the WebP is still too large (>500KB), try more aggressive compression
    if (webpBlob.size > 500 * 1024) {
      console.log(`WebP still large (${Math.round(webpBlob.size / 1024)} KB), trying more aggressive compression`);
      currentQuality = 0.05; // More aggressive compression
      webpBlob = await new Promise(resolve => {
        canvas.toBlob(blob => resolve(blob), 'image/webp', currentQuality);
      });
    }
    
    const compressionRatio = originalSize / webpBlob.size;
    const compressionPercent = ((1 - (webpBlob.size / originalSize)) * 100).toFixed(1);
    
    // Log detailed size information
    console.log(`WebP file size for download: ${Math.round(webpBlob.size / 1024)} KB`);
    console.log(`Compression ratio: ${compressionRatio.toFixed(2)}x (${compressionPercent}% smaller)`);
    console.log(`Final quality setting: ${currentQuality}`);
    
    // Create download link
    const blobUrl = URL.createObjectURL(webpBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename || `hextra-${new Date().getTime()}.webp`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Clean up
    URL.revokeObjectURL(imgBlob);
    URL.revokeObjectURL(blobUrl);
    
    const processingTime = Date.now() - startTime;
    
    // Return detailed information about the download for tracking
    return {
      success: true,
      size: webpBlob.size,
      originalSize,
      compressionRatio,
      compressionPercent: parseFloat(compressionPercent),
      width: img.width,
      height: img.height,
      quality: currentQuality,
      processingTime,
      format: 'webp'
    };
  } catch (error) {
    console.error('Error downloading WebP image:', error);
    // If error occurs before startTime is defined, we need to handle that case
    const errorTime = Date.now();
    return {
      success: false,
      error: error.message || 'Unknown error during WebP download',
      errorType: error.name || 'Error',
      format: 'webp',
      processingTime: typeof startTime !== 'undefined' ? errorTime - startTime : 0
    };
  }
};

// Process image with color
export const processImage = async (imageUrl, color, format = 'png') => {
  // Early return with mock data URL for non-browser environments (like during SSR/build)
  if (!isBrowser) {
    console.log('Image processing skipped - non-browser environment');
    return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==`;
  }

  const cacheKey = `${imageUrl}-${color}-${format}`;
  
  console.log(`Processing image with color: ${color}`);
  
  // Check cache first
  if (processedImageCache.has(cacheKey)) {
    console.log(`Using cached image for ${color}`);
    return processedImageCache.get(cacheKey);
  }
  
  try {
    console.log('Reading image from URL...');
    
    // For relative paths, we need to convert to absolute
    let absoluteUrl = imageUrl;
    if (imageUrl.startsWith('/')) {
      absoluteUrl = window.location.origin + imageUrl;
    }
    
    // Use fetch API to get the image data first
    const response = await fetch(absoluteUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
    }
    
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    
    // Use a simpler approach - create Image element with blob
    const imgBlob = URL.createObjectURL(blob);
    const img = new Image();
    
    // Load image from blob URL
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = imgBlob;
    });
    
    // Create canvas to process the image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    
    // Draw original image
    ctx.drawImage(img, 0, 0);
    
    // Get image data
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    
    // Get the RGB values from the color
    const rgbData = hexToRgb(color);
    if (!rgbData) {
      throw new Error(`Invalid color format: ${color}`);
    }
    
    const { r, g, b } = rgbData;
    console.log(`Color RGB: ${r}, ${g}, ${b}`);
    
    // Process each pixel with color
    for (let idx = 0; idx < imageData.data.length; idx += 4) {
      const red = imageData.data[idx];
      const green = imageData.data[idx + 1];
      const blue = imageData.data[idx + 2];
      const alpha = imageData.data[idx + 3];
      
      if (alpha > 0) {
        // Calculate NATURAL luminance
        const luminance = LUMINANCE_METHODS.NATURAL.calculate(red, green, blue);
        
        // Apply same luminance to each channel
        imageData.data[idx] = Math.round(r * luminance);
        imageData.data[idx + 1] = Math.round(g * luminance);
        imageData.data[idx + 2] = Math.round(b * luminance);
      }
    }
    
    // Put processed image data back to canvas
    ctx.putImageData(imageData, 0, 0);
    
    // Convert canvas to data URL using the selected format
    // Generate both formats to compare sizes
    let pngBlob, webpBlob, processedDataUrl;
    
    try {
      // PNG version first
      pngBlob = await new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else resolve(new Blob([], {type: 'image/png'})); // Fallback empty blob
        }, 'image/png');
      });
      
      // WebP version with very aggressive compression
      webpBlob = await new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else resolve(new Blob([], {type: 'image/webp'})); // Fallback empty blob
        }, 'image/webp', 0.1); // Very low quality to force compression
      });
      
      // Get sizes
      const pngSize = pngBlob.size;
      const webpSize = webpBlob.size;
      
      // Log detailed size information
      console.log('=== IMAGE FORMAT COMPARISON ===');
      console.log(`PNG size: ${Math.round(pngSize / 1024)} KB`);
      console.log(`WebP size: ${Math.round(webpSize / 1024)} KB`);
      
      if (pngSize > 0 && webpSize > 0) {
        const percentSmaller = Math.round((pngSize - webpSize) / pngSize * 100);
        console.log(`WebP is ${percentSmaller}% smaller than PNG`);
        
        if (webpSize >= pngSize) {
          console.warn('WARNING: WebP is not smaller than PNG!');
        }
      }
      
      // Choose the format based on user selection
      const selectedBlob = format === 'webp' ? webpBlob : pngBlob;
      
      // Convert to data URL
      processedDataUrl = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(selectedBlob);
      });
      
    } catch (error) {
      console.error('Error during format conversion:', error);
      // Fallback to basic PNG if there's an error
      processedDataUrl = canvas.toDataURL('image/png');
    }
    
    // Clean up blob URL
    URL.revokeObjectURL(imgBlob);
    
    // Cache the result
    processedImageCache.set(cacheKey, processedDataUrl);
    
    // Limit cache size to prevent memory issues
    if (processedImageCache.size > 50) {
      const firstKey = processedImageCache.keys().next().value;
      processedImageCache.delete(firstKey);
    }
    
    return processedDataUrl;
  } catch (error) {
    console.error('Error processing image:', error);
    throw error;
  }
};
