import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

/**
 * TokenSystem Component
 * Displays a gold coin icon with dropdown and dialog explaining the upcoming token system
 * 
 * @param {Object} props
 * @param {string} props.userType - The user's subscription tier (free, email, earlybird, pro)
 * @param {string} props.theme - Current theme (dark or light)
 */
const TokenSystem = ({ userType, theme }) => {
  const [anchorElToken, setAnchorElToken] = useState(null);
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
  
  // Check if user is an early bird for special messaging
  const isEarlyBird = userType === 'earlybird';
  
  // When used in a menu context, we want to show a simpler version
  return (
    <>
      {/* In menu context, just show text with click handler */}
      <Box 
        onClick={(e) => {
          e.stopPropagation(); // Prevent menu from closing
          setTokenDialogOpen(true);
        }}
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          cursor: 'pointer'
        }}
      >
        <ListItemText 
          primary="Catalog Value Example" 
          secondary="GILDAN 64000" 
          secondaryTypographyProps={{ 
            sx: { color: theme === 'dark' ? '#ffffff' : 'text.secondary', fontWeight: 500 } 
          }}
        />
      </Box>

      {/* No dropdown menu needed since we're now integrated in UserProfileMenu */}

      {/* Token System Dialog */}
      <Dialog
        open={tokenDialogOpen}
        onClose={() => setTokenDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          pb: 2,
          backgroundColor: '#ffffff'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MonetizationOnIcon sx={{ color: '#333333', mr: 1, fontSize: 28 }} />
            <Typography variant="h6" sx={{ fontWeight: 600, color: '#333333' }}>
              Catalog Value Example
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 3, backgroundColor: '#ffffff' }}>
          <Typography variant="body1" sx={{ 
            mb: 3, 
            color: '#333333',
            fontWeight: 500,
            fontSize: '1rem'
          }}>
            With the new token system, catalog downloads provide exceptional value compared to individual downloads:
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <Box sx={{ 
              p: 3, 
              bgcolor: '#f5f5f5', 
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
            }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#333333' }}>
                GILDAN 64000 Catalog (63 colors)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    textDecoration: 'line-through', 
                    color: '#777777',
                    mr: 2,
                    fontSize: '1.1rem'
                  }}
                >
                  63 tokens
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    fontWeight: 'bold',
                    color: '#00805E',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    component="img"
                    src="/images/HEXTRA-Token.svg"
                    alt="HEXTRA Token"
                    sx={{ 
                      width: '24px',
                      height: '24px',
                      mr: 0.75,
                      filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,0.3))',
                    }}
                  />
                  Only 10 tokens!
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          p: 2,
          backgroundColor: '#ffffff'
        }}>
          <Button 
            onClick={() => setTokenDialogOpen(false)}
            variant="contained" 
            sx={{ 
              bgcolor: '#224D8F', 
              '&:hover': { bgcolor: '#3A6EBF' },
              fontWeight: 'bold',
              px: 3
            }}
          >
            GOT IT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TokenSystem;
