/**
 * AboutBox Component
 * 
 * A specialized dialog component for HEXTRA-X's About Box that follows the design pattern:
 * - Light Theme: Dark banner/footer with black logo on a white page
 * - Dark Theme: Light banner/footer with white logo on a dark page
 * 
 * Features RGB color-coded sections and a clear visual hierarchy.
 * 
 * @version 2.3.1
 * @lastUpdated 2025-03-23
 */

import React from 'react';
import HextraDialog from './HextraDialog';
import { Box, Typography, Link, Button } from '@mui/material';
import { VERSION, BUILD_DATE, BUILD_ID } from '../version';

const AboutBox = ({ open, onClose, navigateToPlans }) => {
  // Get theme directly from document attribute for consistency with the rest of the app
  const isDarkTheme = document.documentElement.getAttribute('data-theme') === 'dark';

  return (
    <HextraDialog
      open={open}
      onClose={onClose}
      showLogo={true}
      useThemedHeader={true}
      useAppTheme={true}
      maxWidth="550px"
    >
      <Box sx={{ pt: 6, pb: 2 }}>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          👕 T-shirt blanks 👚 On-Demand!
        </Typography>
        
        <Typography variant="body1" align="center" paragraph>
          HEXTRA-X is a powerful color processing tool for rapid, professional-grade, product visualization with streamlined batch color-line generation.
        </Typography>
        
        {/* Features Section */}
        <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
          Features
        </Typography>
        
        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { xs: '1fr', sm: 'repeat(3, 1fr)' },
          gap: 2,
          mb: 2
        }}>
          {/* COLORIZE Column */}
          <Box>
            <Typography variant="subtitle1" component="h4" sx={{ color: '#D50032', fontWeight: 'bold' }}>
              COLORIZE
            </Typography>
            <ul style={{ paddingLeft: '1.5rem', margin: '0.5rem 0' }}>
              <li>RGB color space simplified</li>
              <li>Interactive color wheel</li>
              <li>HEX support Input/Output</li>
            </ul>
          </Box>
          
          {/* VISUALIZE Column */}
          <Box>
            <Typography variant="subtitle1" component="h4" sx={{ color: '#00A651', fontWeight: 'bold' }}>
              VISUALIZE
            </Typography>
            <ul style={{ paddingLeft: '1.5rem', margin: '0.5rem 0' }}>
              <li>Live FAST rendering</li>
              <li>Advanced luminance controls</li>
              <li>Dark/Light mode</li>
            </ul>
          </Box>
          
          {/* MESMERIZE Column */}
          <Box>
            <Typography variant="subtitle1" component="h4" sx={{ color: '#224D8F', fontWeight: 'bold' }}>
              MESMERIZE
            </Typography>
            <ul style={{ paddingLeft: '1.5rem', margin: '0.5rem 0' }}>
              <li>Rapid BULK Image Gen.</li>
              <li>Multi-catalog support</li>
              <li>Unique HEXTRA Color System</li>
            </ul>
          </Box>
        </Box>
        
        {/* Contact Section */}
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="h6" component="h3">
            Contact
          </Typography>
          <Link 
            href="mailto:james@wolfejames.com" 
            sx={{ 
              color: '#D50032', 
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            james@wolfejames.com
          </Link>
        </Box>
        
        {/* Version Information */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          mt: 1.5,
          flexWrap: 'wrap',
          fontSize: '0.9rem'
        }}>
          <Typography variant="body2" component="span">
            Version {VERSION}
          </Typography>
          <Typography 
            variant="caption" 
            component="span" 
            sx={{ 
              color: isDarkTheme ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.6)', 
              ml: 1.5 
            }}
          >
            Build ID: {BUILD_ID} | Built on: {BUILD_DATE}
          </Typography>
        </Box>
        
        {/* Input/Output Footer */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mt: 2,
          p: 1.5,
          backgroundColor: isDarkTheme ? 'var(--banner-bg)' : 'var(--banner-bg)',
          color: isDarkTheme ? 'var(--banner-text)' : 'var(--banner-text)',
          borderRadius: '4px',
          fontSize: '0.85rem'
        }}>
          <Typography variant="body2" component="span">
            <strong>Input:</strong> PNG/WebP with transparency support
          </Typography>
          <Typography variant="body2" component="span">
            <strong>Output:</strong> PNG/WebP instants, Bulk ZIP Archives for Pro Use
          </Typography>
        </Box>
      </Box>
      
      {/* Custom Footer Buttons */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        mt: 2, 
        mb: 1,
        gap: 1
      }}>
        <Button 
          variant="outlined" 
          onClick={onClose}
          sx={{
            borderColor: 'var(--border-color)',
            backgroundColor: 'var(--button-bg)',
            color: 'var(--button-text)',
            '&:hover': {
              borderColor: 'var(--glow-color)',
              boxShadow: '0 0 0 3px var(--glow-subtle)',
              transform: 'scale(1.05)'
            }
          }}
        >
          Close
        </Button>
        
        <Button 
          variant="contained" 
          onClick={navigateToPlans}
          sx={{
            backgroundColor: '#0057B8', // Blue for expert-level features (exception to the theme rules)
            color: 'white', // White text on blue background for contrast
            '&:hover': {
              backgroundColor: '#004494',
              borderColor: 'var(--glow-color)',
              boxShadow: '0 0 0 3px var(--glow-subtle)',
              transform: 'scale(1.05)'
            },
            ml: 1
          }}
        >
          Plans
        </Button>
      </Box>
    </HextraDialog>
  );
};

export default AboutBox;
