/**
 * HEXTRA-X Cross-Browser Compatibility Fix
 * 
 * Addresses the "Attempting to change the setter of an unconfigurable property" error
 * in Safari and Chrome mobile browsers when React manipulates certain properties.
 * 
 * This follows HEXTRA-X's central spine architecture principles by ensuring
 * consistent behavior across all platforms.
 */

export const applySafariPropertyFix = () => {
  // Check if browser is Safari or mobile 
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  // Only apply if we're on Safari or any mobile browser
  if (!isSafari && !isMobile) return;
  
  console.log('Applying cross-browser property descriptor fix');
  
  // Apply the fix for Safari's strict property descriptor handling
  const originalDefineProperty = Object.defineProperty;
  
  Object.defineProperty = function(obj, prop, descriptor) {
    // Skip problematic property configurations that cause Safari errors
    if (
      obj && 
      (prop === 'current' || prop === 'checked' || prop === 'value' || prop === 'disabled') && 
      obj.nodeName
    ) {
      // For DOM elements with specific props, use a safer approach
      try {
        delete obj[prop];
      } catch (e) {
        // In case the property cannot be deleted
        console.warn('Safari fix: Could not delete property', prop);
      }
      
      // Set the value directly instead of using a setter/getter
      obj[prop] = descriptor.initialValue !== undefined ? descriptor.initialValue : null;
      
      return obj;
    }
    
    // For all other cases, use the original defineProperty
    return originalDefineProperty.call(this, obj, prop, descriptor);
  };
};
