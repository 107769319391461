/**
 * HextraDialog Component (v1.0.0)
 * 
 * A reusable dialog component for HEXTRA-X that supports:
 * - Light and dark themes
 * - Proper logo selection based on background color
 * - Customizable actions, colors, and content
 * - Consistent styling across the application
 * 
 * @version 1.0.0
 * @lastUpdated 2025-03-15
 */

import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';

const HextraDialog = ({ 
  open, 
  onClose,
  title,
  children,
  showLogo = true,
  logoVariant = 'auto', // 'light', 'dark', or 'auto'
  useAppTheme = true, // Whether to use the app's theme context
  backgroundColor,
  textColor,
  primaryAction,
  primaryActionText = 'OK',
  primaryColor = '#D50032', // HEXTRA red
  primaryHoverColor = '#B5002B',
  secondaryAction,
  secondaryActionText = 'Cancel',
  secondaryColor = '#00A651', // HEXTRA green
  secondaryHoverColor = '#008C44',
  maxWidth = '500px',
  disablePrimaryAction = false,
  disableSecondaryAction = false,
  // Special styling options
  useThemedHeader = false, // Whether to use themed header and footer for About Box
  headerHeight = '75px' // Height of the header
}) => {
  // Get theme directly from document attribute for consistency with the rest of the app
  const isDarkTheme = document.documentElement.getAttribute('data-theme') === 'dark';
  
  // Set theme-based properties
  const effectiveLogoVariant = logoVariant || (isDarkTheme ? 'dark' : 'light');
  
  // Set background and text colors based on theme
  const bgColor = backgroundColor || (isDarkTheme ? 'rgb(20, 20, 20)' : '#ffffff');
    
  const txtColor = textColor || (isDarkTheme ? '#ffffff' : '#333333');
  
  // Logo selection based on background color, following HEXTRA Theme Golden Rules:
  // - For dark backgrounds: use white logo (Wht) for contrast
  // - For light backgrounds: use black logo (Blk) for contrast
  // Following the naming convention:
  // - HEXTRA-X-logo-Wht.svg = White logo for dark backgrounds
  // - HEXTRA-X-logo-Blk.svg = Black logo for light backgrounds
  
  // For themed header (AboutBox):
  // - In dark theme: Banner is light, so use black logo
  // - In light theme: Banner is dark, so use white logo
  const logoSrc = useThemedHeader
    ? (isDarkTheme 
        ? "/images/HEXTRA-X-logo-Wht.svg" // White logo for light banner in dark theme (switched)
        : "/images/HEXTRA-X-logo-Blk.svg") // Black logo for dark banner in light theme (switched)
    : (isDarkTheme 
        ? "/images/HEXTRA-X-logo-Blk.svg" // Black logo for dark backgrounds (switched)
        : "/images/HEXTRA-X-logo-Wht.svg"); // White logo for light backgrounds (switched)
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="hextra-dialog-title"
      PaperProps={{
        sx: {
          width: '95vw',
          maxWidth: maxWidth,
          borderRadius: '8px',
          bgcolor: bgColor,
          color: txtColor,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          border: isDarkTheme ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
          overflowX: 'hidden'
        }
      }}
    >
      {/* Dialog Header with Logo */}
      <DialogTitle sx={{ 
        fontSize: '1.5rem', 
        fontWeight: 600,
        pb: 1,
        textAlign: 'center',
        color: useThemedHeader ? (isDarkTheme ? '#333333' : '#ffffff') : txtColor,
        background: useThemedHeader 
          ? (isDarkTheme 
              ? 'linear-gradient(45deg, #f5f5f5 30%, #ffffff 90%)' 
              : 'linear-gradient(45deg, #1a1a1a 30%, #2d2d2d 90%)')
          : 'transparent',
        borderBottom: useThemedHeader 
          ? (isDarkTheme 
              ? '1px solid rgba(0,0,0,0.1)' 
              : '1px solid rgba(255,255,255,0.1)')
          : 'none',
        height: useThemedHeader ? headerHeight : 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1 }}>
          {showLogo && (
            <img 
              src={logoSrc}
              alt="HEXTRA Logo" 
              style={{ 
                width: '80px', 
                height: 'auto',
                marginBottom: '12px'
              }} 
            />
          )}
          {title}
        </Box>
      </DialogTitle>
      
      {/* Dialog Content */}
      <DialogContent sx={{ px: 3 }}>
        {children}
      </DialogContent>
      
      {/* Dialog Actions */}
      {(primaryAction || secondaryAction) && (
        <DialogActions sx={{ 
          px: 3, 
          pb: 3, 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2,
          alignItems: 'center',
          background: useThemedHeader 
            ? (isDarkTheme 
                ? 'linear-gradient(45deg, #f5f5f5 30%, #ffffff 90%)' 
                : 'linear-gradient(45deg, #1a1a1a 30%, #2d2d2d 90%)')
            : 'transparent',
          padding: useThemedHeader ? '16px 24px' : '8px 24px',
          borderTop: useThemedHeader 
            ? (isDarkTheme 
                ? '1px solid rgba(0,0,0,0.1)' 
                : '1px solid rgba(255,255,255,0.1)')
            : 'none',
          color: useThemedHeader ? (isDarkTheme ? '#333333' : '#ffffff') : txtColor,
          mt: useThemedHeader ? 2 : 0
        }}>
          {primaryAction && (
            <Button 
              variant="contained" 
              onClick={primaryAction}
              disabled={disablePrimaryAction}
              sx={{
                fontWeight: 'bold',
                letterSpacing: '0.5px',
                backgroundColor: primaryColor,
                color: 'white',
                '&:hover': {
                  backgroundColor: primaryHoverColor
                },
                '&.Mui-disabled': {
                  backgroundColor: primaryColor,
                  opacity: 0.7,
                  color: 'white'
                },
                width: '250px'
              }}
            >
              {primaryActionText}
            </Button>
          )}
          
          {secondaryAction && (
            <Button 
              variant="contained" 
              onClick={secondaryAction}
              disabled={disableSecondaryAction}
              sx={{ 
                fontWeight: 'bold',
                letterSpacing: '0.5px',
                backgroundColor: secondaryColor,
                color: 'white',
                '&:hover': {
                  backgroundColor: secondaryHoverColor
                },
                width: '250px'
              }}
            >
              {secondaryActionText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default HextraDialog;
