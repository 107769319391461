import React, { useState, useEffect, createContext, useContext } from 'react';
import { Box, Snackbar } from '@mui/material';
import { safeNavigate } from '../../utils/navigation';

// Create a context to share spine visibility state across components
export const SpineContext = createContext({
  globalSpineVisible: false,
  containerSpineVisible: false,
  setGlobalSpineVisible: () => {},
  setContainerSpineVisible: () => {},
  cycleSpineVisibility: () => {}
});

/**
 * CentralSpine - Vertical alignment guide for the HEXTRA-X UI
 * 
 * Implements the central spine line that serves as a visual guide for 
 * container alignment. Features a 5-state rotation with Shift+S:
 * 1. No spines visible
 * 2. Global spine (red) visible
 * 3. Both global and container (red and blue) spines visible
 * 4. Container spine (blue) only visible
 * 5. Back to no spines visible
 * 
 * Also implements Shift+D shortcut for container demos.
 * 
 * @param {Object} props
 * @param {boolean} props.initialGlobalVisible - Whether the global spine should be initially visible
 * @param {boolean} props.initialContainerVisible - Whether the container spine should be initially visible
 * @param {Object} props.sx - Optional additional styling
 */
const CentralSpine = ({ 
  initialGlobalVisible = false, 
  initialContainerVisible = false, 
  sx = {} 
}) => {
  // State for global (red) and container (blue) spine visibility
  const [globalSpineVisible, setGlobalSpineVisible] = useState(initialGlobalVisible);
  const [containerSpineVisible, setContainerSpineVisible] = useState(initialContainerVisible);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Function to cycle through the 5 spine visibility states
  const cycleSpineVisibility = () => {
    // State 1: No spines → State 2: Global (red) only
    if (!globalSpineVisible && !containerSpineVisible) {
      setGlobalSpineVisible(true);
      setContainerSpineVisible(false);
      setSnackbarMessage('Global Spine Shown');
    }
    // State 2: Global only → State 3: Both spines
    else if (globalSpineVisible && !containerSpineVisible) {
      setGlobalSpineVisible(true);
      setContainerSpineVisible(true);
      setSnackbarMessage('Both Spines Shown');
    }
    // State 3: Both spines → State 4: Container (blue) only
    else if (globalSpineVisible && containerSpineVisible) {
      setGlobalSpineVisible(false);
      setContainerSpineVisible(true);
      setSnackbarMessage('Container Spine Shown');
    }
    // State 4: Container only → State 5: No spines
    else if (!globalSpineVisible && containerSpineVisible) {
      setGlobalSpineVisible(false);
      setContainerSpineVisible(false);
      setSnackbarMessage('Spines Hidden');
    }
    
    setSnackbarOpen(true);
  };
  
  // Provide the spine visibility state through context
  const contextValue = {
    globalSpineVisible,
    containerSpineVisible,
    setGlobalSpineVisible,
    setContainerSpineVisible,
    cycleSpineVisibility
  };
  
  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Skip if in input or textarea
      if (
        document.activeElement.tagName === 'INPUT' || 
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        return;
      }
      
      // Shift+S - cycle through spine visibility states
      if ((event.key === 's' || event.key === 'S') && 
          event.shiftKey && 
          !event.ctrlKey && 
          !event.metaKey && 
          !event.altKey) {
        cycleSpineVisibility();
        event.preventDefault();
      }
      
      // Shift+D - navigate to container demos
      if ((event.key === 'd' || event.key === 'D') && 
          event.shiftKey && 
          !event.ctrlKey && 
          !event.metaKey && 
          !event.altKey) {
        // Navigate to the LEGO container example page
        safeNavigate('/lego-example');
        
        // Show feedback snackbar
        setSnackbarMessage('Opening Container Demos');
        setSnackbarOpen(true);
        
        event.preventDefault();
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [globalSpineVisible, containerSpineVisible]);
  
  return (
    <SpineContext.Provider value={contextValue}>
      {/* Global Spine Line (Red) */}
      {globalSpineVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            ...sx
          }}
          className="global-spine-container"
          aria-hidden="true"
        >
          <Box
            sx={{
              width: '1px', // Very fine line
              height: '100%',
              backgroundColor: 'error.light',
              opacity: 0.4,
            }}
            className="global-spine-line"
          />
        </Box>
      )}
      
      {/* Container Spine Line (Blue) - Now visible at global level */}
      {containerSpineVisible && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 9998, // Just below the red line
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            ...sx
          }}
          className="container-spine-container"
          aria-hidden="true"
        >
          <Box
            sx={{
              width: '1px', // Now very fine like the red line
              height: '100%',
              backgroundColor: 'primary.light',
              opacity: 0.4,
            }}
            className="container-spine-line"
          />
        </Box>
      )}
      
      {/* Feedback Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </SpineContext.Provider>
  );
};

export default CentralSpine;
