/**
 * EmailCollectionDialog Component (v2.2.5)
 * 
 * Collects email from users before allowing download.
 * - Shows for non-authenticated users when downloading single images
 * - Stores email in localStorage for persistence
 * - Provides option to proceed to full authentication
 * 
 * @version 2.2.5
 * @lastUpdated 2025-03-10
 */

import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Alert,
  AlertTitle
} from '@mui/material';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import GlowButton from './GlowButton';
import GlowTextButton from './GlowTextButton';
import DirectEmailCapture from './DirectEmailCapture';
import { generateShareableLink } from '../utils/share-link';

const EmailCollectionDialog = ({ 
  open, 
  onClose, 
  onSubmit, 
  title = "Get Access to Downloads",
  message = "Enter your email to verify and access downloads.", 
  submitButtonText = "Verify Email",
  isNewsletter = false
}) => {
  console.log('[DEBUG] Dialog - Rendering with props:', { open });
  const [email, setEmail] = useState(''); // Don't prefill with demo email
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { login } = useKindeAuth();
  
  // Check for MailChimp success parameter in URL
  React.useEffect(() => {
    // Check if we're returning from a successful MailChimp submission
    if (typeof window !== 'undefined' && 
        (window.location.search.includes('mailchimp_success=true') || 
         window.location.search.includes('result=success'))) {
      console.log('[DEBUG] Email Capture - Detected successful MailChimp submission');
      
      // Try to get the email from localStorage
      let storedEmail = '';
      try {
        // For newsletter signup, we don't need to use the stored email
        if (!isNewsletter) {
          const storedData = localStorage.getItem('hextra_email_backup');
          if (storedData) {
            const parsedData = JSON.parse(storedData);
            storedEmail = parsedData.email || '';
            console.log('[DEBUG] Email Capture - Retrieved email from localStorage:', storedEmail);
          }
        }
      } catch (error) {
        console.error('[DEBUG] Email Capture - Failed to retrieve email from localStorage:', error);
      }
      
      // Clean up the URL
      const cleanUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, document.title, cleanUrl);
      
      // Close the dialog with success
      if (onSubmit && (email || storedEmail)) {
        onSubmit(email || storedEmail);
      }
      onClose();
    }
  }, [onClose, onSubmit, email]);

  // Enhanced email validation
  const validateEmail = (email) => {
    // Basic format validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!re.test(email)) return false;
    
    // Don't allow using our own domains (prevent demo email abuse)
    const restrictedDomains = ['hextra.io', 'hextra.app', 'hextra-x.com'];
    const emailDomain = email.split('@')[1].toLowerCase();
    if (restrictedDomains.includes(emailDomain)) {
      console.log(`[SECURITY] Blocked attempt to use restricted domain: ${emailDomain}`);
      return false;
    }
    
    // Check for common disposable email patterns
    const suspiciousDomains = ['mailinator.com', 'tempmail.com', 'fakeinbox.com', 'guerrillamail.com'];
    if (suspiciousDomains.some(domain => emailDomain.includes(domain))) {
      console.log(`[SECURITY] Blocked suspicious email domain: ${emailDomain}`);
      return false;
    }
    
    return true;
  };

  // Subscribe user to MailChimp using API endpoint for newsletter and form submission for verification
  const subscribeToMailChimp = async (email) => {
    // Add newsletter flag if this is a newsletter subscription
    const sourceValue = isNewsletter 
      ? 'HEXTRA-Newsletter-Dialog-v2.2.5' 
      : 'HEXTRA-EmailDialog-v2.2.5';
    console.log(`[DEBUG] Email Capture - Subscribing email: ${email}`);
    
    try {
      // Store the email locally first as a backup
      try {
        localStorage.setItem('hextra_email_backup', JSON.stringify({
          email,
          timestamp: new Date().toISOString(),
          pending: true
        }));
        console.log('[DEBUG] Email Capture - Stored email locally as backup');
      } catch (storageError) {
        console.error('[DEBUG] Email Capture - Failed to store email locally:', storageError);
      }
      
      // For newsletter signups, use the API endpoint instead of form redirect
      if (isNewsletter) {
        console.log('[DEBUG] Newsletter - Using API endpoint');
        try {
          // Call our newsletter API endpoint
          const response = await fetch('/api/newsletter-signup', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
          });
          
          const result = await response.json();
          
          if (result.success) {
            console.log('[DEBUG] Newsletter - API signup successful:', result);
            // Set success state and return true to indicate success
            return true;
          } else {
            console.error('[DEBUG] Newsletter - API signup failed:', result);
            // If API call fails, still return true to avoid disrupting user experience
            // The main handleSubmit function will show appropriate messages
            return true;
          }
        } catch (apiError) {
          console.error('[DEBUG] Newsletter - API error:', apiError);
          // Even if API fails, return true to allow flow to continue
          return true;
        }
      }
      
      // For verification flow, continue with original MailChimp form redirect
      // Set success message for user feedback
      setSuccessMessage('Verification email sent!\nPlease check your inbox to access downloads.');
      
      // MailChimp direct form submission configuration
      const MAILCHIMP_URL = 'https://us8.list-manage.com/subscribe/post';
      const MAILCHIMP_U = '90eafdc7bf8faf272c6e45caf'; // MailChimp user ID
      const MAILCHIMP_ID = '5b2a2cb0b7'; // MailChimp audience ID
      
      console.log('[DEBUG] Email Capture - Using direct form submission to MailChimp');
      console.log(`[DEBUG] Email Capture - MailChimp URL: ${MAILCHIMP_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`);
      
      // Create a form element
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${MAILCHIMP_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;
      // Add success redirect URL
      const successRedirect = `${window.location.href.split('?')[0]}?mailchimp_success=true`;
      form.action = `${MAILCHIMP_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}&success=${encodeURIComponent(successRedirect)}`;
      form.target = '_self'; // Stay in the same tab for redirect handling
      form.style.display = 'none'; // Hide the form
      
      // Add email field
      const emailField = document.createElement('input');
      emailField.type = 'email';
      emailField.name = 'EMAIL';
      emailField.value = email;
      form.appendChild(emailField);
      
      // Add source tracking field
      const sourceField = document.createElement('input');
      sourceField.type = 'hidden';
      sourceField.name = 'SOURCE';
      sourceField.value = sourceValue;
      form.appendChild(sourceField);
      
      // Add anti-spam honeypot field (required by MailChimp)
      const honeypotField = document.createElement('input');
      honeypotField.type = 'text';
      honeypotField.name = `b_${MAILCHIMP_U}_${MAILCHIMP_ID}`;
      honeypotField.value = '';
      honeypotField.style.display = 'none';
      form.appendChild(honeypotField);
      
      // Add tags field
      const tagsField = document.createElement('input');
      tagsField.type = 'hidden';
      tagsField.name = 'tags';
      tagsField.value = 'HEXTRA,WebApp';
      form.appendChild(tagsField);
      
      // Add submit button (required by MailChimp)
      const submitButton = document.createElement('input');
      submitButton.type = 'submit';
      submitButton.name = 'subscribe';
      submitButton.value = 'Join';
      submitButton.style.display = 'none';
      form.appendChild(submitButton);
      
      // Add the form to the document
      document.body.appendChild(form);
      
      // Submit the form
      console.log('[DEBUG] Email Capture - Submitting form to MailChimp');
      form.submit();
      
      // Remove the form after submission
      setTimeout(() => {
        document.body.removeChild(form);
        console.log('[DEBUG] Email Capture - Form submitted and removed');
      }, 1000);
      
      // Mark as successful in local storage
      try {
        console.log('[DEBUG] Email Capture - Direct form submission initiated');
        
        // Update local storage to mark as pending verification
        localStorage.setItem('hextra_email_backup', JSON.stringify({
          email,
          timestamp: new Date().toISOString(),
          pending: true,
          verificationSent: true
        }));
        
        console.log('[DEBUG] Email Capture - Local storage updated with success status');
      } catch (storageError) {
        console.error('[DEBUG] Email Capture - Failed to update local storage:', storageError);
      }
      
      // Add success URL redirect parameter
      console.log('[DEBUG] Email Capture - Form submitted successfully');
      
      // Add a success tracking pixel as a backup
      try {
        const trackingPixel = new Image();
        trackingPixel.src = `https://www.hextra.io/pixel.gif?email=${encodeURIComponent(email)}&t=${Date.now()}`;
        console.log('[DEBUG] Email Capture - Tracking pixel sent');
      } catch (pixelError) {
        console.error('[DEBUG] Email Capture - Pixel tracking error:', pixelError);
      }
      
      // Even if all our approaches fail, we've stored the email locally
      // So we'll return true to allow the user to continue
      return true;
    } catch (error) {
      console.error('[DEBUG] Email Capture - Exception caught:', error);
      // Don't block the user experience if subscription fails
      return true;
    }
  };

  // Handle dialog submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    console.log('[DEBUG] Dialog - Submit button clicked');
    // Validate email
    if (!email) {
      console.log('[DEBUG] Dialog - Email is empty');
      setError('Email is required');
      return;
    }
    
    if (!validateEmail(email)) {
      console.log('[DEBUG] Dialog - Email validation failed:', email);
      setError('Please enter a valid email address. Company domains preferred.');
      return;
    }
    
    // Additional check for suspicious patterns in local part (before @)
    const localPart = email.split('@')[0].toLowerCase();
    if (localPart === 'test' || localPart === 'admin' || localPart === 'user' || localPart === 'info' || localPart.includes('levelup')) {
      console.log('[SECURITY] Potentially suspicious email pattern detected:', email);
      // We still allow it, but log for monitoring
    }
    console.log('[DEBUG] Dialog - Email validation passed:', email);

    // Set submitting state
    setIsSubmitting(true);
    // Clear any previous error
    setError('');

    // Save email to localStorage with pending verification status
    try {
      localStorage.setItem('hextra_email_user', JSON.stringify({
        email,
        timestamp: Date.now(),
        pending: true,
        verificationSent: true
      }));
      console.log('[DEBUG] Dialog - Saved email with pending status to localStorage');
    } catch (error) {
      console.error('[DEBUG] Dialog - Error saving to localStorage:', error);
    }

    // Subscribe to MailChimp and send confirmation email
    console.log('[DEBUG] Dialog - Calling MailChimp subscribe');
    const subscribeResult = await subscribeToMailChimp(email);
    console.log('[DEBUG] Dialog - MailChimp subscribe result:', subscribeResult);
    
    // Set success message for user feedback - different for newsletter vs. verification
    setSuccessMessage(isNewsletter 
      ? 'Thanks for subscribing!\nYou\'ve been added to our newsletter.'
      : 'Verification email sent!\nPlease check your inbox to access downloads.');
    
    if (!subscribeResult) {
      console.log('[DEBUG] Dialog - MailChimp subscription failed, but continuing');
      // Still show success message even if MailChimp fails
      setSuccessMessage(isNewsletter 
        ? 'Thanks for subscribing!\nYou\'ve been added to our newsletter.'
        : 'Verification email sent!\nPlease check your inbox to access downloads.');
    }

    // Call the onSubmit callback with the email
    console.log('[DEBUG] Dialog - Calling onSubmit callback');
    onSubmit(email);
    
    // Don't close dialog immediately to show success message
    console.log('[DEBUG] Dialog - Showing success message');
    
    // Close dialog after showing success message for 5 seconds
    setTimeout(() => {
      console.log('[DEBUG] Dialog - Closing dialog after success message');
      setEmail('');
      setError('');
      setSuccessMessage('');
      setIsSubmitting(false);
      onClose();
    }, 5000);
  };

  // Handle full authentication
  const handleAuthenticate = () => {
    console.log('[DEBUG] Dialog - Authentication requested');
    login();
    console.log('[DEBUG] Dialog - Closing dialog after auth request');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      aria-labelledby="email-collection-dialog-title"
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '95vw',
          borderRadius: '8px',
          bgcolor: '#ffffff',
          color: '#333333',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          overflowX: 'hidden'
        }
      }}
    >
      <DialogTitle sx={{ 
        fontSize: '1.5rem', 
        fontWeight: 600,
        pb: 1,
        textAlign: 'center',
        color: '#333333'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1 }}>
          <img 
            src="/images/HEXTRA-X-logo-Wht.svg" 
            alt="HEXTRA Logo" 
            style={{ 
              width: '80px', 
              height: 'auto',
              marginBottom: '12px'
            }} 
          />
          {isNewsletter ? 'Join Our Newsletter' : 'Join the HEXTRA Community'}
        </Box>
      </DialogTitle>
      
      <DialogContent sx={{ px: 3 }}>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center', color: '#333333' }}>
          {isNewsletter 
            ? 'Stay up to date with the latest color tools, tips and special offers.'
            : 'Enter your email to receive a link to your image and join the HEXTRA community.'}
        </Typography>
        
        {successMessage ? (
          <Box
            sx={{
              color: '#00E676',
              fontWeight: 'bold',
              textAlign: 'center',
              my: 4,
              p: 3,
              bgcolor: 'rgba(0, 230, 118, 0.15)',
              borderRadius: 1,
              border: '1px solid rgba(0, 230, 118, 0.3)'
            }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 'bold',
                fontSize: '1.8rem',
                mb: 1
              }}
            >
              Thank you!
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '1.2rem' 
              }}
            >
              {isNewsletter 
                ? 'You\'ve been added to our newsletter!'
                : 'Please check your inbox for the verification link.'}
            </Typography>
          </Box>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Your Email Address"
              type="email"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (error) setError('');
              }}
              error={!!error}
              helperText={error}
              sx={{ mb: 2 }}
              disabled={isSubmitting}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2 }}>
              <Button 
                variant="contained" 
                onClick={handleSubmit}
                disabled={!email || isSubmitting}
                sx={{
                  fontWeight: 'bold',
                  letterSpacing: '0.5px',
                  backgroundColor: '#D50032',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#B5002B'
                  },
                  '&.Mui-disabled': {
                    backgroundColor: '#D50032',
                    opacity: 0.7,
                    color: 'white'
                  },
                  width: '250px'
                }}
              >
                {isSubmitting ? 'PROCESSING...' : (isNewsletter ? 'SUBSCRIBE NOW' : 'CLAIM INSTANT DOWNLOAD')}
              </Button>
            </Box>
          </>
        )}
        
        {!successMessage && (
          <Box sx={{ my: 2 }}>
            <Divider>
              <Typography variant="caption" sx={{ color: '#666666' }}>
                OR
              </Typography>
            </Divider>
          </Box>
        )}
        
        {!successMessage && (
          <Typography variant="body2" sx={{ mb: 2, textAlign: 'center', color: '#666666' }}>
            Or Why Not Level Up right now with<br />
            Early Bird (while available) or<br />
            Pro (for those who know)
          </Typography>
        )}
      </DialogContent>
      
      <DialogActions sx={{ px: 3, pb: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
        
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button 
            variant="contained" 
            onClick={handleAuthenticate}
            sx={{ 
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              backgroundColor: '#00A651',
              color: 'white',
              '&:hover': {
                backgroundColor: '#008C44'
              },
              width: '250px'
            }}
          >
            LEVEL UP NOW
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EmailCollectionDialog;
