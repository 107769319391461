/**
 * PremiumDialog Component (v2.3.1)
 * 
 * Reusable dialog component for premium messaging to paid users throughout the app.
 * - Shown to Pro and Earlybird users for premium features and announcements
 * - Maintains consistent branding with high-visibility logo
 * - Flexible content with customizable title and message
 * - Provides standard contact information footer
 * 
 * @version 2.3.1
 * @lastUpdated 2025-03-25
 */

import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Typography,
  Box,
  Divider
} from '@mui/material';
import GlowTextButton from './GlowTextButton';

// No need to import logos - using public path
import { useTheme } from '../context';

const PremiumDialog = ({ 
  open, 
  onClose, 
  title = 'Advanced Features Coming Soon!',
  message = 'The advanced image control features are currently being finalized and will be available to premium users in an upcoming update.',
  buttonText = 'Got it!'
}) => {
  const { theme } = useTheme();
  // Select the appropriate logo based on theme
  const logoSrc = theme === 'dark' ? '/images/HEXTRA-X-logo-Blk.svg' : '/images/HEXTRA-X-logo-Wht.svg';
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          bgcolor: 'var(--bg-primary)',
          color: 'var(--text-primary)',
          border: '1px solid var(--border-color)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          padding: '16px'
        }
      }}
    >
      <DialogTitle sx={{ 
        textAlign: 'center',
        fontFamily: "'League Spartan', sans-serif",
        fontSize: '1.8rem',
        fontWeight: 'bold',
        color: 'var(--text-primary)',
        mt: 2
      }}>
        {title}
      </DialogTitle>
      
      <DialogContent>
        {/* Logo */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          mb: 3,
          mt: 1
        }}>
          <img 
            src={logoSrc} 
            alt="HEXTRA Logo" 
            style={{ 
              height: '120px', 
              marginBottom: '8px' 
            }}
          />
        </Box>
        
        <Divider sx={{ mb: 3 }} />
        
        <Typography variant="body1" sx={{ 
          textAlign: 'center',
          fontSize: '1.1rem',
          mb: 2,
          color: 'var(--text-primary)'
        }}>
          Thank you for being a valued HEXTRA subscriber!
        </Typography>
        
        <Typography variant="body1" sx={{ 
          textAlign: 'center',
          fontSize: '1rem',
          mb: 3,
          color: 'var(--text-secondary)'
        }}>
          {message}
        </Typography>
        
        <Box sx={{ 
          bgcolor: 'var(--bg-secondary)',
          p: 2,
          borderRadius: '8px',
          mb: 3,
          border: '1px solid var(--border-color)'
        }}>
          <Typography variant="body2" sx={{ 
            textAlign: 'center',
            fontSize: '0.95rem',
            color: theme === 'dark' ? '#00805E' : '#00805E',
            fontWeight: 'medium'
          }}>
            Need more information? <br />
            Contact our Technical Development team<br />
            <span style={{ fontWeight: 'bold' }}>for assistance with premium features</span>
          </Typography>
        </Box>
      </DialogContent>
      
      <DialogActions sx={{ justifyContent: 'center', pb: 3, px: 3 }}>
        <GlowTextButton 
          onClick={onClose}
          variant="contained"
          customColor="#224D8F"
          fullWidth
          sx={{ 
            fontSize: '1rem',
            py: 1,
            color: '#FFFFFF' // White text in both modes
          }}
        >
          {buttonText}
        </GlowTextButton>
      </DialogActions>
    </Dialog>
  );
};

export default PremiumDialog;
