// Base catalog data
const GILDAN_64 = [
  { hex: '#FFFFFF', name: 'White', family: 'neutral' },
  { hex: '#97999B', name: 'Sport Grey', family: 'grey' },
  { hex: '#D7D2CB', name: 'Ice Grey', family: 'grey' },
  { hex: '#7E7F74', name: 'Heather Military Green', family: 'green', tags: ['heather'] },
  { hex: '#75787B', name: 'Graphite Heather', family: 'grey', tags: ['heather'] },
  { hex: '#425563', name: 'Dark Heather', family: 'grey', tags: ['heather'] },
  { hex: '#4D6995', name: 'Heather Indigo', family: 'blue', tags: ['heather'] },
  { hex: '#4A4A4A', name: 'Dark Heather Grey', family: 'grey', tags: ['heather'] },
  { hex: '#333F48', name: 'Heather Navy', family: 'blue', tags: ['heather'] },
  { hex: '#66676C', name: 'Charcoal', family: 'grey' },
  { hex: '#25282A', name: 'Black', family: 'neutral' },
  { hex: '#971B2F', name: 'Antique Cherry Red', family: 'red', tags: ['antique'] },
  { hex: '#AC2B37', name: 'Cherry Red', family: 'red' },
  { hex: '#BF0D3E', name: 'Heather Red', family: 'red', tags: ['heather'] },
  { hex: '#D50032', name: 'Red', family: 'red' },
  { hex: '#382F2D', name: 'Dark Chocolate', family: 'brown' },
  { hex: '#672E45', name: 'Heather Maroon', family: 'red', tags: ['heather'] },
  { hex: '#5B2B42', name: 'Maroon', family: 'red' },
  { hex: '#8A1538', name: 'Cardinal Red', family: 'red' },
  { hex: '#9B2743', name: 'Heather Cardinal', family: 'red', tags: ['heather'] },
  { hex: '#CABFAD', name: 'Sand', family: 'neutral' },
  { hex: '#E7CEB5', name: 'Natural', family: 'neutral' },
  { hex: '#F4633A', name: 'Orange', family: 'orange' },
  { hex: '#EEAD1A', name: 'Gold', family: 'yellow' },
  { hex: '#FED141', name: 'Daisy', family: 'yellow' },
  { hex: '#F0EC74', name: 'Cornsilk', family: 'yellow' },
  { hex: '#A9C47F', name: 'Pistachio', family: 'green' },
  { hex: '#89A84F', name: 'Kiwi', family: 'green' },
  { hex: '#92BF55', name: 'Lime', family: 'green' },
  { hex: '#A0CFA8', name: 'Mint Green', family: 'green' },
  { hex: '#A4B09E', name: 'Sage', family: 'green' },
  { hex: '#00A74A', name: 'Irish Green', family: 'green' },
  { hex: '#00805E', name: 'Kelly Green', family: 'green' },
  { hex: '#5E7461', name: 'Military Green', family: 'green' },
  { hex: '#273B33', name: 'Forest Green', family: 'green' },
  { hex: '#5CAA7F', name: 'Heather Irish Green', family: 'green', tags: ['heather'] },
  { hex: '#008E85', name: 'Jade Dome', family: 'green' },
  { hex: '#00859B', name: 'Tropical Blue', family: 'blue' },
  { hex: '#006A8E', name: 'Antique Sapphire', family: 'blue', tags: ['antique'] },
  { hex: '#0076A8', name: 'Sapphire', family: 'blue' },
  { hex: '#59A1CE', name: 'Heather Sapphire', family: 'blue', tags: ['heather'] },
  { hex: '#486D87', name: 'Indigo Blue', family: 'blue' },
  { hex: '#7E93A7', name: 'Stone Blue', family: 'blue' },
  { hex: '#464E7E', name: 'Metro Blue', family: 'blue' },
  { hex: '#8BCDEA', name: 'Sky', family: 'blue' },
  { hex: '#A4C8E1', name: 'Light Blue', family: 'blue' },
  { hex: '#7BA4DB', name: 'Carolina Blue', family: 'blue' },
  { hex: '#0093B2', name: 'Heather Galapagos Blue', family: 'blue', tags: ['heather'] },
  { hex: '#3975B7', name: 'Iris', family: 'blue' },
  { hex: '#7C8CD9', name: 'Heather Royal', family: 'blue', tags: ['heather'] },
  { hex: '#307FE2', name: 'Royal', family: 'blue' },
  { hex: '#263147', name: 'Navy', family: 'blue' },
  { hex: '#948794', name: 'Paragon', family: 'purple' },
  { hex: '#A15A95', name: 'Heather Radiant Orchid', family: 'purple', tags: ['heather'] },
  { hex: '#614B79', name: 'Heather Purple', family: 'purple', tags: ['heather'] },
  { hex: '#3c214e', name: 'Purple', family: 'purple' },
  { hex: '#E4C6D4', name: 'Light Pink', family: 'pink' },
  { hex: '#994878', name: 'Heather Berry', family: 'pink', tags: ['heather'] },
  { hex: '#AA0061', name: 'Antique Heliconia', family: 'pink', tags: ['antique'] },
  { hex: '#DD74A1', name: 'Azalea', family: 'pink' },
  { hex: '#E24585', name: 'Heather Heliconia', family: 'pink', tags: ['heather'] },
  { hex: '#FF8D6D', name: 'Heather Orange', family: 'orange', tags: ['heather'] },
  { hex: '#FB637E', name: 'Coral Silk', family: 'pink' }
];

export default GILDAN_64;
