/**
 * Application Configuration
 * 
 * This file contains configuration settings for the HEXTRA-X application.
 * These settings can be overridden by environment variables or admin settings.
 */

// Default configuration
const defaultConfig = {
  // HCS2 Integration
  hcs2: {
    // Default URL for the HCS2 iframe
    defaultUrl: 'https://hextra-color-system-2.vercel.app',
    
    // Gold version URL (v1.3.3-gold)
    goldUrl: 'https://hextra-color-system-2.vercel.app',
    
    // Which URL to use by default ('default' or 'gold')
    activeVersion: 'gold',
    
    // Allowed origins for message validation
    allowedOrigins: [
      'https://hextra-color-system-2.vercel.app',
      'https://hextra-color-system-2-9ocfq75lx-wofejams-projects.vercel.app',
      'https://hextra-color-system2.hextradesign.com',
      'https://hcs2.hextradesign.com'
    ]
  }
};

// Try to load settings from localStorage if available
let userConfig = {};
try {
  if (typeof window !== 'undefined' && window.localStorage) {
    const savedConfig = localStorage.getItem('hextra_admin_config');
    if (savedConfig) {
      userConfig = JSON.parse(savedConfig);
    }
  }
} catch (error) {
  console.warn('Failed to load user configuration:', error);
}

// Merge default config with user config
const config = {
  ...defaultConfig,
  ...userConfig
};

/**
 * Get the active HCS2 URL based on configuration
 * @returns {string} The URL to use for the HCS2 iframe
 */
export const getHCS2Url = () => {
  if (config.hcs2.activeVersion === 'gold') {
    return config.hcs2.goldUrl;
  }
  return config.hcs2.defaultUrl;
};

/**
 * Get the allowed origins for message validation
 * @returns {string[]} Array of allowed origins
 */
export const getAllowedOrigins = () => {
  return config.hcs2.allowedOrigins;
};

/**
 * Save configuration to localStorage
 * @param {Object} newConfig - New configuration to save
 */
export const saveConfig = (newConfig) => {
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      const mergedConfig = { ...config, ...newConfig };
      localStorage.setItem('hextra_admin_config', JSON.stringify(mergedConfig));
      
      // Update the runtime config
      Object.assign(config, newConfig);
      
      return true;
    }
  } catch (error) {
    console.error('Failed to save configuration:', error);
  }
  return false;
};

export default config;
