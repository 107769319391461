/**
 * HEXTRA-X Navigation Utility
 * 
 * This utility provides consistent navigation throughout the application,
 * avoiding React suspension issues with React Router's navigate function.
 * 
 * It uses window.location.href for navigation to prevent the
 * "Cannot access 'g' before initialization" errors that occurred with React Router.
 */

/**
 * Safe navigation function that avoids React suspension issues
 * 
 * @param {string} path - The path to navigate to
 * @param {Object} options - Navigation options
 * @param {boolean} options.replace - Use replaceState instead of direct href assignment
 * @param {Function} options.onComplete - Callback to run after navigation
 */
export const safeNavigate = (path, options = {}) => {
  const {
    replace = false,
    onComplete = null
  } = options;

  // Use direct window.location navigation to avoid React suspension
  if (replace) {
    window.history.replaceState({}, '', path);
    // Reload the page to ensure all components are properly initialized
    window.location.reload();
  } else {
    window.location.href = path;
  }

  // Run completion callback if provided
  if (onComplete && typeof onComplete === 'function') {
    onComplete();
  }
};

/**
 * Scroll to an element by ID with smooth scrolling
 * 
 * @param {string} elementId - The ID of the element to scroll to
 * @param {Object} options - Scrolling options
 * @param {string} options.block - Vertical alignment (start, center, end, nearest)
 * @param {number} options.delay - Delay in ms before scrolling
 */
export const scrollToElement = (elementId, options = {}) => {
  const {
    block = 'start',
    delay = 0
  } = options;

  const scrollAction = () => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth', 
        block 
      });
      return true;
    }
    return false;
  };

  if (delay > 0) {
    setTimeout(scrollAction, delay);
  } else {
    return scrollAction();
  }
};

const navigationUtils = {
  safeNavigate,
  scrollToElement
};

export default navigationUtils;
