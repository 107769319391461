/**
 * Download Tracking Utility
 * 
 * Handles generation and tracking of download links for both individual
 * and bulk downloads. Uses localStorage for temporary storage until
 * a more robust solution like Firebase is implemented.
 */

/**
 * Generate a unique download link with tracking parameters
 * 
 * @param {string} email - User's email address
 * @param {string} imageId - ID of the image to download
 * @param {string|null} batchId - Optional batch ID for bulk downloads
 * @param {Object} options - Additional options
 * @returns {Object} The download URL and ID
 */
export function generateDownloadLink(email, imageId, batchId = null, options = {}) {
  // Create a more robust unique download ID with timestamp and random component
  // Format: dl_timestamp_randomstring_checksum
  const timestamp = Date.now();
  const randomPart = Math.random().toString(36).substring(2, 10);
  const checksum = generateSimpleChecksum(`${email}_${imageId}_${timestamp}`);
  const downloadId = `dl_${timestamp}_${randomPart}_${checksum}`;
  
  // Create the download URL with tracking parameters
  // Use a more reliable URL structure with proper encoding of all parameters
  const baseUrl = `${window.location.origin}/api/download`;
  const params = new URLSearchParams();
  params.append('id', downloadId);
  params.append('email', email);
  params.append('img', imageId);
  if (batchId) params.append('batch', batchId);
  if (options.format) params.append('format', options.format);
  if (options.color) params.append('color', options.color);
  
  const downloadUrl = `${baseUrl}?${params.toString()}`;
  
  // Store the download request with more comprehensive metadata
  try {
    const downloadsJson = localStorage.getItem('hextra_download_links') || '[]';
    const downloads = JSON.parse(downloadsJson);
    
    // Remove any extremely old entries to prevent localStorage from getting too full
    const ONE_MONTH_AGO = new Date();
    ONE_MONTH_AGO.setMonth(ONE_MONTH_AGO.getMonth() - 1);
    const filteredDownloads = downloads.filter(d => {
      return !d.created || new Date(d.created) > ONE_MONTH_AGO;
    });
    
    // Add the new download entry with comprehensive metadata
    filteredDownloads.push({
      id: downloadId,
      email,
      imageId,
      batchId,
      format: options.format || '',
      color: options.color || '',
      userType: options.userType || 'unknown',
      created: new Date().toISOString(),
      expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days expiry
      clicked: false,
      completed: false,
      attempts: 0,
      maxAttempts: 5 // Allow up to 5 download attempts
    });
    
    localStorage.setItem('hextra_download_links', JSON.stringify(filteredDownloads));
    
    // Also track total counts
    updateDownloadStats('links_generated', 1);
    
  } catch (e) {
    console.error('[ERROR] Error storing download link:', e);
    // Implement fallback storage mechanism
    try {
      // Try to store in sessionStorage as fallback
      sessionStorage.setItem(`hextra_dl_${downloadId}`, JSON.stringify({
        email, imageId, batchId, created: new Date().toISOString()
      }));
    } catch (fallbackError) {
      console.error('[ERROR] Fallback storage also failed:', fallbackError);
    }
  }
  
  return { url: downloadUrl, id: downloadId };
}

/**
 * Generate a simple checksum for data integrity validation
 * 
 * @param {string} input - Input string to generate checksum for
 * @returns {string} A simple checksum
 */
function generateSimpleChecksum(input) {
  let hash = 0;
  if (input.length === 0) return hash.toString(16);
  
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  
  return Math.abs(hash).toString(16).substring(0, 8);
}

/**
 * Handle a bulk download request by generating multiple links
 * 
 * @param {string} email - User's email address
 * @param {Array<string>} imageIds - Array of image IDs to download
 * @param {string} userType - User type (free, email, earlybird, pro)
 * @returns {Array} Array of download links
 */
export function generateBulkDownloadLinks(email, imageIds, userType) {
  // Create a batch ID for this bulk download
  const batchId = `batch_${Date.now()}`;
  
  // Generate download links for each image
  const downloadLinks = imageIds.map(imageId => {
    return {
      imageId,
      url: generateDownloadLink(email, imageId, batchId)
    };
  });
  
  // Store batch information
  try {
    const batchesJson = localStorage.getItem('hextra_batches') || '[]';
    const batches = JSON.parse(batchesJson);
    
    batches.push({
      id: batchId,
      email,
      imageCount: imageIds.length,
      created: new Date().toISOString(),
      userType
    });
    
    localStorage.setItem('hextra_batches', JSON.stringify(batches));
    
    // Update stats
    updateDownloadStats('batches_created', 1);
    
  } catch (e) {
    console.error('[ERROR] Error storing batch info:', e);
  }
  
  return {
    batchId,
    links: downloadLinks
  };
}

/**
 * Track when a download link is clicked
 * 
 * @param {string} downloadId - The unique download ID
 * @returns {Object} Success status and download info
 */
export function trackDownloadClick(downloadId) {
  try {
    const downloadsJson = localStorage.getItem('hextra_download_links') || '[]';
    const downloads = JSON.parse(downloadsJson);
    
    const downloadIndex = downloads.findIndex(d => d.id === downloadId);
    if (downloadIndex >= 0) {
      // Check if download link has expired
      const download = downloads[downloadIndex];
      const now = new Date();
      const expiresAt = download.expiresAt ? new Date(download.expiresAt) : null;
      
      if (expiresAt && now > expiresAt) {
        console.log(`[DOWNLOAD] Link expired: ${downloadId}`);
        return { success: false, error: 'expired', download: null };
      }
      
      // Check if maximum attempts reached
      if (download.attempts >= download.maxAttempts) {
        console.log(`[DOWNLOAD] Max attempts reached: ${downloadId}`);
        return { success: false, error: 'max_attempts', download: null };
      }
      
      // Update download record
      download.clicked = true;
      download.clickedAt = now.toISOString();
      download.attempts = (download.attempts || 0) + 1;
      download.lastAttempt = now.toISOString();
      
      localStorage.setItem('hextra_download_links', JSON.stringify(downloads));
      updateDownloadStats('links_clicked', 1);
      
      // Return success with download info
      return { 
        success: true, 
        download: { ...download }
      };
    }
    
    // Try fallback storage
    try {
      const fallbackData = sessionStorage.getItem(`hextra_dl_${downloadId}`);
      if (fallbackData) {
        const download = JSON.parse(fallbackData);
        return { success: true, download, fromFallback: true };
      }
    } catch (fallbackError) {
      console.error('[ERROR] Error checking fallback storage:', fallbackError);
    }
    
    return { success: false, error: 'not_found', download: null };
  } catch (e) {
    console.error('[ERROR] Error tracking download click:', e);
    return { success: false, error: 'exception', message: e.message };
  }
}

/**
 * Track when a download is completed
 * 
 * @param {string} downloadId - The unique download ID
 * @param {Object} metadata - Additional metadata about the download
 * @returns {Object} Success status and download info
 */
export function trackDownloadComplete(downloadId, metadata = {}) {
  try {
    const downloadsJson = localStorage.getItem('hextra_download_links') || '[]';
    const downloads = JSON.parse(downloadsJson);
    
    const downloadIndex = downloads.findIndex(d => d.id === downloadId);
    if (downloadIndex >= 0) {
      const now = new Date();
      const download = downloads[downloadIndex];
      
      // Update download record with completion info and any additional metadata
      download.completed = true;
      download.completedAt = now.toISOString();
      download.fileSize = metadata.fileSize || null;
      download.format = metadata.format || download.format;
      download.downloadDuration = metadata.duration || null;
      download.successful = true;
      
      localStorage.setItem('hextra_download_links', JSON.stringify(downloads));
      updateDownloadStats('downloads_completed', 1);
      
      // Return success with download info
      return { success: true, download: { ...download } };
    }
    
    // Try fallback storage
    try {
      const fallbackData = sessionStorage.getItem(`hextra_dl_${downloadId}`);
      if (fallbackData) {
        const download = JSON.parse(fallbackData);
        download.completed = true;
        download.completedAt = new Date().toISOString();
        sessionStorage.setItem(`hextra_dl_${downloadId}`, JSON.stringify(download));
        return { success: true, download, fromFallback: true };
      }
    } catch (fallbackError) {
      console.error('[ERROR] Error updating fallback storage:', fallbackError);
    }
    
    return { success: false, error: 'not_found' };
  } catch (e) {
    console.error('[ERROR] Error tracking download completion:', e);
    return { success: false, error: 'exception', message: e.message };
  }
}

/**
 * Track a failed download attempt
 * 
 * @param {string} downloadId - The unique download ID
 * @param {string} reason - Reason for failure
 * @returns {Object} Success status
 */
export function trackDownloadFailure(downloadId, reason = 'unknown') {
  try {
    const downloadsJson = localStorage.getItem('hextra_download_links') || '[]';
    const downloads = JSON.parse(downloadsJson);
    
    const downloadIndex = downloads.findIndex(d => d.id === downloadId);
    if (downloadIndex >= 0) {
      const download = downloads[downloadIndex];
      
      // Update failure information
      download.failures = download.failures || [];
      download.failures.push({
        timestamp: new Date().toISOString(),
        reason
      });
      
      localStorage.setItem('hextra_download_links', JSON.stringify(downloads));
      updateDownloadStats('download_failures', 1);
      
      return { success: true };
    }
    
    return { success: false, error: 'not_found' };
  } catch (e) {
    console.error('[ERROR] Error tracking download failure:', e);
    return { success: false, error: 'exception' };
  }
}

/**
 * Update download statistics counters
 * 
 * @param {string} statName - Name of the stat to update
 * @param {number} increment - Amount to increment by
 */
function updateDownloadStats(statName, increment = 1) {
  try {
    const statsJson = localStorage.getItem('hextra_download_stats') || '{}';
    const stats = JSON.parse(statsJson);
    
    // Initialize or increment the stat
    stats[statName] = (stats[statName] || 0) + increment;
    
    // Also update the timestamp
    stats.lastUpdated = new Date().toISOString();
    
    localStorage.setItem('hextra_download_stats', JSON.stringify(stats));
  } catch (e) {
    console.error('[ERROR] Error updating download stats:', e);
  }
}

/**
 * Get current download statistics
 * 
 * @returns {Object} Download statistics
 */
export function getDownloadStats() {
  try {
    const statsJson = localStorage.getItem('hextra_download_stats') || '{}';
    const stats = JSON.parse(statsJson);
    
    // Add some derived stats
    const linksJson = localStorage.getItem('hextra_download_links') || '[]';
    const links = JSON.parse(linksJson);
    
    const batchesJson = localStorage.getItem('hextra_batches') || '[]';
    const batches = JSON.parse(batchesJson);
    
    return {
      ...stats,
      totalLinks: links.length,
      clickedLinks: links.filter(l => l.clicked).length,
      completedDownloads: links.filter(l => l.completed).length,
      totalBatches: batches.length
    };
  } catch (e) {
    console.error('[ERROR] Error getting download stats:', e);
    return {};
  }
}
