import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme } from '../context/ThemeContext';

/**
 * MiniDropDown - A reusable dropdown component with minimal styling
 * 
 * @param {Object} props Component props
 * @param {string} props.title Title text displayed in the dropdown header
 * @param {React.ReactNode} props.children Content to be displayed when dropdown is open
 * @param {boolean} props.defaultOpen Whether the dropdown is open by default (defaults to false)
 * @param {function} props.onChange Optional callback when dropdown state changes
 * @param {Object} props.sx Additional styling for the container
 * @param {string} props.titleFontFamily Font family for the title (defaults to "League Spartan")
 * @param {string} props.titleFontSize Font size for the title (defaults to "15px")
 * @returns {React.ReactElement} MiniDropDown component
 */
const MiniDropDown = ({ 
  title, 
  children, 
  defaultOpen = false,
  onChange,
  sx = {},
  titleFontFamily = '"League Spartan", sans-serif',
  titleFontSize = '15px'
}) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {/* Dropdown Header/Toggle */}
      <Box 
        onClick={handleToggle}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 16px',
          backgroundColor: theme === 'dark' ? '#323232' : '#f5f5f5',
          borderRadius: '4px',
          cursor: 'pointer',
          mb: isOpen ? 2 : 0,
          border: theme === 'dark' ? '1px solid rgba(255,255,255,0.15)' : '1px solid rgba(0,0,0,0.1)',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: theme === 'dark' ? '#404040' : '#e8e8e8',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            component="span" 
            sx={{ 
              fontSize: titleFontSize,
              fontWeight: 'bold',
              fontFamily: titleFontFamily,
              letterSpacing: '0.5px',
              color: theme === 'dark' ? '#FFFFFF' : '#333333',
            }}
          >
            {title}
          </Typography>
        </Box>
        {isOpen ? 
          <ExpandLessIcon fontSize="small" sx={{ color: 'var(--text-secondary)' }} /> : 
          <ExpandMoreIcon fontSize="small" sx={{ color: 'var(--text-secondary)' }} />}
      </Box>

      {/* Dropdown Content */}
      {isOpen && children}
    </Box>
  );
};

export default MiniDropDown;
