import React, { useRef, useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Switch, 
  FormControlLabel, 
  Button, 
  CircularProgress, 
  Snackbar, 
  Alert,
  Tooltip,
  IconButton 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import './HCS2Frame.css';
import { getHCS2Url, getAllowedOrigins } from '../config';

/**
 * MiniHCS2TitleBar Component - Section 9.0 Mini Toggler
 * 
 * A compact version of the HCS2 title bar that can be used
 * anywhere in the interface to toggle the HCS2 iframe.
 */

export const MiniHCS2TitleBar = ({ isOpen, onToggle, onNavigateTo }) => {
  // Snackbar state for mini toggle feedback
  const [miniSnackbarOpen, setMiniSnackbarOpen] = useState(false);
  const [miniSnackbarMessage, setMiniSnackbarMessage] = useState('');
  const [miniSnackbarSeverity, setMiniSnackbarSeverity] = useState('info');
  
  // Handle snackbar close with force-close capability
  const handleMiniSnackbarClose = (event, reason) => {
    // Always close on explicit button press
    setMiniSnackbarOpen(false);
  };
  
  // Ensure both ON and OFF notifications auto-close after a specific time
  useEffect(() => {
    if (miniSnackbarOpen) {
      const timer = setTimeout(() => {
        setMiniSnackbarOpen(false);
      }, 4000); // Match the autoHideDuration
      
      return () => clearTimeout(timer); // Clean up on unmount
    }
  }, [miniSnackbarOpen]);
  
  // Handle toggle with notification
  const handleToggle = () => {
    // Toggle state and show notification
    const newState = !isOpen;
    if (onToggle) onToggle(newState); // Pass the new state to the parent
    
    // Show appropriate notification
    setMiniSnackbarMessage(newState ? 'HCS2 is now ON' : 'HCS2 is now OFF');
    setMiniSnackbarSeverity('info'); // Always use neutral colors
    setMiniSnackbarOpen(true);
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      {/* Snackbar for toggle feedback */}
      <Snackbar 
        open={miniSnackbarOpen} 
        autoHideDuration={4000} // Ensure enough time to be noticed but still auto-close 
        onClose={handleMiniSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position at bottom center to avoid overlap with RGB logo
      >
        <Alert 
          onClose={handleMiniSnackbarClose} 
          severity="info"
          variant="filled"
          sx={{ 
            width: '100%',
            fontWeight: 'bold', 
            display: 'flex', 
            alignItems: 'center',
            backgroundColor: '#333333', // Stylish dark gray
            '& .MuiAlert-icon': {
              color: '#ffffff' // White icon for all states
            }
          }}
        >
          {miniSnackbarMessage}
        </Alert>
      </Snackbar>
      <Tooltip title="Toggle Hextra Color System On/Off" arrow placement="top">
        <Box 
          className="mini-hcs2-titlebar"
          onClick={handleToggle}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: '#333333', // Flat dark gray color like requested
            color: 'white',
            padding: '0px 16px',
            height: '40px', // Match APPLY button height
            borderRadius: '4px',
            fontFamily: '"League Spartan", sans-serif',
            fontWeight: 'bold',
            fontSize: '15px', // Match APPLY button text size
            letterSpacing: '0.5px',
            transition: 'all 0.2s ease',
            border: '1px solid rgba(255,255,255,0.1)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: '#444444', // Slightly lighter on hover
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
            },
            position: 'relative'
          }}
        >
          <Typography 
            component="span" 
            sx={{ 
              fontSize: '15px',
              fontWeight: 'bold',
              mr: 0.8,
              fontFamily: 'inherit'
            }}
          >
            Toggle HCS2
          </Typography>
          {isOpen ? 
            <ExpandLessIcon fontSize="small" sx={{ ml: 0.5 }} /> : 
            <ExpandMoreIcon fontSize="small" sx={{ ml: 0.5 }} />}
        </Box>
      </Tooltip>
      
      {/* Color Wheel Quick Access Button - clean gradient circle */}
      <Tooltip title="Navigate to HCS2 Color Wheel" arrow placement="top">
        <Box
          onClick={() => {
            // Navigate to the HCS2 section
            if (onNavigateTo) onNavigateTo();
          }}
          sx={{
            width: '40px', // Match height of button
            height: '40px', // Match height of button
            borderRadius: '50%',
            cursor: 'pointer',
            background: 'conic-gradient(from 0deg, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
            transition: 'all 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden', // Ensures clean edges
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              background: 'white',
              boxShadow: 'inset 0 0 2px rgba(0,0,0,0.2)'
            }
          }}
        />
      </Tooltip>
    </Box>
  );
};

/**
 * HCS2Frame Component - Section 9.0
 * 
 * This component embeds the HCS2 application in an iframe and handles
 * communication between HEXTRA-X and HCS2.
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onColorSelect - Callback when a color is selected
 * @param {Function} props.onColorApply - Callback when a color is applied
 * @param {string} props.initialColor - Initial color to set in HCS2
 */
const HCS2Frame = ({
  onColorSelect = () => {},
  onColorApply = () => {},
  initialColor = '#EEAD1A', // Gold color for the Golden Connection (v1.3.3-gold)
  hexInputRef,
  isDropdownOpen = false,
  onDropdownToggle = () => {}
}) => {
  // Track last applied color to prevent feedback loops
  const [lastAppliedColor, setLastAppliedColor] = useState(null);
  const lastAppliedTimeRef = useRef(0);
  // Determine if we're in development or production
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  // Set the appropriate URL based on environment
  // TEMPORARY: Point to our local test server
  // Add uniqueId to help with debugging
  const uniqueId = Date.now();
  // Add a timestamp to bust cache
  const timestamp = Date.now();
  // Get the base URL from config
  const baseUrl = isDevelopment
    ? 'http://localhost:3002'
    : getHCS2Url();
    
  // Construct the full URL with query parameters
  const hcs2Url = `${baseUrl}/?embedded=true&source=hextra-x&width=1280&forceDesktop=true&uniqueId=${uniqueId}&parentApp=HEXTRA-X&version=1.3.3-gold&t=${timestamp}`;
    
  console.log('Using HCS2 URL:', hcs2Url);
  
  const iframeRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedColor, setSelectedColor] = useState(initialColor);
  const [loadError, setLoadError] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(hcs2Url);
  // Now using the external isDropdownOpen prop instead of internal state
  const [footerInfo, setFooterInfo] = useState({
    title: 'HEXTRA Color System 2 (HCS2) - The next generation color selection tool',
    version: 'Version 1.3.3-gold • Build: 2025-03-20 (golden-edition)'
  });
  
  // Maintaining the RGB Cube Logo Reset approach for focus management
  const fullUrl = iframeUrl; // Use the state variable for the iframe URL

  // Handle messages from the iframe
  useEffect(() => {
    const handleMessage = (event) => {
      // ==== GREEN BUTTON DETECTOR ====
      console.log('🔵 MESSAGE LISTENER TRIGGERED', event.origin, event.data);
      
      // Enhanced message handling approach based on "Golden Connection" implementation
      // Log all incoming messages for debugging
      console.log('📨 Raw message received:', event);
      
      // Validate message source for security
      // Get allowed origins from config
      const expectedOrigins = getAllowedOrigins();
      
      if (event.origin !== window.location.origin && 
          !expectedOrigins.includes(event.origin) && 
          event.origin !== 'null') { // 'null' is for local file testing
        // Skip validation in development for easier testing
        if (process.env.NODE_ENV === 'production') {
          console.warn(`⚠️ Message from unexpected origin: ${event.origin}`);
          // Don't return early in development to facilitate testing
        }
      }
      
      // Check if the event data is valid
      if (!event.data) {
        console.log('No data in message, ignoring');
        return;
      }
      
      // Extract message data - support all possible message formats
      // Format 1: { type, data, source }
      // Format 2: { action, payload }
      // Format 3: { type, color }
      // Format 4: { message, value }
      // Format 5: direct color value as string
      let messageType, messageData, messageSource, colorValue;
      
      if (typeof event.data === 'string') {
        // Handle direct string (likely a color)
        messageType = 'COLOR_VALUE';
        colorValue = event.data;
        messageData = { color: event.data };
        console.log('📝 Received direct string value:', event.data);
      } else {
        // Extract from object formats
        const data = event.data;
        
        // Determine message type (priority order)
        messageType = data.type || data.action || data.message || 'UNKNOWN';
        
        // Determine message data
        messageData = data.data || data.payload || data;
        
        // Extract color from multiple possible locations
        colorValue = messageData.color || data.color || messageData.value || data.value;
        
        // Determine source
        messageSource = data.source || data.from || 'unknown';
      }
      
      // Enhanced debug logging
      console.log('📩 Parsed message:', { 
        type: messageType, 
        data: messageData,
        source: messageSource,
        color: colorValue,
        origin: event.origin
      });
      
      // Special handling for APPLY_COLOR message (sent by green button in HCS2)
      // Following the "Golden Connection Strategy" (Enhanced Option 2)
      if (messageType === 'APPLY_COLOR' || messageType === 'APPLY_SELECTED_COLOR' || messageType === 'COLOR_APPLIED' || 
          (typeof messageType === 'string' && messageType.toUpperCase().includes('APPLY'))) {
        console.log('🟢 GREEN BUTTON PRESSED! Received APPLY_COLOR message with color:', colorValue);
        
        if (colorValue && typeof colorValue === 'string') {
          // Step 1: Normalize and uppercase the color (HEXTRA standard)
          const normalizedColor = colorValue.startsWith('#') 
            ? colorValue.toUpperCase() 
            : `#${colorValue.toUpperCase()}`;
          
          console.log('🌈 NORMALIZED COLOR FROM GREEN BUTTON:', normalizedColor);
          
          // Step 2: Update local state
          setSelectedColor(normalizedColor);
          
          // Step 3: THE GOLDEN CONNECTION - Pass the color to the main application
          // This will update the main HEXInputRef and application state AND APPLY TO PRODUCT
          console.log('🌟 GOLDEN CONNECTION: Applying color from green button to HEXInputRef:', normalizedColor);
          
          // Track this color as applied to prevent feedback loops
          setLastAppliedColor(normalizedColor);
          lastAppliedTimeRef.current = Date.now();
          
          // Apply the color to the product just like dropdown selections
          onColorApply(normalizedColor);
          
          // Log success confirmation
          console.log('✨ Golden Connection successful - color applied to product');
        } else {
          console.error('❌ GREEN BUTTON ERROR: No valid color value in message');
        }
        return;
      }
      
      // GOLDEN CONNECTION APPROACH: Process any message with color information
      if (colorValue && typeof colorValue === 'string') {
        // Always normalize color format
        const normalizedColor = colorValue.startsWith('#') 
          ? colorValue.toUpperCase() 
          : `#${colorValue.toUpperCase()}`;
        
        console.log('🎨 NORMALIZED COLOR:', normalizedColor, 'Original:', colorValue);
        
        // Determine if this is a color apply action
        const isApplyAction = [
          'APPLY', 'APPLY_COLOR', 'COLOR_APPLIED', 'APPLY_SELECTED_COLOR',
          'apply', 'applyColor', 'colorApplied'
        ].includes(messageType) || 
        (messageData && messageData.action === 'apply') ||
        (messageType && (messageType.includes('APPLY') || messageType.includes('apply')));
        
        if (isApplyAction) {
          console.log('🎯 Color apply action detected, applying NORMALIZED color:', normalizedColor);
          setSelectedColor(normalizedColor);
          onColorApply(normalizedColor);
          return;
        } else {
          // This is a color selection action 
          console.log('🔍 Color select action detected, selecting NORMALIZED color:', normalizedColor);
          
          // Prevent feedback loops by checking if this is a recently applied color
          const now = Date.now();
          if (normalizedColor === lastAppliedColor && (now - lastAppliedTimeRef.current) < 1000) {
            console.log('⏱️ Ignoring repeated color to prevent feedback loop:', normalizedColor);
            return;
          }
          
          setSelectedColor(normalizedColor);
          
          // Update the HEXInputRef with the color (don't auto-apply)
          // This matches the expected behavior: HCS2 selection -> round swatch update
          onColorSelect(normalizedColor);
          
          // HCS2 should only apply colors when the green button or Enter key is pressed
          return;
        }
      }
      
      // Process specific message types for status and initialization
      switch (messageType) {
        case 'HCS2_READY':
        case 'READY':
        case 'ready':
          console.log('🟢 HCS2 is ready for communication');
          setIsConnected(true);
          setIsLoaded(true);
          
          // Request footer information
          setTimeout(() => {
            sendMessageToHCS2('GET_FOOTER_INFO');
          }, 500);
          
          // Setting initial color handled by separate useEffect
          break;
          
        case 'FOOTER_INFO':
          console.log('📄 Received footer information:', messageData);
          if (messageData && messageData.title && messageData.version) {
            setFooterInfo({
              title: messageData.title,
              version: messageData.version
            });
          }
          break;
          
        case 'HCS2_LOADED':
        case 'LOADED':
        case 'loaded':
          console.log('✅ HCS2 is fully loaded');
          setIsLoaded(true);
          setIsConnected(true);
          
          // Setting initial color handled by separate useEffect
          break;
        
        case 'APPLY_COLOR':
        case 'COLOR_APPLIED':
          console.log('🟢 GREEN BUTTON: Secondary handler for APPLY_COLOR message!', messageData);
          if (colorValue && typeof colorValue === 'string') {
            const normalizedColor = colorValue.startsWith('#') 
              ? colorValue.toUpperCase() 
              : `#${colorValue.toUpperCase()}`;
            console.log('⭐ GOLDEN CONNECTION (secondary handler): Applying color from GREEN BUTTON:', normalizedColor);
            
            // Update local state
            setSelectedColor(normalizedColor);
            
            // Invoke the onColorApply callback - this implements the Golden Connection strategy
            console.log('🔄 GOLDEN CONNECTION SECONDARY PATH: Passing color to main application:', normalizedColor);
            onColorApply(normalizedColor);
          }
          break;
          
        case 'ERROR':
        case 'error':
          console.error('❌ Error from HCS2:', messageData);
          break;
          
        default:
          // For any other message type, log it but don't process
          console.log(`📌 Unhandled message type: ${messageType}`, messageData);
      }
    };
    
    // Add message listener to window - THIS IS CRITICAL!
    console.log('🔌 SETTING UP HCS2 MESSAGE LISTENER');  
    window.addEventListener('message', handleMessage);
    
    // Clean up on component unmount
    return () => {
      console.log('🧹 CLEANING UP HCS2 MESSAGE LISTENER');
      window.removeEventListener('message', handleMessage);
    };
  }, [initialColor, onColorApply, onColorSelect]);
  
  // Separate effect for initializing the color in HCS2
  useEffect(() => {
    // Only proceed if the iframe is loaded and connected
    if (isLoaded && isConnected && iframeRef.current) {
      // Normalize the initial color
      const normalizedColor = initialColor.startsWith('#') 
        ? initialColor.toUpperCase() 
        : `#${initialColor.toUpperCase()}`;
      
      console.log('🌈 Setting initial color in HCS2 after connection:', normalizedColor);
      
      // Send after a small delay to ensure HCS2 is ready
      setTimeout(() => {
        if (iframeRef.current) {
          try {
            // Use the iframe's contentWindow directly for most reliable communication
            const iframe = iframeRef.current;
            
            iframe.contentWindow.postMessage({
              type: 'SET_INITIAL_COLOR',
              data: {
                color: normalizedColor,
                source: 'HEXTRA-X',
                parentApp: 'HEXTRA-X'
              },
              source: 'HEXTRA-X'
            }, '*');
            
            // Also send direct color format for maximum compatibility
            iframe.contentWindow.postMessage(normalizedColor, '*');
            
            console.log('💌 Color initialization message sent directly to iframe!');
          } catch (error) {
            console.error('Error sending color initialization message:', error);
          }
        }
      }, 500);
    }
  }, [isLoaded, isConnected, initialColor]);
  
  // Handle iframe load error and timeout
  useEffect(() => {
    const handleIframeError = () => {
      console.error('Failed to load HCS2 iframe');
      setLoadError(true);
    };
    
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('error', handleIframeError);
      
      // Set a timeout to detect if iframe doesn't load within 15 seconds
      const timeoutId = setTimeout(() => {
        if (!isLoaded && !loadError) {
          console.warn('HCS2 iframe load timeout - attempting to reload');
          setIframeUrl(`${hcs2Url}&t=${Date.now()}`);
        }
      }, 15000);
      
      return () => {
        iframe.removeEventListener('error', handleIframeError);
        clearTimeout(timeoutId);
      };
    }
  }, [isLoaded, loadError, hcs2Url]);
  
  // Automatically set connection status if iframe loads successfully
  useEffect(() => {
    if (isLoaded && !isConnected) {
      console.log('Iframe loaded, setting connection status...');
      const connectionTimeoutId = setTimeout(() => {
        console.log('Automatically setting connection status to connected');
        setIsConnected(true);
      }, 1500); // Wait 1.5 seconds after loading before assuming connected
      
      return () => {
        clearTimeout(connectionTimeoutId);
      };
    }
  }, [isLoaded, isConnected]);
  
  // Send a message to the HCS2 iframe - simplified for reliable communication
  const sendMessageToHCS2 = (type, data = {}) => {
    console.log(`Sending message to HCS2: ${type}`, data);
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      // Use wildcard for simplicity during development
      const targetOrigin = '*';
      
      // Make sure color is normalized
      let colorValue = data.color;
      if (colorValue && typeof colorValue === 'string') {
        colorValue = colorValue.startsWith('#') ? colorValue.toUpperCase() : `#${colorValue.toUpperCase()}`;
      }
      
      // Simple message structure for reliable communication
      const enhancedData = {
        ...data,
        color: colorValue,
        source: 'HEXTRA-X',
        parentApp: 'HEXTRA-X'
      };
      
      console.log(`📣 Sending message to HCS2: ${type}`, enhancedData);
      
      try {
        // Send using simple postMessage format to ensure compatibility
        iframe.contentWindow.postMessage({
          type,
          data: enhancedData,
          source: 'HEXTRA-X'
        }, targetOrigin);
        
        // Also send the direct color format for maximum compatibility
        if (colorValue) {
          console.log(`🌈 Sending direct color message: ${colorValue}`);
          iframe.contentWindow.postMessage(colorValue, targetOrigin);
        }
      } catch (error) {
        console.error('Error sending message to HCS2:', error);
      }
    }
  };
  
  // Apply functionality - Handled only via HCS2's green button and the Golden Connection
  // Removed the handleApplyColor function since we're only using the green Apply in HCS2
  
  // Retry loading the iframe
  const handleRetry = () => {
    setLoadError(false);
    setIsLoaded(false);
    
    // Add a timestamp to force a fresh load and prevent caching
    const timestamp = Date.now();
    
    // Try toggling between production and local URLs if in development
    if (isDevelopment && iframeUrl === hcs2Url) {
      // If current URL is local, try production
      // Use a fresh timestamp for each retry
      const freshTimestamp = Date.now();
      const productionUrl = getHCS2Url();
      setIframeUrl(`${productionUrl}/?forceDesktop=true&width=1280&layout=desktop&embedded=true&version=1.3.3-gold&t=${freshTimestamp}`);
    } else if (isDevelopment) {
      // If current URL is production, go back to local
      setIframeUrl(`${hcs2Url}&t=${timestamp}`);
    } else {
      // In production, add a timestamp to force a fresh load
      setIframeUrl(`${hcs2Url}&t=${timestamp}`);
    }
  };
  
  // Toggle dropdown function
  const toggleDropdown = () => {
    const newState = !isDropdownOpen;
    onDropdownToggle(newState);
    
    // If opening and we have a reference to the hex input, focus it after a slight delay
    if (newState && hexInputRef?.current) {
      setTimeout(() => {
        hexInputRef.current.focus({ preventScroll: true });
      }, 100);
    }
  };
  
  // RGB Cube Logo Reset strategy focus management
  
  // RGB Cube Logo Reset strategy for focus management
  const triggerLogoClick = (pressureLevel = 1) => {
    // Find the RGB cube logo element
    const logoElement = document.querySelector('.hextra-logo');
    
    if (logoElement) {
      // Preserve current scroll position
      const scrollPos = {
        x: window.scrollX,
        y: window.scrollY
      };
      
      // Click the logo with appropriate timing based on pressure level
      setTimeout(() => {
        logoElement.click();
        
        // Restore scroll position
        window.scrollTo(scrollPos.x, scrollPos.y);
        
        // Verify focus was achieved, increase pressure if needed
        setTimeout(() => {
          if (document.activeElement !== hexInputRef?.current) {
            if (pressureLevel < 3) {
              // Recursively try again with increased pressure
              triggerLogoClick(pressureLevel + 1);
            }
          }
        }, 50 * pressureLevel);
      }, 10 * pressureLevel);
    } else {
      // Direct fallback if logo not found
      if (hexInputRef?.current) {
        hexInputRef.current.focus({ preventScroll: true });
      }
    }
  };
  
  // Set up iframe event listeners for focus management
  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && isDropdownOpen) {
      const handleMouseDown = () => triggerLogoClick(2);
      const handleMouseUp = () => triggerLogoClick(1);
      const handleFocus = () => triggerLogoClick(3);
      
      iframe.addEventListener('mousedown', handleMouseDown);
      iframe.addEventListener('mouseup', handleMouseUp);
      iframe.addEventListener('focus', handleFocus);
      
      return () => {
        iframe.removeEventListener('mousedown', handleMouseDown);
        iframe.removeEventListener('mouseup', handleMouseUp);
        iframe.removeEventListener('focus', handleFocus);
      };
    }
  }, [iframeRef.current, isDropdownOpen]);
  
  return (
    <Box className="hcs2-frame-container">
      {/* Special HCS2 Title Bar with Integrated Toggle */}
      <div 
        className="hcs2-title-bar"
        onClick={toggleDropdown}
        aria-expanded={isDropdownOpen}
        aria-controls="hcs2-dropdown-content"
      >
        <div className="hcs2-title-content">
          <div className="hcs2-title-text">
            <Typography variant="h5" component="h2" className="hcs2-main-title" id="section-9.0">
              HEXTRA Color System 2.0 (HCS2)
            </Typography>
            <Typography variant="subtitle1" className="hcs2-subtitle">
              Advanced color selection and matching for your products
            </Typography>
          </div>
          <div className="hcs2-dropdown-indicator">
            {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
      </div>
      
      {/* Connection Status - Shown only when dropdown is open */}
      {isDropdownOpen && (
        <Box sx={{ mb: 2, mt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {isLoaded ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>✓ Connected to HEXTRA Color System 2</span>
            ) : loadError ? (
              <span style={{ color: 'red', fontWeight: 'bold' }}>✗ Connection failed</span>
            ) : (
              <span style={{ color: 'orange', fontWeight: 'bold' }}>⚠ Connecting...</span>
            )}
          </Typography>
        </Box>
      )}
      
      {/* Dropdown Content */}
      {isDropdownOpen && (
        <div id="hcs2-dropdown-content" className="hcs2-dropdown-content">
          <Paper 
            elevation={3}
            sx={{ 
              position: 'relative',
              borderRadius: 2,
              overflow: 'hidden',
              mb: 2,
              width: '100%',
              maxWidth: '1280px',
              margin: '0 auto'
            }}
            className="hcs2-frame-wrapper"
          >
            {/* Loading Indicator */}
            {!isLoaded && !loadError && (
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,0.8)',
                zIndex: 2
              }}>
                <Box sx={{ textAlign: 'center' }}>
                  <CircularProgress size={40} sx={{ mb: 2 }} />
                  <Typography variant="body2">
                    Loading HEXTRA Color System 2...
                  </Typography>
                </Box>
              </Box>
            )}
            
            {/* Error State */}
            {loadError && (
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,0.9)',
                zIndex: 2,
                p: 3
              }}>
                <Box sx={{ textAlign: 'center', maxWidth: 400 }}>
                  <Typography variant="h6" color="error" gutterBottom>
                    Failed to load HEXTRA Color System 2
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 3 }}>
                    We couldn't connect to the color system. This could be due to network issues or the service may be temporarily unavailable.
                  </Typography>
                  <Button 
                    variant="contained" 
                    onClick={handleRetry}
                    sx={{
                      backgroundColor: '#333333',
                      '&:hover': {
                        backgroundColor: '#444444'
                      }
                    }}
                  >
                    Retry
                  </Button>
                </Box>
              </Box>
            )}
            
            {/* HCS2 iFrame */}
            <iframe
              ref={iframeRef}
              src={fullUrl}
              title="HEXTRA Color System 2"
              width="1280"
              height="800"
              style={{
                border: 'none',
                width: '1280px !important',    // Standard width matching HEXTRA design
                minWidth: '1280px !important', // Force minimum width
                maxWidth: '1280px !important', // Force maximum width
                height: '800px',    // Fixed height
                display: isDropdownOpen ? 'block' : 'none', // Only control iframe visibility - keep sections 9-11 visible
                margin: '0 auto',   // Center the iframe in the container
                overflow: 'hidden',  // Prevent scrollbars within the iframe
                visibility: isDropdownOpen ? 'visible' : 'hidden' // Double ensure it's hidden when toggle is off
              }}
              onLoad={() => {
                console.log('HCS2 iframe loaded from:', fullUrl);
                setIsLoaded(true);
                // Set connected to true here as well for redundancy
                setIsConnected(true);
                
                // Initialize with the current color after a brief delay to ensure iframe is ready
                setTimeout(() => {
                  const colorToSync = selectedColor || initialColor || '#FFFFFF';
                  console.log('🔄 Initializing HCS2 with color:', colorToSync);
                  sendMessageToHCS2('SET_INITIAL_COLOR', { color: colorToSync });
                  
                  // Focus back to hex input after iframe loads
                  triggerLogoClick(2);
                }, 500);
              }}
            />
          </Paper>
        </div>
      )}
      
      {/* Controls - Simplified with dropdown */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', sm: 'center' },
        mb: 2,
        width: '100%'
      }}>
        {/* Selected Color display removed - HCS2 provides its own color display */}
        
        {/* Apply button removed - using the green button in HCS2 only */}
      </Box>
    </Box>
  );
};

export default HCS2Frame;
// HEXTRA Color System 2 (HCS2) - The next generation color selection toolVersion 1.3.3-gold • Build: 2025-03-20 (golden-edition) REFRESH FOOTER
