/**
 * UserProfileMenu Component
 * 
 * Displays the user's badge with initial and appropriate styling based on user type,
 * along with a dropdown menu for account settings, subscription management, and logout.
 */

import React, { useState } from 'react';
import { 
  Box, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText, 
  Tooltip, 
  Divider
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { safeNavigate } from '../utils/navigation';
import WorkIcon from '@mui/icons-material/Work';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import UserBadge from './UserBadge';
import TokenSystem from './TokenSystem';

const UserProfileMenu = ({ derivedUserType = null }) => {
  const auth = useAuth();
  // Safely destructure auth values with fallbacks to prevent TypeError
  const isAuthenticated = auth?.isAuthenticated || false;
  const user = auth?.user || null;
  const userType = auth?.userType || 'free';
  
  // If derivedUserType is provided, use it instead of userType
  const effectiveUserType = derivedUserType || userType;
  const logout = auth?.logout || (() => console.warn('Logout function not available'));
  
  const { theme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get badge information based on user type
  const getBadgeInfo = () => {
    // Default to 'F' for free users
    let initial = 'F';
    
    // CRITICAL FIX: Special debugging for authenticated users
    if (isAuthenticated && user) {
      console.log('⚠️ Auth Debug - User:', { 
        id: user.id,
        email: user.email,
        name: user.given_name,
        userType: userType
      });
    }
    
    // Check for authenticated user's given name first
    if (isAuthenticated && user && user.given_name) {
      // Use first initial of name for authenticated users
      initial = user.given_name.charAt(0).toUpperCase();
      console.log(`✅ Using name initial: ${initial} from ${user.given_name}`);
    } 
    // Next, check for authenticated user's email
    else if (isAuthenticated && user && user.email) {
      // Fallback to email initial for authenticated users
      initial = user.email.charAt(0).toUpperCase();
      console.log(`✅ Using email initial: ${initial} from ${user.email}`);
    }
    // Finally check localStorage for email subscriber information
    else if (effectiveUserType === 'email') {
      try {
        const emailUser = JSON.parse(localStorage.getItem('hextra_email_user') || 'null');
        if (emailUser && emailUser.email) {
          const email = emailUser.email.trim();
          if (email && email.length > 0) {
            initial = email[0].toUpperCase();
            console.log(`✅ Email user initial set to: ${initial} from ${email}`);
          }
        }
      } catch (error) {
        console.error('Error getting email user initial:', error);
      }
    }
    
    // We no longer need color and icon since we're using the UserBadge component
    // which handles all the styling based on userType
    return { initial };
  };

  const { initial } = getBadgeInfo();
  
  // Return tooltip text based on user type
  const getTooltipText = () => {
    // CRITICAL FIX: Always show email for authenticated users
    if (isAuthenticated && user && user.email) {
      const tierDesc = userType === 'pro' ? 'PRO User - Full Access' : 
                    userType === 'earlybird' ? 'Early Bird - Premium Access' : 
                    'Authenticated User';
      return `${user.email} - ${tierDesc}`;
    }
    
    // For Verified users, try to get their email address
    if (effectiveUserType === 'email') {
      try {
        // For non-authenticated Verified users, try to get from localStorage
        const emailUser = JSON.parse(localStorage.getItem('hextra_email_user') || 'null');
        if (emailUser && emailUser.email) {
          return `${emailUser.email} - Verified User`;
        }
      } catch (error) {
        console.error('Error getting email for tooltip:', error);
      }
    }
    
    // Default tooltip messages based on user type
    switch (effectiveUserType) {
      case 'free':
        return 'Free Loader - Sign up to unlock downloads';
      case 'email':
        return 'Verified User - Single image downloads'; // Fallback if email can't be retrieved
      case 'earlybird':
        return user?.email ? `${user.email} - Full premium access` : 'Early Bird Member - Full premium access';
      case 'pro':
        return user?.email ? `${user.email} - Full premium access` : 'Pro Member - Full premium access';
      default:
        return 'HEXTRA User';
    }
  };

  return (
    <>
      <Box sx={{ transform: 'scale(0.75)', marginRight: '-5px' }}>
        <UserBadge
          initial={initial}
          userType={effectiveUserType}
          tooltipText={getTooltipText()}
          onClick={handleClick}
        />
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            mt: 1.5,
            backgroundColor: theme === 'dark' ? '#333' : '#fff',
            color: theme === 'dark' ? '#fff' : '#333',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            minWidth: 180
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* Only keep internal routes - no external Kinde pages */}
        
        {/* Return to work option */}
        <MenuItem 
          onClick={() => safeNavigate('/app')}
          sx={{
            backgroundColor: theme === 'dark' ? 'transparent' : '#00805E',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: theme === 'dark' ? 'rgba(0, 128, 94, 0.1)' : '#006e50',
            },
            ...(theme === 'light' && {
              marginTop: '4px',
              marginBottom: '4px',
              borderRadius: '4px',
              mx: 1
            })
          }}
        >
          <ListItemIcon>
            <WorkIcon fontSize="small" style={{ color: theme === 'dark' ? '#00805E' : '#FFFFFF' }} />
          </ListItemIcon>
          <ListItemText primary="Return to work" sx={{ 
            color: theme === 'dark' ? 'inherit' : '#FFFFFF'
          }} />
        </MenuItem>
        
        {/* Gildan Catalog Example - visible to all users */}
        <MenuItem>
          <ListItemIcon>
            <MonetizationOnIcon fontSize="small" sx={{ color: theme === 'dark' ? '#FFFFFF' : '#333333' }} />
          </ListItemIcon>
          <TokenSystem userType={effectiveUserType} theme={theme} />
        </MenuItem>
        
        {/* Only show subscription menu item for paid users */}
        {userType && userType !== 'free' && (
          <MenuItem onClick={() => safeNavigate('/subscription')}>
            <ListItemIcon>
              <CreditCardIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Plans</ListItemText>
          </MenuItem>
        )}
        
        {/* Download Statistics Dashboard - only visible to authenticated users */}
        {isAuthenticated && (
          <MenuItem onClick={() => safeNavigate('/admin/downloads')}>
            <ListItemIcon>
              <DashboardIcon fontSize="small" style={{ color: '#00805E' }} />
            </ListItemIcon>
            <ListItemText>Download Stats</ListItemText>
          </MenuItem>
        )}
        
        <Divider />
        
        {/* Test Role Selector has been removed */}
        
        <Divider />
        
        {/* ALWAYS show Login/Logout as the last option for ALL user types */}
        {isAuthenticated === true ? (
          /* Logout option for authenticated users (paid or email) */
          <MenuItem onClick={() => {
            // Execute logout function
            logout();
            // Additional redirection if needed
            safeNavigate('/');
          }}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        ) : (
          /* For non-authenticated users, show both Login and Register Account options */
          <>
            {/* Regular Login option */}
            <MenuItem onClick={() => {
              // Use the improved Kinde login with proper redirect
              console.log('🔑 Initiating Kinde login redirect from UserProfileMenu...');
              auth.login({
                authOnMount: false, // Critical: Prevents auto-login and forces redirect
                appState: { targetUrl: window.location.pathname },
                openerEnabled: false // Ensure we don't use a popup
              });
            }}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Login</ListItemText>
            </MenuItem>
            
            {/* Register Account option - Creates a verified user through Kinde */}
            <MenuItem onClick={() => {
              console.log('📝 Initiating Kinde registration redirect...');
              auth.login({
                authOnMount: false,
                appState: { targetUrl: window.location.pathname },
                openerEnabled: true,
                registration: true, // Enable registration mode
                // These additional parameters ensure users go directly to registration
                authUrlParams: {
                  screen_hint: 'signup',  // Critical parameter - forces signup screen
                  is_create_account: 'true',
                  prompt: 'create',
                  signup_mode: 'fullform'
                }
              });
            }} sx={{ color: '#4CAF50' }}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" sx={{ color: '#4CAF50' }} />
              </ListItemIcon>
              <ListItemText>Register Account</ListItemText>
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default UserProfileMenu;
