// React polyfills
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Node.js polyfills
import { Buffer } from 'buffer';
import process from 'process';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/features/object/from-entries';
import 'core-js/features/promise/finally';
import 'core-js/features/string/pad-start';
import 'core-js/features/string/pad-end';
import 'core-js/features/url';
import 'core-js/features/url-search-params';
import 'stream-browserify';
import 'buffer';

// Ensure polyfills are available globally
if (typeof window !== 'undefined') {
  window.Buffer = window.Buffer || Buffer;
  window.process = window.process || process;
  window.global = window;
  
  // Use URLSearchParams instead of querystring
  window.URLSearchParams = window.URLSearchParams || URLSearchParams;
  window.URL = window.URL || URL;
}

// Ensure fs is mocked
if (typeof window !== 'undefined') {
  window.fs = {
    readFileSync: () => {},
    writeFileSync: () => {},
    existsSync: () => false
  };
}

/**
 * HEXTRA-X Cross-Browser Compatibility Fix
 * 
 * This fixes the "Attempting to change the setter of an unconfigurable property" error
 * by directly patching React's property descriptor handling before components mount.
 */
if (typeof window !== 'undefined') {
  // More aggressive fix that runs at the polyfill level
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  if (isSafari || isMobile) {
    console.log('Applying deep property descriptor fix for Safari/Mobile');
    
    // Store the original methods
    const originalDefineProperty = Object.defineProperty;
    const originalGetOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
    
    // Override defineProperty
    Object.defineProperty = function(obj, prop, descriptor) {
      // Skip DOM elements for problematic properties
      if (obj && 
          typeof obj === 'object' && 
          obj.nodeType && 
          (prop === 'value' || prop === 'checked' || prop === 'selected' || 
           prop === 'current' || prop === 'disabled')) {
        
        try {
          // For DOM elements, use direct property assignment
          const value = descriptor.value !== undefined ? 
            descriptor.value : 
            (descriptor.get ? descriptor.get() : undefined);
          
          // Try to delete the property first
          delete obj[prop];
          
          // Then set it directly
          obj[prop] = value;
          return obj;
        } catch (e) {
          console.warn('Safari/Mobile fix: Failed to handle property:', prop);
          // Fall back to original if our approach fails
        }
      }
      
      // For all other cases, use the original
      return originalDefineProperty.call(this, obj, prop, descriptor);
    };
    
    // Add a safeguard for getOwnPropertyDescriptor
    Object.getOwnPropertyDescriptor = function(obj, prop) {
      try {
        return originalGetOwnPropertyDescriptor.call(this, obj, prop);
      } catch (e) {
        // If it fails, return a basic descriptor
        if (obj && obj[prop] !== undefined) {
          return {
            configurable: true,
            enumerable: true,
            value: obj[prop],
            writable: true
          };
        }
        return undefined;
      }
    };
  }
}
